.ladda-button {
  position: relative;
}
.ladda-button .ladda-spinner {
  position: absolute;
  top: 50%;
  z-index: 2;
  display: inline-block;
  width: 32px;
  height: 32px;
  margin-top: 0;
  pointer-events: none;
  opacity: 0;
}
.ladda-button .ladda-label {
  position: relative;
  z-index: 3;
}
.ladda-button .ladda-progress {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  visibility: hidden;
  background: rgba(0, 0, 0, .2);
  opacity: 0;
  -webkit-transition: .1s linear all ;
       -o-transition: .1s linear all ;
          transition: .1s linear all ;
}
.ladda-button[data-loading] .ladda-progress {
  visibility: visible;
  opacity: 1;
}
.ladda-button,
.ladda-button .ladda-spinner,
.ladda-button .ladda-label {
  -webkit-transition: .3s cubic-bezier(.175, .885, .32, 1.275 all);
       -o-transition: .3s cubic-bezier(.175, .885, .32, 1.275 all);
          transition: .3s cubic-bezier(.175, .885, .32, 1.275 all);
}
.ladda-button[data-style=zoom-in],
.ladda-button[data-style=zoom-in] .ladda-spinner,
.ladda-button[data-style=zoom-in] .ladda-label,
.ladda-button[data-style=zoom-out],
.ladda-button[data-style=zoom-out] .ladda-spinner,
.ladda-button[data-style=zoom-out] .ladda-label {
  -webkit-transition: .3s ease all ;
       -o-transition: .3s ease all ;
          transition: .3s ease all ;
}
.ladda-button[data-style=expand-right] .ladda-spinner {
  right: -6px;
}
.ladda-button[data-style=expand-right][data-size="s"] .ladda-spinner,
.ladda-button[data-style=expand-right][data-size="xs"] .ladda-spinner,
.ladda-button[data-style=expand-right].btn-xs .ladda-spinner {
  right: -12px;
}
.ladda-button[data-style=expand-right][data-loading] {
  padding-right: 45px;
}
.ladda-button[data-style=expand-right][data-loading] .ladda-spinner {
  opacity: 1;
}
.ladda-button[data-style=expand-right][data-loading][data-size="s"],
.ladda-button[data-style=expand-right][data-loading][data-size="xs"],
.ladda-button[data-style=expand-right][data-loading] .btn-xs {
  padding-right: 30px;
}
.ladda-button[data-style=expand-left] .ladda-spinner {
  left: 26px;
}
.ladda-button[data-style=expand-left][data-size="s"] .ladda-spinner,
.ladda-button[data-style=expand-left][data-size="xs"] .ladda-spinner,
.ladda-button[data-style=expand-left].btn-xs .ladda-spinner {
  left: 15px;
}
.ladda-button[data-style=expand-left][data-loading] {
  padding-left: 45px;
}
.ladda-button[data-style=expand-left][data-loading] .ladda-spinner {
  opacity: 1;
}
.ladda-button[data-style=expand-left][data-loading][data-size="s"],
.ladda-button[data-style=expand-left][data-loading][data-size="xs"],
.ladda-button[data-style=expand-left][data-loading].btn-xs {
  padding-left: 30px;
}
.ladda-button[data-style=expand-up] {
  overflow: hidden;
}
.ladda-button[data-style=expand-up] .ladda-spinner {
  top: -32px;
  left: 50%;
  margin-left: 0;
}
.ladda-button[data-style=expand-up][data-loading] {
  padding-top: 30px;
}
.ladda-button[data-style=expand-up][data-loading] .ladda-spinner {
  top: 16px;
  margin-top: 0;
  opacity: 1;
}
.ladda-button[data-style=expand-up][data-loading][data-size="s"],
.ladda-button[data-style=expand-up][data-loading][data-size="xs"],
.ladda-button[data-style=expand-up][data-loading].btn-xs {
  padding-top: 32px;
}
.ladda-button[data-style=expand-up][data-loading][data-size="s"] .ladda-spinner,
.ladda-button[data-style=expand-up][data-loading][data-size="xs"] .ladda-spinner,
.ladda-button[data-style=expand-up][data-loading].btn-xs .ladda-spinner {
  top: 4px;
}
.ladda-button[data-style=expand-down] {
  overflow: hidden;
}
.ladda-button[data-style=expand-down] .ladda-spinner {
  top: 44px;
  left: 50%;
  margin-left: 0;
}
.ladda-button[data-style=expand-down][data-size="s"] .ladda-spinner,
.ladda-button[data-style=expand-down][data-size="xs"] .ladda-spinner,
.ladda-button[data-style=expand-down].btn-xs .ladda-spinner {
  top: 30px;
}
.ladda-button[data-style=expand-down][data-loading] {
  padding-bottom: 30px;
}
.ladda-button[data-style=expand-down][data-loading] .ladda-spinner {
  opacity: 1;
}
.ladda-button[data-style=expand-down][data-loading][data-size="s"],
.ladda-button[data-style=expand-down][data-loading][data-size="xs"],
.ladda-button[data-style=expand-down][data-loading].btn-xs {
  padding-bottom: 23px;
}
.ladda-button[data-style=slide-left] {
  overflow: hidden;
}
.ladda-button[data-style=slide-left] .ladda-label {
  position: relative;
}
.ladda-button[data-style=slide-left] .ladda-spinner {
  left: 100%;
  margin-left: 0;
}
.ladda-button[data-style=slide-left][data-loading] .ladda-label {
  left: -100%;
  opacity: 0;
}
.ladda-button[data-style=slide-left][data-loading] .ladda-spinner {
  left: 50%;
  opacity: 1;
}
.ladda-button[data-style=slide-right] {
  overflow: hidden;
}
.ladda-button[data-style=slide-right] .ladda-label {
  position: relative;
}
.ladda-button[data-style=slide-right] .ladda-spinner {
  right: 100%;
  left: 16px;
  margin-left: 0;
}
.ladda-button[data-style=slide-right][data-loading] .ladda-label {
  left: 100%;
  opacity: 0;
}
.ladda-button[data-style=slide-right][data-loading] .ladda-spinner {
  left: 50%;
  opacity: 1;
}
.ladda-button[data-style=slide-up] {
  overflow: hidden;
}
.ladda-button[data-style=slide-up] .ladda-label {
  position: relative;
}
.ladda-button[data-style=slide-up] .ladda-spinner {
  left: 50%;
  margin-top: 1em;
  margin-left: 0;
}
.ladda-button[data-style=slide-up][data-loading] .ladda-label {
  top: -1em;
  opacity: 0;
}
.ladda-button[data-style=slide-up][data-loading] .ladda-spinner {
  margin-top: 0;
  opacity: 1;
}
.ladda-button[data-style=slide-down] {
  overflow: hidden;
}
.ladda-button[data-style=slide-down] .ladda-label {
  position: relative;
}
.ladda-button[data-style=slide-down] .ladda-spinner {
  left: 50%;
  margin-top: -2em;
  margin-left: 0;
}
.ladda-button[data-style=slide-down][data-loading] .ladda-label {
  top: 1em;
  opacity: 0;
}
.ladda-button[data-style=slide-down][data-loading] .ladda-spinner {
  margin-top: 0;
  opacity: 1;
}
.ladda-button[data-style=zoom-out] {
  overflow: hidden;
}
.ladda-button[data-style=zoom-out] .ladda-spinner {
  left: 50%;
  margin-left: 32px;
  -webkit-transform: scale(2.5);
      -ms-transform: scale(2.5);
       -o-transform: scale(2.5);
          transform: scale(2.5);
}
.ladda-button[data-style=zoom-out] .ladda-label {
  position: relative;
  display: inline-block;
}
.ladda-button[data-style=zoom-out][data-loading] .ladda-label {
  opacity: 0;
  -webkit-transform: scale(.5);
      -ms-transform: scale(.5);
       -o-transform: scale(.5);
          transform: scale(.5);
}
.ladda-button[data-style=zoom-out][data-loading] .ladda-spinner {
  margin-left: 0;
  opacity: 1;
  -webkit-transform: none;
      -ms-transform: none;
       -o-transform: none;
          transform: none;
}
.ladda-button[data-style=zoom-in] {
  overflow: hidden;
}
.ladda-button[data-style=zoom-in] .ladda-spinner {
  left: 50%;
  margin-left: -16px;
  -webkit-transform: scale(.2);
      -ms-transform: scale(.2);
       -o-transform: scale(.2);
          transform: scale(.2);
}
.ladda-button[data-style=zoom-in] .ladda-label {
  position: relative;
  display: inline-block;
}
.ladda-button[data-style=zoom-in][data-loading] .ladda-label {
  opacity: 0;
  -webkit-transform: scale(2.2);
      -ms-transform: scale(2.2);
       -o-transform: scale(2.2);
          transform: scale(2.2);
}
.ladda-button[data-style=zoom-in][data-loading] .ladda-spinner {
  margin-left: 0;
  opacity: 1;
  -webkit-transform: none;
      -ms-transform: none;
       -o-transform: none;
          transform: none;
}
.ladda-button[data-style=contract] {
  width: 100px;
  overflow: hidden;
}
.ladda-button[data-style=contract] .ladda-spinner {
  left: 50%;
  margin-left: 0;
}
.ladda-button[data-style=contract][data-loading] {
  width: 52px;
  border-radius: 50%;
}
.ladda-button[data-style=contract][data-loading] .ladda-label {
  opacity: 0;
}
.ladda-button[data-style=contract][data-loading] .ladda-spinner {
  opacity: 1;
}
.ladda-button[data-style=contract-overlay] {
  width: 100px;
  overflow: hidden;
  -webkit-box-shadow: 0 0 0 3000px rgba(0, 0, 0, 0);
          box-shadow: 0 0 0 3000px rgba(0, 0, 0, 0);
}
.ladda-button[data-style=contract-overlay] .ladda-spinner {
  left: 50%;
  margin-left: 0;
}
.ladda-button[data-style=contract-overlay][data-loading] {
  width: 52px;
  border-radius: 50%;
  /*outline: 10000px solid rgba( 0, 0, 0, 0.5 );*/
  -webkit-box-shadow: 0 0 0 3000px rgba(0, 0, 0, .8);
          box-shadow: 0 0 0 3000px rgba(0, 0, 0, .8);
}
.ladda-button[data-style=contract-overlay][data-loading] .ladda-label {
  opacity: 0;
}
.ladda-button[data-style=contract-overlay][data-loading] .ladda-spinner {
  opacity: 1;
}
