.slidePanel {
  position: fixed;
  z-index: 1310;
  max-width: 100%;
  max-height: 100%;
  visibility: hidden;
  background: #fff;
  -webkit-box-shadow: -5px 0 20px 0 rgba(66, 66, 66, .2);
          box-shadow: -5px 0 20px 0 rgba(66, 66, 66, .2);
}
.slidePanel-right,
.slidePanel-left {
  top: 66px;
  bottom: 0;
  width: 700px;
}
@media (max-width: 1199px) {
  .slidePanel-right,
  .slidePanel-left {
    width: -webkit-calc(100% - 230px);
    width:         calc(100% - 230px);
  }
}
@media (max-width: 767px) {
  .slidePanel-right,
  .slidePanel-left {
    width: 100%;
  }
}
.slidePanel-top,
.slidePanel-bottom {
  width: 100%;
  height: 500px;
}
@media (max-width: 1199px) {
  .slidePanel-top,
  .slidePanel-bottom {
    top: 0;
    bottom: 0;
    height: auto;
  }
}
.slidePanel-left {
  left: 0;
}
.slidePanel-right {
  right: 0;
}
.slidePanel-top {
  top: 0;
  left: 0;
}
.slidePanel-bottom {
  bottom: 0;
  left: 0;
}
.slidePanel .scrollable-container {
  height: 100%;
}
.slidePanel-show {
  visibility: visible;
}
.slidePanel-handler {
  position: absolute;
  top: 0;
  left: 0;
  width: 30px;
  height: 100%;
  cursor: e-resize;
  background-color: transparent;
}
.slidePanel-loading {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  font-size: 0;
  text-align: center;
  visibility: hidden;
}
.slidePanel-loading:before {
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  content: "";
}
.slidePanel-loading .loader {
  vertical-align: middle;
}
.slidePanel-loading-show {
  visibility: visible;
}
.slidePanel-scrollable {
  height: 100%;
}
.slidePanel-header {
  position: relative;
  padding: 40px 30px;
  padding-right: 120px;
  color: #fff;
}
.slidePanel-header h1 {
  margin: 0;
  font-size: 22px;
  line-height: 33px;
  color: #fff;
}
.slidePanel-actions {
  float: right;
  min-height: 67px;
  margin: 0;
  margin-left: 15px;
  text-align: right;
  list-style: none;
}
.slidePanel-actions .btn {
  line-height: 33px;
}
.slidePanel-action {
  border: 1px solid transparent;
  border-radius: 3px;
  -webkit-transition: border .25s linear;
       -o-transition: border .25s linear;
          transition: border .25s linear;
}
.slidePanel-action:last-child {
  margin-right: 0;
}
.slidePanel-action.dropdown.open {
  border-color: #fff;
}
.slidePanel-action .icon {
  cursor: pointer;
}
.slidePanel-action .icon:hover {
  color: #ecf0f3;
}
.slidePanel-action .dropdown-menu {
  right: 0;
  left: auto;
  margin-top: 10px;
}
.slidePanel-action .dropdown-menu .icon {
  margin-right: 10px;
}
.slidePanel-inner {
  padding: 0 30px;
}
.slidePanel-inner-section {
  padding-top: 30px;
  padding-bottom: 30px;
  border-bottom: 1px solid #e0e0e0;
}
.slidePanel-comment {
  position: relative;
  margin-top: 50px;
  margin-bottom: 50px;
}
.slidePanel-comment textarea {
  margin-bottom: 5px;
}
.slidePanel-comment .reply {
  position: absolute;
  top: 10px;
  left: 10px;
}
