body {
    color: #757575;
    background-color: #fff
}

a {
    color: #1e88e5
}

a:focus,
a:hover {
    color: #2196f3
}

img {
    vertical-align: middle
}

.img-thumbnail {
    background-color: #fff;
    border: 1px solid #e0e0e0
}

hr {
    border-top: 1px solid #e0e0e0
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
    color: #424242
}

.h1 .small,
.h1 small,
.h2 .small,
.h2 small,
.h3 .small,
.h3 small,
.h4 .small,
.h4 small,
.h5 .small,
.h5 small,
.h6 .small,
.h6 small,
h1 .small,
h1 small,
h2 .small,
h2 small,
h3 .small,
h3 small,
h4 .small,
h4 small,
h5 .small,
h5 small,
h6 .small,
h6 small {
    color: #9e9e9e
}

.mark,
mark {
    background-color: #ff9800
}

.text-muted {
    color: #616161
}

.text-primary {
    color: #2196f3
}

a.text-primary:focus,
a.text-primary:hover {
    color: #0c7cd5
}

.text-success {
    color: #fff
}

a.text-success:focus,
a.text-success:hover {
    color: #e6e6e6
}

.text-info {
    color: #fff
}

a.text-info:focus,
a.text-info:hover {
    color: #e6e6e6
}

.text-warning {
    color: #fff
}

a.text-warning:focus,
a.text-warning:hover {
    color: #e6e6e6
}

.text-danger {
    color: #fff
}

a.text-danger:focus,
a.text-danger:hover {
    color: #e6e6e6
}

.bg-primary {
    color: #fff;
    background-color: #2196f3
}

a.bg-primary:focus,
a.bg-primary:hover {
    background-color: #0c7cd5
}

.bg-success {
    background-color: #4caf50
}

a.bg-success:focus,
a.bg-success:hover {
    background-color: #3d8b40
}

.bg-info {
    background-color: #00bcd4
}

a.bg-info:focus,
a.bg-info:hover {
    background-color: #008fa1
}

.bg-warning {
    background-color: #ff9800
}

a.bg-warning:focus,
a.bg-warning:hover {
    background-color: #cc7a00
}

.bg-danger {
    background-color: #f44336
}

a.bg-danger:focus,
a.bg-danger:hover {
    background-color: #ea1c0d
}

.page-header {
    border-bottom: 1px solid transparent
}

abbr[data-original-title],
abbr[title] {
    border-bottom: 1px dotted #e0e0e0
}

blockquote {
    border-left: 5px solid #e0e0e0
}

blockquote .small,
blockquote footer,
blockquote small {
    color: #9e9e9e
}

.blockquote-reverse,
blockquote.pull-right {
    border-right: 5px solid #e0e0e0
}

code {
    color: #456d9b;
    background-color: rgba(187, 222, 251, .1)
}

kbd {
    color: #fff;
    background-color: #2196f3
}

pre {
    color: inherit;
    background-color: #fff;
    border: 1px solid #e4f2fe
}

table {
    background-color: transparent
}

caption {
    color: #616161
}

.table>tbody>tr>td,
.table>tbody>tr>th,
.table>tfoot>tr>td,
.table>tfoot>tr>th,
.table>thead>tr>td,
.table>thead>tr>th {
    border-top: 1px solid #e0e0e0
}

.table>thead>tr>th {
    border-bottom: 2px solid #e0e0e0
}

.table>tbody+tbody {
    border-top: 2px solid #e0e0e0
}

.table .table {
    background-color: #fff
}

.table-bordered {
    border: 1px solid #e0e0e0
}

.table-bordered>tbody>tr>td,
.table-bordered>tbody>tr>th,
.table-bordered>tfoot>tr>td,
.table-bordered>tfoot>tr>th,
.table-bordered>thead>tr>td,
.table-bordered>thead>tr>th {
    border: 1px solid #e0e0e0
}

.table-striped>tbody>tr:nth-of-type(odd) {
    background-color: rgba(238, 238, 238, .3)
}

.table-hover>tbody>tr:hover {
    background-color: #eee
}

.table>tbody>tr.active>td,
.table>tbody>tr.active>th,
.table>tbody>tr>td.active,
.table>tbody>tr>th.active,
.table>tfoot>tr.active>td,
.table>tfoot>tr.active>th,
.table>tfoot>tr>td.active,
.table>tfoot>tr>th.active,
.table>thead>tr.active>td,
.table>thead>tr.active>th,
.table>thead>tr>td.active,
.table>thead>tr>th.active {
    background-color: #eee
}

.table-hover>tbody>tr.active:hover>td,
.table-hover>tbody>tr.active:hover>th,
.table-hover>tbody>tr:hover>.active,
.table-hover>tbody>tr>td.active:hover,
.table-hover>tbody>tr>th.active:hover {
    background-color: #e1e1e1
}

.table>tbody>tr.success>td,
.table>tbody>tr.success>th,
.table>tbody>tr>td.success,
.table>tbody>tr>th.success,
.table>tfoot>tr.success>td,
.table>tfoot>tr.success>th,
.table>tfoot>tr>td.success,
.table>tfoot>tr>th.success,
.table>thead>tr.success>td,
.table>thead>tr.success>th,
.table>thead>tr>td.success,
.table>thead>tr>th.success {
    background-color: #4caf50
}

.table-hover>tbody>tr.success:hover>td,
.table-hover>tbody>tr.success:hover>th,
.table-hover>tbody>tr:hover>.success,
.table-hover>tbody>tr>td.success:hover,
.table-hover>tbody>tr>th.success:hover {
    background-color: #449d48
}

.table>tbody>tr.info>td,
.table>tbody>tr.info>th,
.table>tbody>tr>td.info,
.table>tbody>tr>th.info,
.table>tfoot>tr.info>td,
.table>tfoot>tr.info>th,
.table>tfoot>tr>td.info,
.table>tfoot>tr>th.info,
.table>thead>tr.info>td,
.table>thead>tr.info>th,
.table>thead>tr>td.info,
.table>thead>tr>th.info {
    background-color: #00bcd4
}

.table-hover>tbody>tr.info:hover>td,
.table-hover>tbody>tr.info:hover>th,
.table-hover>tbody>tr:hover>.info,
.table-hover>tbody>tr>td.info:hover,
.table-hover>tbody>tr>th.info:hover {
    background-color: #00a5bb
}

.table>tbody>tr.warning>td,
.table>tbody>tr.warning>th,
.table>tbody>tr>td.warning,
.table>tbody>tr>th.warning,
.table>tfoot>tr.warning>td,
.table>tfoot>tr.warning>th,
.table>tfoot>tr>td.warning,
.table>tfoot>tr>th.warning,
.table>thead>tr.warning>td,
.table>thead>tr.warning>th,
.table>thead>tr>td.warning,
.table>thead>tr>th.warning {
    background-color: #ff9800
}

.table-hover>tbody>tr.warning:hover>td,
.table-hover>tbody>tr.warning:hover>th,
.table-hover>tbody>tr:hover>.warning,
.table-hover>tbody>tr>td.warning:hover,
.table-hover>tbody>tr>th.warning:hover {
    background-color: #e68900
}

.table>tbody>tr.danger>td,
.table>tbody>tr.danger>th,
.table>tbody>tr>td.danger,
.table>tbody>tr>th.danger,
.table>tfoot>tr.danger>td,
.table>tfoot>tr.danger>th,
.table>tfoot>tr>td.danger,
.table>tfoot>tr>th.danger,
.table>thead>tr.danger>td,
.table>thead>tr.danger>th,
.table>thead>tr>td.danger,
.table>thead>tr>th.danger {
    background-color: #f44336
}

.table-hover>tbody>tr.danger:hover>td,
.table-hover>tbody>tr.danger:hover>th,
.table-hover>tbody>tr:hover>.danger,
.table-hover>tbody>tr>td.danger:hover,
.table-hover>tbody>tr>th.danger:hover {
    background-color: #f32c1e
}

@media screen and (max-width:767px) {
    .table-responsive {
        border: 1px solid #e0e0e0
    }
}

legend {
    color: inherit;
    border-bottom: 1px solid transparent
}

output {
    color: #757575
}

.form-control {
    color: #757575;
    background-color: #fff;
    border: 1px solid #e0e0e0
}

.form-control:focus {
    border-color: #2196f3;
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(33, 150, 243, .6);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(33, 150, 243, .6)
}

.form-control.focus,
.form-control:focus {
    border-color: #2196f3;
    -webkit-box-shadow: none;
    box-shadow: none
}

.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control {
    background-color: #eee
}

.has-success .checkbox,
.has-success .checkbox-inline,
.has-success .control-label,
.has-success .help-block,
.has-success .radio,
.has-success .radio-inline,
.has-success.checkbox label,
.has-success.checkbox-inline label,
.has-success.radio label,
.has-success.radio-inline label {
    color: #fff
}

.has-success .form-control {
    border-color: #fff;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075)
}

.has-success .form-control:focus {
    border-color: #e6e6e6;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 6px #fff;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 6px #fff
}

.has-success .input-group-addon {
    color: #fff;
    background-color: #4caf50;
    border-color: #fff
}

.has-success .form-control-feedback {
    color: #fff
}

.has-success .form-control {
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .05);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .05)
}

.has-success .form-control:focus {
    border-color: #fff;
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(255, 255, 255, .6);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(255, 255, 255, .6)
}

.has-success .form-control.focus,
.has-success .form-control:focus {
    border-color: #fff;
    -webkit-box-shadow: none;
    box-shadow: none
}

.has-warning .checkbox,
.has-warning .checkbox-inline,
.has-warning .control-label,
.has-warning .help-block,
.has-warning .radio,
.has-warning .radio-inline,
.has-warning.checkbox label,
.has-warning.checkbox-inline label,
.has-warning.radio label,
.has-warning.radio-inline label {
    color: #fff
}

.has-warning .form-control {
    border-color: #fff;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075)
}

.has-warning .form-control:focus {
    border-color: #e6e6e6;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 6px #fff;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 6px #fff
}

.has-warning .input-group-addon {
    color: #fff;
    background-color: #ff9800;
    border-color: #fff
}

.has-warning .form-control-feedback {
    color: #fff
}

.has-warning .form-control {
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .05);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .05)
}

.has-warning .form-control:focus {
    border-color: #fff;
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(255, 255, 255, .6);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(255, 255, 255, .6)
}

.has-warning .form-control.focus,
.has-warning .form-control:focus {
    border-color: #fff;
    -webkit-box-shadow: none;
    box-shadow: none
}

.has-error .checkbox,
.has-error .checkbox-inline,
.has-error .control-label,
.has-error .help-block,
.has-error .radio,
.has-error .radio-inline,
.has-error.checkbox label,
.has-error.checkbox-inline label,
.has-error.radio label,
.has-error.radio-inline label {
    color: #fff
}

.has-error .form-control {
    border-color: #fff;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075)
}

.has-error .form-control:focus {
    border-color: #e6e6e6;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 6px #fff;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 6px #fff
}

.has-error .input-group-addon {
    color: #fff;
    background-color: #f44336;
    border-color: #fff
}

.has-error .form-control-feedback {
    color: #fff
}

.has-error .form-control {
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .05);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .05)
}

.has-error .form-control:focus {
    border-color: #fff;
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(255, 255, 255, .6);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(255, 255, 255, .6)
}

.has-error .form-control.focus,
.has-error .form-control:focus {
    border-color: #fff;
    -webkit-box-shadow: none;
    box-shadow: none
}

.help-block {
    color: #b5b5b5
}

.help-block-error {
    color: #f44336
}

.btn.focus,
.btn:focus,
.btn:hover {
    color: #757575
}

.btn-default {
    color: #757575;
    background-color: #eee;
    border-color: #eee
}

.btn-default.focus,
.btn-default:focus {
    color: #757575;
    background-color: #d5d5d5;
    border-color: #aeaeae
}

.btn-default:hover {
    color: #757575;
    background-color: #d5d5d5;
    border-color: #cfcfcf
}

.btn-default.active,
.btn-default:active,
.open>.dropdown-toggle.btn-default {
    color: #757575;
    background-color: #d5d5d5;
    border-color: #cfcfcf
}

.btn-default.active.focus,
.btn-default.active:focus,
.btn-default.active:hover,
.btn-default:active.focus,
.btn-default:active:focus,
.btn-default:active:hover,
.open>.dropdown-toggle.btn-default.focus,
.open>.dropdown-toggle.btn-default:focus,
.open>.dropdown-toggle.btn-default:hover {
    color: #757575;
    background-color: #c3c3c3;
    border-color: #aeaeae
}

.btn-default.active,
.btn-default:active,
.open>.dropdown-toggle.btn-default {
    background-image: none
}

.btn-default.disabled.focus,
.btn-default.disabled:focus,
.btn-default.disabled:hover,
.btn-default[disabled].focus,
.btn-default[disabled]:focus,
.btn-default[disabled]:hover,
fieldset[disabled] .btn-default.focus,
fieldset[disabled] .btn-default:focus,
fieldset[disabled] .btn-default:hover {
    background-color: #eee;
    border-color: #eee
}

.btn-default .badge {
    color: #eee;
    background-color: #757575
}

.btn-primary {
    color: #fff;
    background-color: #2196f3;
    border-color: #2196f3
}

.btn-primary.focus,
.btn-primary:focus {
    color: #fff;
    background-color: #0c7cd5;
    border-color: #08528d
}

.btn-primary:hover {
    color: #fff;
    background-color: #0c7cd5;
    border-color: #0b76cc
}

.btn-primary.active,
.btn-primary:active,
.open>.dropdown-toggle.btn-primary {
    color: #fff;
    background-color: #0c7cd5;
    border-color: #0b76cc
}

.btn-primary.active.focus,
.btn-primary.active:focus,
.btn-primary.active:hover,
.btn-primary:active.focus,
.btn-primary:active:focus,
.btn-primary:active:hover,
.open>.dropdown-toggle.btn-primary.focus,
.open>.dropdown-toggle.btn-primary:focus,
.open>.dropdown-toggle.btn-primary:hover {
    color: #fff;
    background-color: #0a68b4;
    border-color: #08528d
}

.btn-primary.active,
.btn-primary:active,
.open>.dropdown-toggle.btn-primary {
    background-image: none
}

.btn-primary.disabled.focus,
.btn-primary.disabled:focus,
.btn-primary.disabled:hover,
.btn-primary[disabled].focus,
.btn-primary[disabled]:focus,
.btn-primary[disabled]:hover,
fieldset[disabled] .btn-primary.focus,
fieldset[disabled] .btn-primary:focus,
fieldset[disabled] .btn-primary:hover {
    background-color: #2196f3;
    border-color: #2196f3
}

.btn-primary .badge {
    color: #2196f3;
    background-color: #fff
}

.btn-success {
    color: #fff;
    background-color: #4caf50;
    border-color: #4caf50
}

.btn-success.focus,
.btn-success:focus {
    color: #fff;
    background-color: #3d8b40;
    border-color: #255627
}

.btn-success:hover {
    color: #fff;
    background-color: #3d8b40;
    border-color: #39843c
}

.btn-success.active,
.btn-success:active,
.open>.dropdown-toggle.btn-success {
    color: #fff;
    background-color: #3d8b40;
    border-color: #39843c
}

.btn-success.active.focus,
.btn-success.active:focus,
.btn-success.active:hover,
.btn-success:active.focus,
.btn-success:active:focus,
.btn-success:active:hover,
.open>.dropdown-toggle.btn-success.focus,
.open>.dropdown-toggle.btn-success:focus,
.open>.dropdown-toggle.btn-success:hover {
    color: #fff;
    background-color: #327334;
    border-color: #255627
}

.btn-success.active,
.btn-success:active,
.open>.dropdown-toggle.btn-success {
    background-image: none
}

.btn-success.disabled.focus,
.btn-success.disabled:focus,
.btn-success.disabled:hover,
.btn-success[disabled].focus,
.btn-success[disabled]:focus,
.btn-success[disabled]:hover,
fieldset[disabled] .btn-success.focus,
fieldset[disabled] .btn-success:focus,
fieldset[disabled] .btn-success:hover {
    background-color: #4caf50;
    border-color: #4caf50
}

.btn-success .badge {
    color: #4caf50;
    background-color: #fff
}

.btn-info {
    color: #fff;
    background-color: #00bcd4;
    border-color: #00bcd4
}

.btn-info.focus,
.btn-info:focus {
    color: #fff;
    background-color: #008fa1;
    border-color: #004b55
}

.btn-info:hover {
    color: #fff;
    background-color: #008fa1;
    border-color: #008697
}

.btn-info.active,
.btn-info:active,
.open>.dropdown-toggle.btn-info {
    color: #fff;
    background-color: #008fa1;
    border-color: #008697
}

.btn-info.active.focus,
.btn-info.active:focus,
.btn-info.active:hover,
.btn-info:active.focus,
.btn-info:active:focus,
.btn-info:active:hover,
.open>.dropdown-toggle.btn-info.focus,
.open>.dropdown-toggle.btn-info:focus,
.open>.dropdown-toggle.btn-info:hover {
    color: #fff;
    background-color: #006f7d;
    border-color: #004b55
}

.btn-info.active,
.btn-info:active,
.open>.dropdown-toggle.btn-info {
    background-image: none
}

.btn-info.disabled.focus,
.btn-info.disabled:focus,
.btn-info.disabled:hover,
.btn-info[disabled].focus,
.btn-info[disabled]:focus,
.btn-info[disabled]:hover,
fieldset[disabled] .btn-info.focus,
fieldset[disabled] .btn-info:focus,
fieldset[disabled] .btn-info:hover {
    background-color: #00bcd4;
    border-color: #00bcd4
}

.btn-info .badge {
    color: #00bcd4;
    background-color: #fff
}

.btn-warning {
    color: #fff;
    background-color: #ff9800;
    border-color: #ff9800
}

.btn-warning.focus,
.btn-warning:focus {
    color: #fff;
    background-color: #cc7a00;
    border-color: #804c00
}

.btn-warning:hover {
    color: #fff;
    background-color: #cc7a00;
    border-color: #c27400
}

.btn-warning.active,
.btn-warning:active,
.open>.dropdown-toggle.btn-warning {
    color: #fff;
    background-color: #cc7a00;
    border-color: #c27400
}

.btn-warning.active.focus,
.btn-warning.active:focus,
.btn-warning.active:hover,
.btn-warning:active.focus,
.btn-warning:active:focus,
.btn-warning:active:hover,
.open>.dropdown-toggle.btn-warning.focus,
.open>.dropdown-toggle.btn-warning:focus,
.open>.dropdown-toggle.btn-warning:hover {
    color: #fff;
    background-color: #a86400;
    border-color: #804c00
}

.btn-warning.active,
.btn-warning:active,
.open>.dropdown-toggle.btn-warning {
    background-image: none
}

.btn-warning.disabled.focus,
.btn-warning.disabled:focus,
.btn-warning.disabled:hover,
.btn-warning[disabled].focus,
.btn-warning[disabled]:focus,
.btn-warning[disabled]:hover,
fieldset[disabled] .btn-warning.focus,
fieldset[disabled] .btn-warning:focus,
fieldset[disabled] .btn-warning:hover {
    background-color: #ff9800;
    border-color: #ff9800
}

.btn-warning .badge {
    color: #ff9800;
    background-color: #fff
}

.btn-danger {
    color: #fff;
    background-color: #f44336;
    border-color: #f44336
}

.btn-danger.focus,
.btn-danger:focus {
    color: #fff;
    background-color: #ea1c0d;
    border-color: #a21309
}

.btn-danger:hover {
    color: #fff;
    background-color: #ea1c0d;
    border-color: #e11b0c
}

.btn-danger.active,
.btn-danger:active,
.open>.dropdown-toggle.btn-danger {
    color: #fff;
    background-color: #ea1c0d;
    border-color: #e11b0c
}

.btn-danger.active.focus,
.btn-danger.active:focus,
.btn-danger.active:hover,
.btn-danger:active.focus,
.btn-danger:active:focus,
.btn-danger:active:hover,
.open>.dropdown-toggle.btn-danger.focus,
.open>.dropdown-toggle.btn-danger:focus,
.open>.dropdown-toggle.btn-danger:hover {
    color: #fff;
    background-color: #c8180b;
    border-color: #a21309
}

.btn-danger.active,
.btn-danger:active,
.open>.dropdown-toggle.btn-danger {
    background-image: none
}

.btn-danger.disabled.focus,
.btn-danger.disabled:focus,
.btn-danger.disabled:hover,
.btn-danger[disabled].focus,
.btn-danger[disabled]:focus,
.btn-danger[disabled]:hover,
fieldset[disabled] .btn-danger.focus,
fieldset[disabled] .btn-danger:focus,
fieldset[disabled] .btn-danger:hover {
    background-color: #f44336;
    border-color: #f44336
}

.btn-danger .badge {
    color: #f44336;
    background-color: #fff
}

.btn-link {
    color: #1e88e5
}

.btn-link:focus,
.btn-link:hover {
    color: #2196f3;
    text-decoration: underline
}

.btn-link[disabled]:focus,
.btn-link[disabled]:hover,
fieldset[disabled] .btn-link:focus,
fieldset[disabled] .btn-link:hover {
    color: #9e9e9e
}

.dropdown-menu {
    background-color: #fff;
    border: 1px solid #ccc;
    border: 1px solid #e0e0e0
}

.dropdown-menu .divider {
    height: 1px;
    margin: 10px 0;
    overflow: hidden;
    background-color: #e0e0e0
}

.dropdown-menu>li>a {
    color: #757575
}

.dropdown-menu>li>a:focus,
.dropdown-menu>li>a:hover {
    color: #757575;
    background-color: #eee
}

.dropdown-menu>.active>a,
.dropdown-menu>.active>a:focus,
.dropdown-menu>.active>a:hover {
    color: #757575;
    background-color: #eee
}

.dropdown-menu>.disabled>a,
.dropdown-menu>.disabled>a:focus,
.dropdown-menu>.disabled>a:hover {
    color: #bdbdbd
}

.dropdown-header {
    color: #424242
}

.btn-group.open .dropdown-toggle {
    -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125)
}

[data-toggle=buttons]>.btn input[type=radio],
[data-toggle=buttons]>.btn input[type=checkbox],
[data-toggle=buttons]>.btn-group>.btn input[type=radio],
[data-toggle=buttons]>.btn-group>.btn input[type=checkbox] {
    clip: rect(0, 0, 0, 0)
}

.input-group-addon {
    color: #757575;
    background-color: #eee;
    border: 1px solid #e0e0e0
}

.nav>li>a:focus,
.nav>li>a:hover {
    background-color: #eee
}

.nav>li.disabled>a {
    color: #9e9e9e
}

.nav>li.disabled>a:focus,
.nav>li.disabled>a:hover {
    color: #9e9e9e
}

.nav .open>a,
.nav .open>a:focus,
.nav .open>a:hover {
    background-color: #eee;
    border-color: #1e88e5
}

.nav-tabs {
    border-bottom: 1px solid #e0e0e0
}

.nav-tabs>li>a:hover {
    border-color: transparent transparent #e0e0e0
}

.nav-tabs>li.active>a,
.nav-tabs>li.active>a:focus,
.nav-tabs>li.active>a:hover {
    color: #1976d2;
    background-color: #fff;
    border: 1px solid #e0e0e0
}

.nav-pills>li.active>a,
.nav-pills>li.active>a:focus,
.nav-pills>li.active>a:hover {
    color: #fff;
    background-color: #2196f3
}

.nav-tabs-justified>.active>a,
.nav-tabs-justified>.active>a:focus,
.nav-tabs-justified>.active>a:hover {
    border: 1px solid #e0e0e0
}

@media (min-width:768px) {
    .nav-tabs-justified>li>a {
        border-bottom: 1px solid #e0e0e0
    }
    .nav-tabs-justified>.active>a,
    .nav-tabs-justified>.active>a:focus,
    .nav-tabs-justified>.active>a:hover {
        border-bottom-color: #fff
    }
}

.navbar-default {
    background-color: #fff;
    border-color: #e0e0e0
}

.navbar-default .navbar-brand {
    color: #424242
}

.navbar-default .navbar-brand:focus,
.navbar-default .navbar-brand:hover {
    color: #424242;
    background-color: none
}

.navbar-default .navbar-text {
    color: #757575
}

.navbar-default .navbar-nav>li>a {
    color: #757575
}

.navbar-default .navbar-nav>li>a:focus,
.navbar-default .navbar-nav>li>a:hover {
    color: #616161;
    background-color: rgba(238, 238, 238, .3)
}

.navbar-default .navbar-nav>.active>a,
.navbar-default .navbar-nav>.active>a:focus,
.navbar-default .navbar-nav>.active>a:hover {
    color: #616161;
    background-color: rgba(238, 238, 238, .6)
}

.navbar-default .navbar-nav>.disabled>a,
.navbar-default .navbar-nav>.disabled>a:focus,
.navbar-default .navbar-nav>.disabled>a:hover {
    color: #bdbdbd;
    background-color: transparent
}

.navbar-default .navbar-toggle {
    border-color: transparent
}

.navbar-default .navbar-toggle:focus,
.navbar-default .navbar-toggle:hover {
    background-color: rgba(238, 238, 238, .3)
}

.navbar-default .navbar-toggle .icon-bar {
    background-color: #757575
}

.navbar-default .navbar-collapse,
.navbar-default .navbar-form {
    border-color: #e0e0e0
}

.navbar-default .navbar-nav>.open>a,
.navbar-default .navbar-nav>.open>a:focus,
.navbar-default .navbar-nav>.open>a:hover {
    color: #616161;
    background-color: rgba(238, 238, 238, .6)
}

@media (max-width:767px) {
    .navbar-default .navbar-nav .open .dropdown-menu>li>a {
        color: #757575
    }
    .navbar-default .navbar-nav .open .dropdown-menu>li>a:focus,
    .navbar-default .navbar-nav .open .dropdown-menu>li>a:hover {
        color: #616161;
        background-color: rgba(238, 238, 238, .3)
    }
    .navbar-default .navbar-nav .open .dropdown-menu>.active>a,
    .navbar-default .navbar-nav .open .dropdown-menu>.active>a:focus,
    .navbar-default .navbar-nav .open .dropdown-menu>.active>a:hover {
        color: #616161;
        background-color: rgba(238, 238, 238, .6)
    }
    .navbar-default .navbar-nav .open .dropdown-menu>.disabled>a,
    .navbar-default .navbar-nav .open .dropdown-menu>.disabled>a:focus,
    .navbar-default .navbar-nav .open .dropdown-menu>.disabled>a:hover {
        color: #bdbdbd;
        background-color: transparent
    }
}

.navbar-default .navbar-link {
    color: #757575
}

.navbar-default .navbar-link:hover {
    color: #616161
}

.navbar-default .btn-link {
    color: #757575
}

.navbar-default .btn-link:focus,
.navbar-default .btn-link:hover {
    color: #616161
}

.navbar-default .btn-link[disabled]:focus,
.navbar-default .btn-link[disabled]:hover,
fieldset[disabled] .navbar-default .btn-link:focus,
fieldset[disabled] .navbar-default .btn-link:hover {
    color: #bdbdbd
}

.navbar-inverse {
    background-color: #2196f3;
    border-color: rgba(0, 0, 0, .1)
}

.navbar-inverse .navbar-brand {
    color: #fff
}

.navbar-inverse .navbar-brand:focus,
.navbar-inverse .navbar-brand:hover {
    color: #fff;
    background-color: none
}

.navbar-inverse .navbar-text {
    color: #fff
}

.navbar-inverse .navbar-nav>li>a {
    color: #fff
}

.navbar-inverse .navbar-nav>li>a:focus,
.navbar-inverse .navbar-nav>li>a:hover {
    color: #fff;
    background-color: rgba(0, 0, 0, .1)
}

.navbar-inverse .navbar-nav>.active>a,
.navbar-inverse .navbar-nav>.active>a:focus,
.navbar-inverse .navbar-nav>.active>a:hover {
    color: #fff;
    background-color: rgba(0, 0, 0, .1)
}

.navbar-inverse .navbar-nav>.disabled>a,
.navbar-inverse .navbar-nav>.disabled>a:focus,
.navbar-inverse .navbar-nav>.disabled>a:hover {
    color: #fff;
    background-color: transparent
}

.navbar-inverse .navbar-toggle {
    border-color: transparent
}

.navbar-inverse .navbar-toggle:focus,
.navbar-inverse .navbar-toggle:hover {
    background-color: rgba(0, 0, 0, .1)
}

.navbar-inverse .navbar-toggle .icon-bar {
    background-color: #fff
}

.navbar-inverse .navbar-collapse,
.navbar-inverse .navbar-form {
    border-color: #0c84e4
}

.navbar-inverse .navbar-nav>.open>a,
.navbar-inverse .navbar-nav>.open>a:focus,
.navbar-inverse .navbar-nav>.open>a:hover {
    color: #fff;
    background-color: rgba(0, 0, 0, .1)
}

@media (max-width:767px) {
    .navbar-inverse .navbar-nav .open .dropdown-menu>.dropdown-header {
        border-color: rgba(0, 0, 0, .1)
    }
    .navbar-inverse .navbar-nav .open .dropdown-menu .divider {
        background-color: rgba(0, 0, 0, .1)
    }
    .navbar-inverse .navbar-nav .open .dropdown-menu>li>a {
        color: #fff
    }
    .navbar-inverse .navbar-nav .open .dropdown-menu>li>a:focus,
    .navbar-inverse .navbar-nav .open .dropdown-menu>li>a:hover {
        color: #fff;
        background-color: rgba(0, 0, 0, .1)
    }
    .navbar-inverse .navbar-nav .open .dropdown-menu>.active>a,
    .navbar-inverse .navbar-nav .open .dropdown-menu>.active>a:focus,
    .navbar-inverse .navbar-nav .open .dropdown-menu>.active>a:hover {
        color: #fff;
        background-color: rgba(0, 0, 0, .1)
    }
    .navbar-inverse .navbar-nav .open .dropdown-menu>.disabled>a,
    .navbar-inverse .navbar-nav .open .dropdown-menu>.disabled>a:focus,
    .navbar-inverse .navbar-nav .open .dropdown-menu>.disabled>a:hover {
        color: #fff;
        background-color: transparent
    }
}

.navbar-inverse .navbar-link {
    color: #fff
}

.navbar-inverse .navbar-link:hover {
    color: #fff
}

.navbar-inverse .btn-link {
    color: #fff
}

.navbar-inverse .btn-link:focus,
.navbar-inverse .btn-link:hover {
    color: #fff
}

.navbar-inverse .btn-link[disabled]:focus,
.navbar-inverse .btn-link[disabled]:hover,
fieldset[disabled] .navbar-inverse .btn-link:focus,
fieldset[disabled] .navbar-inverse .btn-link:hover {
    color: #fff
}

.breadcrumb {
    background-color: transparent
}

.breadcrumb>li+li:before {
    color: #1e88e5
}

.breadcrumb>.active {
    color: #757575
}

.pagination>li>a,
.pagination>li>span {
    color: #757575;
    background-color: transparent;
    border: 1px solid #e0e0e0
}

.pagination>li>a:focus,
.pagination>li>a:hover,
.pagination>li>span:focus,
.pagination>li>span:hover {
    color: #2196f3;
    background-color: #eee;
    border-color: #e0e0e0
}

.pagination>.active>a,
.pagination>.active>a:focus,
.pagination>.active>a:hover,
.pagination>.active>span,
.pagination>.active>span:focus,
.pagination>.active>span:hover {
    color: #fff;
    background-color: #2196f3;
    border-color: #2196f3
}

.pagination>.disabled>a,
.pagination>.disabled>a:focus,
.pagination>.disabled>a:hover,
.pagination>.disabled>span,
.pagination>.disabled>span:focus,
.pagination>.disabled>span:hover {
    color: #bdbdbd;
    background-color: transparent;
    border-color: #e0e0e0
}

.pager li>a,
.pager li>span {
    background-color: transparent;
    border: 1px solid #e0e0e0
}

.pager li>a:focus,
.pager li>a:hover {
    background-color: #fff
}

.pager .disabled>a,
.pager .disabled>a:focus,
.pager .disabled>a:hover,
.pager .disabled>span {
    color: #bdbdbd;
    background-color: transparent
}

.label {
    color: #fff
}

a.label:focus,
a.label:hover {
    color: #fff
}

.label-default {
    background-color: #e0e0e0
}

.label-default[href]:focus,
.label-default[href]:hover {
    background-color: #c7c7c7
}

.label-primary {
    background-color: #2196f3
}

.label-primary[href]:focus,
.label-primary[href]:hover {
    background-color: #0c7cd5
}

.label-success {
    background-color: #4caf50
}

.label-success[href]:focus,
.label-success[href]:hover {
    background-color: #3d8b40
}

.label-info {
    background-color: #00bcd4
}

.label-info[href]:focus,
.label-info[href]:hover {
    background-color: #008fa1
}

.label-warning {
    background-color: #ff9800
}

.label-warning[href]:focus,
.label-warning[href]:hover {
    background-color: #cc7a00
}

.label-danger {
    background-color: #f44336
}

.label-danger[href]:focus,
.label-danger[href]:hover {
    background-color: #ea1c0d
}

.badge {
    color: #757575;
    background-color: #e0e0e0
}

a.badge:focus,
a.badge:hover {
    color: #9e9e9e
}

.list-group-item.active>.badge,
.nav-pills>.active>a>.badge {
    color: #616161;
    background-color: #e0e0e0
}

.jumbotron {
    color: inherit;
    background-color: #e0e0e0
}

.jumbotron .h1,
.jumbotron h1 {
    color: inherit
}

.jumbotron>hr {
    border-top-color: #c7c7c7
}

.thumbnail {
    background-color: #fff;
    border: 1px solid #e0e0e0
}

a.thumbnail.active,
a.thumbnail:focus,
a.thumbnail:hover {
    border-color: #1e88e5
}

.thumbnail .caption {
    color: #757575
}

.alert-success {
    color: #4caf50;
    background-color: rgba(200, 230, 201, .8);
    border-color: #c8e6c9
}

.alert-success hr {
    border-top-color: #b6deb8
}

.alert-success .alert-link {
    color: #3d8b40
}

.alert-success .close {
    color: #4caf50
}

.alert-success .close:focus,
.alert-success .close:hover {
    color: #4caf50
}

.alert-info {
    color: #00bcd4;
    background-color: rgba(178, 235, 242, .8);
    border-color: #b2ebf2
}

.alert-info hr {
    border-top-color: #9ce5ee
}

.alert-info .alert-link {
    color: #008fa1
}

.alert-info .close {
    color: #00bcd4
}

.alert-info .close:focus,
.alert-info .close:hover {
    color: #00bcd4
}

.alert-warning {
    color: #ff9800;
    background-color: rgba(255, 224, 178, .8);
    border-color: #ffe0b2
}

.alert-warning hr {
    border-top-color: #ffd699
}

.alert-warning .alert-link {
    color: #cc7a00
}

.alert-warning .close {
    color: #ff9800
}

.alert-warning .close:focus,
.alert-warning .close:hover {
    color: #ff9800
}

.alert-danger {
    color: #f44336;
    background-color: rgba(255, 205, 210, .8);
    border-color: #ffcdd2
}

.alert-danger hr {
    border-top-color: #ffb3bb
}

.alert-danger .alert-link {
    color: #ea1c0d
}

.alert-danger .close {
    color: #f44336
}

.alert-danger .close:focus,
.alert-danger .close:hover {
    color: #f44336
}

.progress {
    background-color: #e0e0e0
}

.progress-bar {
    color: #fff;
    background-color: #2196f3
}

.progress-bar-success {
    background-color: #4caf50
}

.progress-striped .progress-bar-success {
    background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, .15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, transparent 75%, transparent);
    background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, .15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, transparent 75%, transparent);
    background-image: linear-gradient(45deg, rgba(255, 255, 255, .15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, transparent 75%, transparent)
}

.progress-bar-info {
    background-color: #00bcd4
}

.progress-striped .progress-bar-info {
    background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, .15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, transparent 75%, transparent);
    background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, .15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, transparent 75%, transparent);
    background-image: linear-gradient(45deg, rgba(255, 255, 255, .15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, transparent 75%, transparent)
}

.progress-bar-warning {
    background-color: #ff9800
}

.progress-striped .progress-bar-warning {
    background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, .15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, transparent 75%, transparent);
    background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, .15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, transparent 75%, transparent);
    background-image: linear-gradient(45deg, rgba(255, 255, 255, .15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, transparent 75%, transparent)
}

.progress-bar-danger {
    background-color: #f44336
}

.progress-striped .progress-bar-danger {
    background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, .15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, transparent 75%, transparent);
    background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, .15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, transparent 75%, transparent);
    background-image: linear-gradient(45deg, rgba(255, 255, 255, .15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, transparent 75%, transparent)
}

.list-group-item {
    background-color: #fff;
    border: 1px solid transparent
}

a.list-group-item,
button.list-group-item {
    color: #757575
}

a.list-group-item .list-group-item-heading,
button.list-group-item .list-group-item-heading {
    color: #424242
}

a.list-group-item:focus,
a.list-group-item:hover,
button.list-group-item:focus,
button.list-group-item:hover {
    color: #757575;
    background-color: #eee
}

.list-group-item.disabled,
.list-group-item.disabled:focus,
.list-group-item.disabled:hover {
    color: #bdbdbd;
    background-color: transparent
}

.list-group-item.disabled .list-group-item-text,
.list-group-item.disabled:focus .list-group-item-text,
.list-group-item.disabled:hover .list-group-item-text {
    color: #bdbdbd
}

.list-group-item.active,
.list-group-item.active:focus,
.list-group-item.active:hover {
    color: #2196f3;
    background-color: transparent;
    border-color: transparent
}

.list-group-item.active .list-group-item-text,
.list-group-item.active:focus .list-group-item-text,
.list-group-item.active:hover .list-group-item-text {
    color: #e3f2fd
}

.list-group-item-success {
    color: #fff;
    background-color: #4caf50
}

a.list-group-item-success,
button.list-group-item-success {
    color: #fff
}

a.list-group-item-success .list-group-item-heading,
button.list-group-item-success .list-group-item-heading {
    color: inherit
}

a.list-group-item-success:focus,
a.list-group-item-success:hover,
button.list-group-item-success:focus,
button.list-group-item-success:hover {
    color: #fff;
    background-color: #449d48
}

a.list-group-item-success.active,
a.list-group-item-success.active:focus,
a.list-group-item-success.active:hover,
button.list-group-item-success.active,
button.list-group-item-success.active:focus,
button.list-group-item-success.active:hover {
    color: #fff;
    background-color: #fff;
    border-color: #fff
}

.list-group-item-info {
    color: #fff;
    background-color: #00bcd4
}

a.list-group-item-info,
button.list-group-item-info {
    color: #fff
}

a.list-group-item-info .list-group-item-heading,
button.list-group-item-info .list-group-item-heading {
    color: inherit
}

a.list-group-item-info:focus,
a.list-group-item-info:hover,
button.list-group-item-info:focus,
button.list-group-item-info:hover {
    color: #fff;
    background-color: #00a5bb
}

a.list-group-item-info.active,
a.list-group-item-info.active:focus,
a.list-group-item-info.active:hover,
button.list-group-item-info.active,
button.list-group-item-info.active:focus,
button.list-group-item-info.active:hover {
    color: #fff;
    background-color: #fff;
    border-color: #fff
}

.list-group-item-warning {
    color: #fff;
    background-color: #ff9800
}

a.list-group-item-warning,
button.list-group-item-warning {
    color: #fff
}

a.list-group-item-warning .list-group-item-heading,
button.list-group-item-warning .list-group-item-heading {
    color: inherit
}

a.list-group-item-warning:focus,
a.list-group-item-warning:hover,
button.list-group-item-warning:focus,
button.list-group-item-warning:hover {
    color: #fff;
    background-color: #e68900
}

a.list-group-item-warning.active,
a.list-group-item-warning.active:focus,
a.list-group-item-warning.active:hover,
button.list-group-item-warning.active,
button.list-group-item-warning.active:focus,
button.list-group-item-warning.active:hover {
    color: #fff;
    background-color: #fff;
    border-color: #fff
}

.list-group-item-danger {
    color: #fff;
    background-color: #f44336
}

a.list-group-item-danger,
button.list-group-item-danger {
    color: #fff
}

a.list-group-item-danger .list-group-item-heading,
button.list-group-item-danger .list-group-item-heading {
    color: inherit
}

a.list-group-item-danger:focus,
a.list-group-item-danger:hover,
button.list-group-item-danger:focus,
button.list-group-item-danger:hover {
    color: #fff;
    background-color: #f32c1e
}

a.list-group-item-danger.active,
a.list-group-item-danger.active:focus,
a.list-group-item-danger.active:hover,
button.list-group-item-danger.active,
button.list-group-item-danger.active:focus,
button.list-group-item-danger.active:hover {
    color: #fff;
    background-color: #fff;
    border-color: #fff
}

.panel {
    background-color: #fff
}

.panel-footer {
    background-color: transparent;
    border-top: 1px solid #e0e0e0
}

.panel>.panel-body+.table,
.panel>.panel-body+.table-responsive,
.panel>.table+.panel-body,
.panel>.table-responsive+.panel-body {
    border-top: 1px solid #e0e0e0
}

.panel-group .panel-footer+.panel-collapse .panel-body {
    border-bottom: 1px solid #e0e0e0
}

.panel-default {
    border-color: #e0e0e0
}

.panel-default>.panel-heading {
    color: #757575;
    background-color: #e0e0e0;
    border-color: #e0e0e0
}

.panel-default>.panel-heading+.panel-collapse>.panel-body {
    border-top-color: #e0e0e0
}

.panel-default>.panel-heading .badge {
    color: #e0e0e0;
    background-color: #757575
}

.panel-default>.panel-footer+.panel-collapse>.panel-body {
    border-bottom-color: #e0e0e0
}

.panel-primary {
    border-color: #2196f3
}

.panel-primary>.panel-heading {
    color: #fff;
    background-color: #2196f3;
    border-color: #2196f3
}

.panel-primary>.panel-heading+.panel-collapse>.panel-body {
    border-top-color: #2196f3
}

.panel-primary>.panel-heading .badge {
    color: #2196f3;
    background-color: #fff
}

.panel-primary>.panel-footer+.panel-collapse>.panel-body {
    border-bottom-color: #2196f3
}

.panel-success {
    border-color: #509d44
}

.panel-success>.panel-heading {
    color: #fff;
    background-color: #4caf50;
    border-color: #509d44
}

.panel-success>.panel-heading+.panel-collapse>.panel-body {
    border-top-color: #509d44
}

.panel-success>.panel-heading .badge {
    color: #4caf50;
    background-color: #fff
}

.panel-success>.panel-footer+.panel-collapse>.panel-body {
    border-bottom-color: #509d44
}

.panel-info {
    border-color: #00b0a7
}

.panel-info>.panel-heading {
    color: #fff;
    background-color: #00bcd4;
    border-color: #00b0a7
}

.panel-info>.panel-heading+.panel-collapse>.panel-body {
    border-top-color: #00b0a7
}

.panel-info>.panel-heading .badge {
    color: #00bcd4;
    background-color: #fff
}

.panel-info>.panel-footer+.panel-collapse>.panel-body {
    border-bottom-color: #00b0a7
}

.panel-warning {
    border-color: #f06700
}

.panel-warning>.panel-heading {
    color: #fff;
    background-color: #ff9800;
    border-color: #f06700
}

.panel-warning>.panel-heading+.panel-collapse>.panel-body {
    border-top-color: #f06700
}

.panel-warning>.panel-heading .badge {
    color: #ff9800;
    background-color: #fff
}

.panel-warning>.panel-footer+.panel-collapse>.panel-body {
    border-bottom-color: #f06700
}

.panel-danger {
    border-color: #f3273c
}

.panel-danger>.panel-heading {
    color: #fff;
    background-color: #f44336;
    border-color: #f3273c
}

.panel-danger>.panel-heading+.panel-collapse>.panel-body {
    border-top-color: #f3273c
}

.panel-danger>.panel-heading .badge {
    color: #f44336;
    background-color: #fff
}

.panel-danger>.panel-footer+.panel-collapse>.panel-body {
    border-bottom-color: #f3273c
}

.well {
    background-color: #eee;
    border: 1px solid #e0e0e0
}

.close {
    color: #000;
    text-shadow: none
}

.close:focus,
.close:hover {
    color: #000
}

.modal-content {
    background-color: #fff;
    border: 1px solid #999;
    border: 1px solid transparent
}

.modal-backdrop {
    background-color: #000
}

.modal-header {
    border-bottom: 1px solid #e0e0e0
}

.modal-footer {
    border-top: 1px solid #e0e0e0
}

.tooltip-inner {
    color: #fff;
    background-color: rgba(0, 0, 0, .8)
}

.tooltip.top .tooltip-arrow {
    border-top-color: rgba(0, 0, 0, .8)
}

.tooltip.top-left .tooltip-arrow {
    border-top-color: rgba(0, 0, 0, .8)
}

.tooltip.top-right .tooltip-arrow {
    border-top-color: rgba(0, 0, 0, .8)
}

.tooltip.right .tooltip-arrow {
    border-right-color: rgba(0, 0, 0, .8)
}

.tooltip.left .tooltip-arrow {
    border-left-color: rgba(0, 0, 0, .8)
}

.tooltip.bottom .tooltip-arrow {
    border-bottom-color: rgba(0, 0, 0, .8)
}

.tooltip.bottom-left .tooltip-arrow {
    border-bottom-color: rgba(0, 0, 0, .8)
}

.tooltip.bottom-right .tooltip-arrow {
    border-bottom-color: rgba(0, 0, 0, .8)
}

.popover {
    background-color: #fff;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    border: 1px solid #e0e0e0;
    border: 1px solid rgba(189, 189, 189, .8)
}

.popover-title {
    background-color: #eee;
    border-bottom: 1px solid #e1e1e1
}

.popover.top>.arrow {
    border-top-color: #adadad;
    border-top-color: rgba(189, 189, 189, .85)
}

.popover.top>.arrow:after {
    border-top-color: #fff
}

.popover.right>.arrow {
    border-right-color: #adadad;
    border-right-color: rgba(189, 189, 189, .85)
}

.popover.right>.arrow:after {
    border-right-color: #fff
}

.popover.bottom>.arrow {
    border-bottom-color: #adadad;
    border-bottom-color: rgba(189, 189, 189, .85)
}

.popover.bottom>.arrow:after {
    border-bottom-color: #fff
}

.popover.left>.arrow {
    border-left-color: #adadad;
    border-left-color: rgba(189, 189, 189, .85)
}

.popover.left>.arrow:after {
    border-left-color: #fff
}

.carousel-control {
    color: #fff;
    text-shadow: 0 1px 2px rgba(0, 0, 0, .6)
}

.carousel-control:focus,
.carousel-control:hover {
    color: #fff
}

.carousel-indicators li {
    border: 1px solid #fff
}

.carousel-indicators .active {
    background-color: #fff
}

.carousel-caption {
    color: #fff;
    text-shadow: 0 1px 2px rgba(0, 0, 0, .6)
}

a.text-action {
    color: #9e9e9e
}

a.text-action,
a.text-action:focus,
a.text-action:hover {
    text-decoration: none
}

a.text-action:focus,
a.text-action:hover {
    color: #bdbdbd
}

a.text-like {
    color: #9e9e9e!important
}

a.text-like,
a.text-like:focus,
a.text-like:hover {
    text-decoration: none
}

a.text-like.active,
a.text-like:focus,
a.text-like:hover {
    color: #e53935!important
}

.img-bordered {
    border: 1px solid #e0e0e0
}

.img-bordered-primary {
    border-color: #2196f3!important
}

.img-bordered-purple {
    border-color: #8e24aa!important
}

.img-bordered-red {
    border-color: #e53935!important
}

.img-bordered-green {
    border-color: #66bb6a!important
}

.img-bordered-orange {
    border-color: #fb8c00!important
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
    text-shadow: rgba(0, 0, 0, .15) 0 0 1px
}

.mark,
mark {
    color: #fff
}

.drop-cap {
    color: #212121
}

.drop-cap-reversed {
    color: #fff;
    background-color: #212121
}

.text-primary {
    color: #2196f3
}

a.text-primary:focus,
a.text-primary:hover {
    color: #0c7cd5
}

.text-success {
    color: #4caf50
}

a.text-success:focus,
a.text-success:hover {
    color: #3d8b40
}

.text-info {
    color: #00bcd4
}

a.text-info:focus,
a.text-info:hover {
    color: #008fa1
}

.text-warning {
    color: #ff9800
}

a.text-warning:focus,
a.text-warning:hover {
    color: #cc7a00
}

.text-danger {
    color: #f44336
}

a.text-danger:focus,
a.text-danger:hover {
    color: #ea1c0d
}

blockquote {
    color: #616161;
    border-left-width: 2px
}

.blockquote-reverse {
    border-right-width: 2px
}

.blockquote {
    border-left-width: 4px
}

.blockquote.blockquote-reverse {
    border-right-width: 4px
}

.blockquote-success {
    background-color: rgba(76, 175, 80, .1);
    border-color: #4caf50
}

.blockquote-info {
    background-color: rgba(0, 188, 212, .1);
    border-color: #00bcd4
}

.blockquote-warning {
    background-color: rgba(255, 152, 0, .1);
    border-color: #ff9800
}

.blockquote-danger {
    background-color: rgba(244, 67, 54, .1);
    border-color: #f44336
}

code {
    border: 1px solid #bbdefb
}

.table {
    color: #757575
}

.table>tfoot>tr>th,
.table>thead>tr>th {
    color: #616161
}

.table>thead>tr>th {
    border-bottom: 1px solid #e0e0e0
}

.table>tbody+tbody {
    border-top: 1px solid #e0e0e0
}

.table .danger,
.table .info,
.table .success,
.table .warning {
    color: #fff
}

.table .danger a,
.table .info a,
.table .success a,
.table .warning a {
    color: #fff
}

.table-danger thead tr,
.table-dark thead tr,
.table-info thead tr,
.table-primary thead tr,
.table-success thead tr,
.table-warning thead tr {
    color: #fff
}

.table-default thead tr {
    background: #eee
}

.table-primary thead tr {
    background: #2196f3
}

.table-success thead tr {
    background: #4caf50
}

.table-info thead tr {
    background: #00bcd4
}

.table-warning thead tr {
    background: #ff9800
}

.table-danger thead tr {
    background: #f44336
}

.table-dark thead tr {
    background: #616161
}

.table-gray thead tr {
    color: #616161;
    background: #bdbdbd
}

.table-bordered>thead>tr>td,
.table-bordered>thead>tr>th {
    border-bottom-width: 1px
}

.table-bordered>thead:first-child>tr:first-child>th {
    border: 1px solid #e0e0e0
}

.table-section.active tr {
    background-color: #eee
}

.form-control {
    -webkit-box-shadow: none;
    box-shadow: none
}

.form-control:not(select) {
    -webkit-appearance: none
}

.has-success .checkbox,
.has-success .checkbox-inline,
.has-success .control-label,
.has-success .help-block,
.has-success .radio,
.has-success .radio-inline,
.has-success.checkbox label,
.has-success.checkbox-inline label,
.has-success.radio label,
.has-success.radio-inline label {
    color: #4caf50
}

.has-success .form-control {
    border-color: #4caf50;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075)
}

.has-success .form-control:focus {
    border-color: #3d8b40;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 6px #92cf94;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 6px #92cf94
}

.has-success .input-group-addon {
    color: #4caf50;
    background-color: #fff;
    border-color: #4caf50
}

.has-success .form-control-feedback {
    color: #4caf50
}

.has-success .form-control {
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .05);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .05)
}

.has-success .form-control:focus {
    border-color: #4caf50;
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(76, 175, 80, .6);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(76, 175, 80, .6)
}

.has-success .form-control.focus,
.has-success .form-control:focus {
    border-color: #4caf50;
    -webkit-box-shadow: none;
    box-shadow: none
}

.has-warning .checkbox,
.has-warning .checkbox-inline,
.has-warning .control-label,
.has-warning .help-block,
.has-warning .radio,
.has-warning .radio-inline,
.has-warning.checkbox label,
.has-warning.checkbox-inline label,
.has-warning.radio label,
.has-warning.radio-inline label {
    color: #ff9800
}

.has-warning .form-control {
    border-color: #ff9800;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075)
}

.has-warning .form-control:focus {
    border-color: #cc7a00;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 6px #ffc166;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 6px #ffc166
}

.has-warning .input-group-addon {
    color: #ff9800;
    background-color: #fff;
    border-color: #ff9800
}

.has-warning .form-control-feedback {
    color: #ff9800
}

.has-warning .form-control {
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .05);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .05)
}

.has-warning .form-control:focus {
    border-color: #ff9800;
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(255, 152, 0, .6);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(255, 152, 0, .6)
}

.has-warning .form-control.focus,
.has-warning .form-control:focus {
    border-color: #ff9800;
    -webkit-box-shadow: none;
    box-shadow: none
}

.has-error .checkbox,
.has-error .checkbox-inline,
.has-error .control-label,
.has-error .help-block,
.has-error .radio,
.has-error .radio-inline,
.has-error.checkbox label,
.has-error.checkbox-inline label,
.has-error.radio label,
.has-error.radio-inline label {
    color: #f44336
}

.has-error .form-control {
    border-color: #f44336;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075)
}

.has-error .form-control:focus {
    border-color: #ea1c0d;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 6px #f99d97;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 6px #f99d97
}

.has-error .input-group-addon {
    color: #f44336;
    background-color: #fff;
    border-color: #f44336
}

.has-error .form-control-feedback {
    color: #f44336
}

.has-error .form-control {
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .05);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .05)
}

.has-error .form-control:focus {
    border-color: #f44336;
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(244, 67, 54, .6);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(244, 67, 54, .6)
}

.has-error .form-control.focus,
.has-error .form-control:focus {
    border-color: #f44336;
    -webkit-box-shadow: none;
    box-shadow: none
}

.input-group-file input[type=text] {
    background-color: #fff
}

.input-search-close {
    color: #000;
    text-shadow: none
}

.input-search-close:focus,
.input-search-close:hover {
    color: #000;
    text-decoration: none
}

button.input-search-close {
    -webkit-appearance: none;
    background: 0 0;
    border: 0
}

.input-search .input-search-icon {
    color: #9e9e9e
}

.input-search-btn {
    background: 0 0;
    border: none
}

.input-search-dark .input-search-icon {
    color: #757575
}

.input-search-dark .form-control {
    background: #eee;
    -webkit-box-shadow: none;
    box-shadow: none
}

.input-search-dark .form-control:focus {
    background-color: transparent
}

.btn.active:focus,
.btn:active:focus,
.btn:focus {
    outline: 0
}

.btn.active,
.btn:active {
    -webkit-box-shadow: none;
    box-shadow: none
}

.btn-default.focus,
.btn-default:focus,
.btn-default:hover {
    background-color: #f5f5f5;
    border-color: #f5f5f5
}

.btn-default.active,
.btn-default:active,
.open>.dropdown-toggle.btn-default {
    background-color: #e0e0e0;
    border-color: #e0e0e0
}

.btn-default.active.focus,
.btn-default.active:focus,
.btn-default.active:hover,
.btn-default:active.focus,
.btn-default:active:focus,
.btn-default:active:hover,
.open>.dropdown-toggle.btn-default.focus,
.open>.dropdown-toggle.btn-default:focus,
.open>.dropdown-toggle.btn-default:hover {
    background-color: #e0e0e0;
    border-color: #e0e0e0
}

.btn-default.disabled,
.btn-default.disabled.active,
.btn-default.disabled.focus,
.btn-default.disabled:active,
.btn-default.disabled:focus,
.btn-default.disabled:hover,
.btn-default[disabled],
.btn-default[disabled].active,
.btn-default[disabled].focus,
.btn-default[disabled]:active,
.btn-default[disabled]:focus,
.btn-default[disabled]:hover,
fieldset[disabled] .btn-default,
fieldset[disabled] .btn-default.active,
fieldset[disabled] .btn-default.focus,
fieldset[disabled] .btn-default:active,
fieldset[disabled] .btn-default:focus,
fieldset[disabled] .btn-default:hover {
    color: #757575;
    background-color: #eee;
    border-color: #eee
}

.btn-default.btn-up:before {
    border-bottom-color: #eee
}

.btn-default.btn-up:focus:before,
.btn-default.btn-up:hover:before {
    border-bottom-color: #f5f5f5
}

.btn-default.btn-up.active:before,
.btn-default.btn-up:active:before,
.open>.dropdown-toggle.btn-default.btn-up:before {
    border-bottom-color: #e0e0e0
}

.btn-default.btn-right:before {
    border-left-color: #eee
}

.btn-default.btn-right:focus:before,
.btn-default.btn-right:hover:before {
    border-left-color: #f5f5f5
}

.btn-default.btn-right.active:before,
.btn-default.btn-right:active:before,
.open>.dropdown-toggle.btn-default.btn-right:before {
    border-left-color: #e0e0e0
}

.btn-default.btn-bottom:before {
    border-top-color: #eee
}

.btn-default.btn-bottom:focus:before,
.btn-default.btn-bottom:hover:before {
    border-top-color: #f5f5f5
}

.btn-default.btn-bottom.active:before,
.btn-default.btn-bottom:active:before,
.open>.dropdown-toggle.btn-default.btn-bottom:before {
    border-top-color: #e0e0e0
}

.btn-default.btn-left:before {
    border-right-color: #eee
}

.btn-default.btn-left:focus:before,
.btn-default.btn-left:hover:before {
    border-right-color: #f5f5f5
}

.btn-default.btn-left.active:before,
.btn-default.btn-left:active:before,
.open>.dropdown-toggle.btn-default.btn-left:before {
    border-right-color: #e0e0e0
}

.btn-primary.focus,
.btn-primary:focus,
.btn-primary:hover {
    background-color: #42a5f5;
    border-color: #42a5f5
}

.btn-primary.active,
.btn-primary:active,
.open>.dropdown-toggle.btn-primary {
    background-color: #1e88e5;
    border-color: #1e88e5
}

.btn-primary.active.focus,
.btn-primary.active:focus,
.btn-primary.active:hover,
.btn-primary:active.focus,
.btn-primary:active:focus,
.btn-primary:active:hover,
.open>.dropdown-toggle.btn-primary.focus,
.open>.dropdown-toggle.btn-primary:focus,
.open>.dropdown-toggle.btn-primary:hover {
    background-color: #1e88e5;
    border-color: #1e88e5
}

.btn-primary.disabled,
.btn-primary.disabled.active,
.btn-primary.disabled.focus,
.btn-primary.disabled:active,
.btn-primary.disabled:focus,
.btn-primary.disabled:hover,
.btn-primary[disabled],
.btn-primary[disabled].active,
.btn-primary[disabled].focus,
.btn-primary[disabled]:active,
.btn-primary[disabled]:focus,
.btn-primary[disabled]:hover,
fieldset[disabled] .btn-primary,
fieldset[disabled] .btn-primary.active,
fieldset[disabled] .btn-primary.focus,
fieldset[disabled] .btn-primary:active,
fieldset[disabled] .btn-primary:focus,
fieldset[disabled] .btn-primary:hover {
    color: #fff;
    background-color: #42a5f5;
    border-color: #42a5f5
}

.btn-primary.btn-up:before {
    border-bottom-color: #2196f3
}

.btn-primary.btn-up:focus:before,
.btn-primary.btn-up:hover:before {
    border-bottom-color: #42a5f5
}

.btn-primary.btn-up.active:before,
.btn-primary.btn-up:active:before,
.open>.dropdown-toggle.btn-primary.btn-up:before {
    border-bottom-color: #1e88e5
}

.btn-primary.btn-right:before {
    border-left-color: #2196f3
}

.btn-primary.btn-right:focus:before,
.btn-primary.btn-right:hover:before {
    border-left-color: #42a5f5
}

.btn-primary.btn-right.active:before,
.btn-primary.btn-right:active:before,
.open>.dropdown-toggle.btn-primary.btn-right:before {
    border-left-color: #1e88e5
}

.btn-primary.btn-bottom:before {
    border-top-color: #2196f3
}

.btn-primary.btn-bottom:focus:before,
.btn-primary.btn-bottom:hover:before {
    border-top-color: #42a5f5
}

.btn-primary.btn-bottom.active:before,
.btn-primary.btn-bottom:active:before,
.open>.dropdown-toggle.btn-primary.btn-bottom:before {
    border-top-color: #1e88e5
}

.btn-primary.btn-left:before {
    border-right-color: #2196f3
}

.btn-primary.btn-left:focus:before,
.btn-primary.btn-left:hover:before {
    border-right-color: #42a5f5
}

.btn-primary.btn-left.active:before,
.btn-primary.btn-left:active:before,
.open>.dropdown-toggle.btn-primary.btn-left:before {
    border-right-color: #1e88e5
}

.btn-success.focus,
.btn-success:focus,
.btn-success:hover {
    background-color: #66bb6a;
    border-color: #66bb6a
}

.btn-success.active,
.btn-success:active,
.open>.dropdown-toggle.btn-success {
    background-color: #43a047;
    border-color: #43a047
}

.btn-success.active.focus,
.btn-success.active:focus,
.btn-success.active:hover,
.btn-success:active.focus,
.btn-success:active:focus,
.btn-success:active:hover,
.open>.dropdown-toggle.btn-success.focus,
.open>.dropdown-toggle.btn-success:focus,
.open>.dropdown-toggle.btn-success:hover {
    background-color: #43a047;
    border-color: #43a047
}

.btn-success.disabled,
.btn-success.disabled.active,
.btn-success.disabled.focus,
.btn-success.disabled:active,
.btn-success.disabled:focus,
.btn-success.disabled:hover,
.btn-success[disabled],
.btn-success[disabled].active,
.btn-success[disabled].focus,
.btn-success[disabled]:active,
.btn-success[disabled]:focus,
.btn-success[disabled]:hover,
fieldset[disabled] .btn-success,
fieldset[disabled] .btn-success.active,
fieldset[disabled] .btn-success.focus,
fieldset[disabled] .btn-success:active,
fieldset[disabled] .btn-success:focus,
fieldset[disabled] .btn-success:hover {
    color: #fff;
    background-color: #66bb6a;
    border-color: #66bb6a
}

.btn-success.btn-up:before {
    border-bottom-color: #4caf50
}

.btn-success.btn-up:focus:before,
.btn-success.btn-up:hover:before {
    border-bottom-color: #66bb6a
}

.btn-success.btn-up.active:before,
.btn-success.btn-up:active:before,
.open>.dropdown-toggle.btn-success.btn-up:before {
    border-bottom-color: #43a047
}

.btn-success.btn-right:before {
    border-left-color: #4caf50
}

.btn-success.btn-right:focus:before,
.btn-success.btn-right:hover:before {
    border-left-color: #66bb6a
}

.btn-success.btn-right.active:before,
.btn-success.btn-right:active:before,
.open>.dropdown-toggle.btn-success.btn-right:before {
    border-left-color: #43a047
}

.btn-success.btn-bottom:before {
    border-top-color: #4caf50
}

.btn-success.btn-bottom:focus:before,
.btn-success.btn-bottom:hover:before {
    border-top-color: #66bb6a
}

.btn-success.btn-bottom.active:before,
.btn-success.btn-bottom:active:before,
.open>.dropdown-toggle.btn-success.btn-bottom:before {
    border-top-color: #43a047
}

.btn-success.btn-left:before {
    border-right-color: #4caf50
}

.btn-success.btn-left:focus:before,
.btn-success.btn-left:hover:before {
    border-right-color: #66bb6a
}

.btn-success.btn-left.active:before,
.btn-success.btn-left:active:before,
.open>.dropdown-toggle.btn-success.btn-left:before {
    border-right-color: #43a047
}

.btn-info.focus,
.btn-info:focus,
.btn-info:hover {
    background-color: #26c6da;
    border-color: #26c6da
}

.btn-info.active,
.btn-info:active,
.open>.dropdown-toggle.btn-info {
    background-color: #00acc1;
    border-color: #00acc1
}

.btn-info.active.focus,
.btn-info.active:focus,
.btn-info.active:hover,
.btn-info:active.focus,
.btn-info:active:focus,
.btn-info:active:hover,
.open>.dropdown-toggle.btn-info.focus,
.open>.dropdown-toggle.btn-info:focus,
.open>.dropdown-toggle.btn-info:hover {
    background-color: #00acc1;
    border-color: #00acc1
}

.btn-info.disabled,
.btn-info.disabled.active,
.btn-info.disabled.focus,
.btn-info.disabled:active,
.btn-info.disabled:focus,
.btn-info.disabled:hover,
.btn-info[disabled],
.btn-info[disabled].active,
.btn-info[disabled].focus,
.btn-info[disabled]:active,
.btn-info[disabled]:focus,
.btn-info[disabled]:hover,
fieldset[disabled] .btn-info,
fieldset[disabled] .btn-info.active,
fieldset[disabled] .btn-info.focus,
fieldset[disabled] .btn-info:active,
fieldset[disabled] .btn-info:focus,
fieldset[disabled] .btn-info:hover {
    color: #fff;
    background-color: #26c6da;
    border-color: #26c6da
}

.btn-info.btn-up:before {
    border-bottom-color: #00bcd4
}

.btn-info.btn-up:focus:before,
.btn-info.btn-up:hover:before {
    border-bottom-color: #26c6da
}

.btn-info.btn-up.active:before,
.btn-info.btn-up:active:before,
.open>.dropdown-toggle.btn-info.btn-up:before {
    border-bottom-color: #00acc1
}

.btn-info.btn-right:before {
    border-left-color: #00bcd4
}

.btn-info.btn-right:focus:before,
.btn-info.btn-right:hover:before {
    border-left-color: #26c6da
}

.btn-info.btn-right.active:before,
.btn-info.btn-right:active:before,
.open>.dropdown-toggle.btn-info.btn-right:before {
    border-left-color: #00acc1
}

.btn-info.btn-bottom:before {
    border-top-color: #00bcd4
}

.btn-info.btn-bottom:focus:before,
.btn-info.btn-bottom:hover:before {
    border-top-color: #26c6da
}

.btn-info.btn-bottom.active:before,
.btn-info.btn-bottom:active:before,
.open>.dropdown-toggle.btn-info.btn-bottom:before {
    border-top-color: #00acc1
}

.btn-info.btn-left:before {
    border-right-color: #00bcd4
}

.btn-info.btn-left:focus:before,
.btn-info.btn-left:hover:before {
    border-right-color: #26c6da
}

.btn-info.btn-left.active:before,
.btn-info.btn-left:active:before,
.open>.dropdown-toggle.btn-info.btn-left:before {
    border-right-color: #00acc1
}

.btn-warning.focus,
.btn-warning:focus,
.btn-warning:hover {
    background-color: #ffa726;
    border-color: #ffa726
}

.btn-warning.active,
.btn-warning:active,
.open>.dropdown-toggle.btn-warning {
    background-color: #fb8c00;
    border-color: #fb8c00
}

.btn-warning.active.focus,
.btn-warning.active:focus,
.btn-warning.active:hover,
.btn-warning:active.focus,
.btn-warning:active:focus,
.btn-warning:active:hover,
.open>.dropdown-toggle.btn-warning.focus,
.open>.dropdown-toggle.btn-warning:focus,
.open>.dropdown-toggle.btn-warning:hover {
    background-color: #fb8c00;
    border-color: #fb8c00
}

.btn-warning.disabled,
.btn-warning.disabled.active,
.btn-warning.disabled.focus,
.btn-warning.disabled:active,
.btn-warning.disabled:focus,
.btn-warning.disabled:hover,
.btn-warning[disabled],
.btn-warning[disabled].active,
.btn-warning[disabled].focus,
.btn-warning[disabled]:active,
.btn-warning[disabled]:focus,
.btn-warning[disabled]:hover,
fieldset[disabled] .btn-warning,
fieldset[disabled] .btn-warning.active,
fieldset[disabled] .btn-warning.focus,
fieldset[disabled] .btn-warning:active,
fieldset[disabled] .btn-warning:focus,
fieldset[disabled] .btn-warning:hover {
    color: #fff;
    background-color: #ffa726;
    border-color: #ffa726
}

.btn-warning.btn-up:before {
    border-bottom-color: #ff9800
}

.btn-warning.btn-up:focus:before,
.btn-warning.btn-up:hover:before {
    border-bottom-color: #ffa726
}

.btn-warning.btn-up.active:before,
.btn-warning.btn-up:active:before,
.open>.dropdown-toggle.btn-warning.btn-up:before {
    border-bottom-color: #fb8c00
}

.btn-warning.btn-right:before {
    border-left-color: #ff9800
}

.btn-warning.btn-right:focus:before,
.btn-warning.btn-right:hover:before {
    border-left-color: #ffa726
}

.btn-warning.btn-right.active:before,
.btn-warning.btn-right:active:before,
.open>.dropdown-toggle.btn-warning.btn-right:before {
    border-left-color: #fb8c00
}

.btn-warning.btn-bottom:before {
    border-top-color: #ff9800
}

.btn-warning.btn-bottom:focus:before,
.btn-warning.btn-bottom:hover:before {
    border-top-color: #ffa726
}

.btn-warning.btn-bottom.active:before,
.btn-warning.btn-bottom:active:before,
.open>.dropdown-toggle.btn-warning.btn-bottom:before {
    border-top-color: #fb8c00
}

.btn-warning.btn-left:before {
    border-right-color: #ff9800
}

.btn-warning.btn-left:focus:before,
.btn-warning.btn-left:hover:before {
    border-right-color: #ffa726
}

.btn-warning.btn-left.active:before,
.btn-warning.btn-left:active:before,
.open>.dropdown-toggle.btn-warning.btn-left:before {
    border-right-color: #fb8c00
}

.btn-danger.focus,
.btn-danger:focus,
.btn-danger:hover {
    background-color: #ef5350;
    border-color: #ef5350
}

.btn-danger.active,
.btn-danger:active,
.open>.dropdown-toggle.btn-danger {
    background-color: #e53935;
    border-color: #e53935
}

.btn-danger.active.focus,
.btn-danger.active:focus,
.btn-danger.active:hover,
.btn-danger:active.focus,
.btn-danger:active:focus,
.btn-danger:active:hover,
.open>.dropdown-toggle.btn-danger.focus,
.open>.dropdown-toggle.btn-danger:focus,
.open>.dropdown-toggle.btn-danger:hover {
    background-color: #e53935;
    border-color: #e53935
}

.btn-danger.disabled,
.btn-danger.disabled.active,
.btn-danger.disabled.focus,
.btn-danger.disabled:active,
.btn-danger.disabled:focus,
.btn-danger.disabled:hover,
.btn-danger[disabled],
.btn-danger[disabled].active,
.btn-danger[disabled].focus,
.btn-danger[disabled]:active,
.btn-danger[disabled]:focus,
.btn-danger[disabled]:hover,
fieldset[disabled] .btn-danger,
fieldset[disabled] .btn-danger.active,
fieldset[disabled] .btn-danger.focus,
fieldset[disabled] .btn-danger:active,
fieldset[disabled] .btn-danger:focus,
fieldset[disabled] .btn-danger:hover {
    color: #fff;
    background-color: #ef5350;
    border-color: #ef5350
}

.btn-danger.btn-up:before {
    border-bottom-color: #f44336
}

.btn-danger.btn-up:focus:before,
.btn-danger.btn-up:hover:before {
    border-bottom-color: #ef5350
}

.btn-danger.btn-up.active:before,
.btn-danger.btn-up:active:before,
.open>.dropdown-toggle.btn-danger.btn-up:before {
    border-bottom-color: #e53935
}

.btn-danger.btn-right:before {
    border-left-color: #f44336
}

.btn-danger.btn-right:focus:before,
.btn-danger.btn-right:hover:before {
    border-left-color: #ef5350
}

.btn-danger.btn-right.active:before,
.btn-danger.btn-right:active:before,
.open>.dropdown-toggle.btn-danger.btn-right:before {
    border-left-color: #e53935
}

.btn-danger.btn-bottom:before {
    border-top-color: #f44336
}

.btn-danger.btn-bottom:focus:before,
.btn-danger.btn-bottom:hover:before {
    border-top-color: #ef5350
}

.btn-danger.btn-bottom.active:before,
.btn-danger.btn-bottom:active:before,
.open>.dropdown-toggle.btn-danger.btn-bottom:before {
    border-top-color: #e53935
}

.btn-danger.btn-left:before {
    border-right-color: #f44336
}

.btn-danger.btn-left:focus:before,
.btn-danger.btn-left:hover:before {
    border-right-color: #ef5350
}

.btn-danger.btn-left.active:before,
.btn-danger.btn-left:active:before,
.open>.dropdown-toggle.btn-danger.btn-left:before {
    border-right-color: #e53935
}

.btn-inverse {
    color: #757575;
    background-color: #fff;
    border-color: #eee
}

.btn-inverse.focus,
.btn-inverse:focus {
    color: #757575;
    background-color: #e6e6e6;
    border-color: #aeaeae
}

.btn-inverse:hover {
    color: #757575;
    background-color: #e6e6e6;
    border-color: #cfcfcf
}

.btn-inverse.active,
.btn-inverse:active,
.open>.dropdown-toggle.btn-inverse {
    color: #757575;
    background-color: #e6e6e6;
    border-color: #cfcfcf
}

.btn-inverse.active.focus,
.btn-inverse.active:focus,
.btn-inverse.active:hover,
.btn-inverse:active.focus,
.btn-inverse:active:focus,
.btn-inverse:active:hover,
.open>.dropdown-toggle.btn-inverse.focus,
.open>.dropdown-toggle.btn-inverse:focus,
.open>.dropdown-toggle.btn-inverse:hover {
    color: #757575;
    background-color: #d4d4d4;
    border-color: #aeaeae
}

.btn-inverse.active,
.btn-inverse:active,
.open>.dropdown-toggle.btn-inverse {
    background-image: none
}

.btn-inverse.disabled.focus,
.btn-inverse.disabled:focus,
.btn-inverse.disabled:hover,
.btn-inverse[disabled].focus,
.btn-inverse[disabled]:focus,
.btn-inverse[disabled]:hover,
fieldset[disabled] .btn-inverse.focus,
fieldset[disabled] .btn-inverse:focus,
fieldset[disabled] .btn-inverse:hover {
    background-color: #fff;
    border-color: #eee
}

.btn-inverse .badge {
    color: #fff;
    background-color: #757575
}

.btn-inverse.focus,
.btn-inverse:focus,
.btn-inverse:hover {
    background-color: #fff;
    border-color: #f5f5f5
}

.btn-inverse.active,
.btn-inverse:active,
.open>.dropdown-toggle.btn-inverse {
    background-color: #fff;
    border-color: #e0e0e0
}

.btn-inverse.active.focus,
.btn-inverse.active:focus,
.btn-inverse.active:hover,
.btn-inverse:active.focus,
.btn-inverse:active:focus,
.btn-inverse:active:hover,
.open>.dropdown-toggle.btn-inverse.focus,
.open>.dropdown-toggle.btn-inverse:focus,
.open>.dropdown-toggle.btn-inverse:hover {
    background-color: #fff;
    border-color: #e0e0e0
}

.btn-inverse.disabled,
.btn-inverse.disabled.active,
.btn-inverse.disabled.focus,
.btn-inverse.disabled:active,
.btn-inverse.disabled:focus,
.btn-inverse.disabled:hover,
.btn-inverse[disabled],
.btn-inverse[disabled].active,
.btn-inverse[disabled].focus,
.btn-inverse[disabled]:active,
.btn-inverse[disabled]:focus,
.btn-inverse[disabled]:hover,
fieldset[disabled] .btn-inverse,
fieldset[disabled] .btn-inverse.active,
fieldset[disabled] .btn-inverse.focus,
fieldset[disabled] .btn-inverse:active,
fieldset[disabled] .btn-inverse:focus,
fieldset[disabled] .btn-inverse:hover {
    color: #bdbdbd;
    background-color: #fff;
    border-color: #9e9e9e
}

.btn-inverse.btn-up:before {
    border-bottom-color: #fff
}

.btn-inverse.btn-up:focus:before,
.btn-inverse.btn-up:hover:before {
    border-bottom-color: #fff
}

.btn-inverse.btn-up.active:before,
.btn-inverse.btn-up:active:before,
.open>.dropdown-toggle.btn-inverse.btn-up:before {
    border-bottom-color: #fff
}

.btn-inverse.btn-right:before {
    border-left-color: #fff
}

.btn-inverse.btn-right:focus:before,
.btn-inverse.btn-right:hover:before {
    border-left-color: #fff
}

.btn-inverse.btn-right.active:before,
.btn-inverse.btn-right:active:before,
.open>.dropdown-toggle.btn-inverse.btn-right:before {
    border-left-color: #fff
}

.btn-inverse.btn-bottom:before {
    border-top-color: #fff
}

.btn-inverse.btn-bottom:focus:before,
.btn-inverse.btn-bottom:hover:before {
    border-top-color: #fff
}

.btn-inverse.btn-bottom.active:before,
.btn-inverse.btn-bottom:active:before,
.open>.dropdown-toggle.btn-inverse.btn-bottom:before {
    border-top-color: #fff
}

.btn-inverse.btn-left:before {
    border-right-color: #fff
}

.btn-inverse.btn-left:focus:before,
.btn-inverse.btn-left:hover:before {
    border-right-color: #fff
}

.btn-inverse.btn-left.active:before,
.btn-inverse.btn-left:active:before,
.open>.dropdown-toggle.btn-inverse.btn-left:before {
    border-right-color: #fff
}

.btn-dark {
    color: #fff;
    background-color: #616161;
    border-color: #616161
}

.btn-dark.focus,
.btn-dark:focus {
    color: #fff;
    background-color: #484848;
    border-color: #212121
}

.btn-dark:hover {
    color: #fff;
    background-color: #484848;
    border-color: #424242
}

.btn-dark.active,
.btn-dark:active,
.open>.dropdown-toggle.btn-dark {
    color: #fff;
    background-color: #484848;
    border-color: #424242
}

.btn-dark.active.focus,
.btn-dark.active:focus,
.btn-dark.active:hover,
.btn-dark:active.focus,
.btn-dark:active:focus,
.btn-dark:active:hover,
.open>.dropdown-toggle.btn-dark.focus,
.open>.dropdown-toggle.btn-dark:focus,
.open>.dropdown-toggle.btn-dark:hover {
    color: #fff;
    background-color: #363636;
    border-color: #212121
}

.btn-dark.active,
.btn-dark:active,
.open>.dropdown-toggle.btn-dark {
    background-image: none
}

.btn-dark.disabled.focus,
.btn-dark.disabled:focus,
.btn-dark.disabled:hover,
.btn-dark[disabled].focus,
.btn-dark[disabled]:focus,
.btn-dark[disabled]:hover,
fieldset[disabled] .btn-dark.focus,
fieldset[disabled] .btn-dark:focus,
fieldset[disabled] .btn-dark:hover {
    background-color: #616161;
    border-color: #616161
}

.btn-dark .badge {
    color: #616161;
    background-color: #fff
}

.btn-dark.focus,
.btn-dark:focus,
.btn-dark:hover {
    background-color: #757575;
    border-color: #757575
}

.btn-dark.active,
.btn-dark:active,
.open>.dropdown-toggle.btn-dark {
    background-color: #424242;
    border-color: #424242
}

.btn-dark.active.focus,
.btn-dark.active:focus,
.btn-dark.active:hover,
.btn-dark:active.focus,
.btn-dark:active:focus,
.btn-dark:active:hover,
.open>.dropdown-toggle.btn-dark.focus,
.open>.dropdown-toggle.btn-dark:focus,
.open>.dropdown-toggle.btn-dark:hover {
    background-color: #424242;
    border-color: #424242
}

.btn-dark.disabled,
.btn-dark.disabled.active,
.btn-dark.disabled.focus,
.btn-dark.disabled:active,
.btn-dark.disabled:focus,
.btn-dark.disabled:hover,
.btn-dark[disabled],
.btn-dark[disabled].active,
.btn-dark[disabled].focus,
.btn-dark[disabled]:active,
.btn-dark[disabled]:focus,
.btn-dark[disabled]:hover,
fieldset[disabled] .btn-dark,
fieldset[disabled] .btn-dark.active,
fieldset[disabled] .btn-dark.focus,
fieldset[disabled] .btn-dark:active,
fieldset[disabled] .btn-dark:focus,
fieldset[disabled] .btn-dark:hover {
    color: #fff;
    background-color: #9e9e9e;
    border-color: #9e9e9e
}

.btn-dark.btn-up:before {
    border-bottom-color: #616161
}

.btn-dark.btn-up:focus:before,
.btn-dark.btn-up:hover:before {
    border-bottom-color: #757575
}

.btn-dark.btn-up.active:before,
.btn-dark.btn-up:active:before,
.open>.dropdown-toggle.btn-dark.btn-up:before {
    border-bottom-color: #424242
}

.btn-dark.btn-right:before {
    border-left-color: #616161
}

.btn-dark.btn-right:focus:before,
.btn-dark.btn-right:hover:before {
    border-left-color: #757575
}

.btn-dark.btn-right.active:before,
.btn-dark.btn-right:active:before,
.open>.dropdown-toggle.btn-dark.btn-right:before {
    border-left-color: #424242
}

.btn-dark.btn-bottom:before {
    border-top-color: #616161
}

.btn-dark.btn-bottom:focus:before,
.btn-dark.btn-bottom:hover:before {
    border-top-color: #757575
}

.btn-dark.btn-bottom.active:before,
.btn-dark.btn-bottom:active:before,
.open>.dropdown-toggle.btn-dark.btn-bottom:before {
    border-top-color: #424242
}

.btn-dark.btn-left:before {
    border-right-color: #616161
}

.btn-dark.btn-left:focus:before,
.btn-dark.btn-left:hover:before {
    border-right-color: #757575
}

.btn-dark.btn-left.active:before,
.btn-dark.btn-left:active:before,
.open>.dropdown-toggle.btn-dark.btn-left:before {
    border-right-color: #424242
}

.btn-dark:focus,
.btn-dark:hover {
    color: #fff
}

.btn-dark.active,
.btn-dark:active,
.open>.dropdown-toggle.btn-dark {
    color: #fff
}

.btn-dark.btn-flat {
    color: #616161
}

.btn-flat {
    background: 0 0;
    border: none;
    -webkit-box-shadow: none;
    box-shadow: none
}

.btn-flat.disabled {
    color: #9e9e9e
}

.btn-raised {
    -webkit-box-shadow: 0 0 2px rgba(0, 0, 0, .18), 0 2px 4px rgba(0, 0, 0, .21);
    box-shadow: 0 0 2px rgba(0, 0, 0, .18), 0 2px 4px rgba(0, 0, 0, .21);
    -webkit-transition: -webkit-box-shadow .25s cubic-bezier(.4, 0, .2, 1);
    -o-transition: box-shadow .25s cubic-bezier(.4, 0, .2, 1);
    transition: box-shadow .25s cubic-bezier(.4, 0, .2, 1)
}

.btn-raised.active,
.btn-raised:active,
.btn-raised:hover,
.open>.dropdown-toggle.btn-raised {
    -webkit-box-shadow: 0 0 3px rgba(0, 0, 0, .15), 0 3px 6px rgba(0, 0, 0, .2);
    box-shadow: 0 0 3px rgba(0, 0, 0, .15), 0 3px 6px rgba(0, 0, 0, .2)
}

.btn-raised.disabled,
.btn-raised[disabled],
fieldset[disabled] .btn-raised {
    -webkit-box-shadow: none;
    box-shadow: none
}

.btn-label {
    background-color: rgba(0, 0, 0, .15)
}

.btn-direction:before {
    border: 8px solid transparent
}

.btn-up:before {
    border-bottom-color: #e0e0e0
}

.btn-right:before {
    border-left-color: #e0e0e0
}

.btn-bottom:before {
    border-top-color: #e0e0e0
}

.btn-left:before {
    border-right-color: #e0e0e0
}

.btn-pure,
.btn-pure.active,
.btn-pure:active,
.btn-pure:focus,
.btn-pure:hover,
.btn-pure[disabled],
.open>.dropdown-toggle.btn-pure,
fieldset[disabled] .btn-pure {
    background-color: transparent;
    border-color: transparent;
    -webkit-box-shadow: none;
    box-shadow: none
}

.btn-pure.active.focus,
.btn-pure.active:focus,
.btn-pure.active:hover,
.btn-pure.focus,
.btn-pure:active.focus,
.btn-pure:active:focus,
.btn-pure:active:hover,
.btn-pure:focus,
.btn-pure:focus.focus,
.btn-pure:focus:focus,
.btn-pure:focus:hover,
.btn-pure:hover,
.btn-pure:hover.focus,
.btn-pure:hover:focus,
.btn-pure:hover:hover,
.btn-pure[disabled].focus,
.btn-pure[disabled]:focus,
.btn-pure[disabled]:hover,
.open>.dropdown-toggle.btn-pure.focus,
.open>.dropdown-toggle.btn-pure:focus,
.open>.dropdown-toggle.btn-pure:hover,
fieldset[disabled] .btn-pure.focus,
fieldset[disabled] .btn-pure:focus,
fieldset[disabled] .btn-pure:hover {
    background-color: transparent;
    border-color: transparent;
    -webkit-box-shadow: none;
    box-shadow: none
}

.btn-pure.btn-default {
    color: #9e9e9e
}

.btn-pure.btn-default.active,
.btn-pure.btn-default:active,
.btn-pure.btn-default:focus,
.btn-pure.btn-default:hover,
.open>.dropdown-toggle.btn-pure.btn-default {
    color: #bdbdbd
}

.btn-pure.btn-default.active.focus,
.btn-pure.btn-default.active:focus,
.btn-pure.btn-default.active:hover,
.btn-pure.btn-default:active.focus,
.btn-pure.btn-default:active:focus,
.btn-pure.btn-default:active:hover,
.btn-pure.btn-default:focus.focus,
.btn-pure.btn-default:focus:focus,
.btn-pure.btn-default:focus:hover,
.btn-pure.btn-default:hover.focus,
.btn-pure.btn-default:hover:focus,
.btn-pure.btn-default:hover:hover,
.open>.dropdown-toggle.btn-pure.btn-default.focus,
.open>.dropdown-toggle.btn-pure.btn-default:focus,
.open>.dropdown-toggle.btn-pure.btn-default:hover {
    color: #bdbdbd
}

.btn-pure.btn-default.active .badge,
.btn-pure.btn-default:active .badge,
.btn-pure.btn-default:focus .badge,
.btn-pure.btn-default:hover .badge,
.open>.dropdown-toggle.btn-pure.btn-default .badge {
    color: #bdbdbd
}

.btn-pure.btn-primary {
    color: #2196f3
}

.btn-pure.btn-primary.active,
.btn-pure.btn-primary:active,
.btn-pure.btn-primary:focus,
.btn-pure.btn-primary:hover,
.open>.dropdown-toggle.btn-pure.btn-primary {
    color: #42a5f5
}

.btn-pure.btn-primary.active.focus,
.btn-pure.btn-primary.active:focus,
.btn-pure.btn-primary.active:hover,
.btn-pure.btn-primary:active.focus,
.btn-pure.btn-primary:active:focus,
.btn-pure.btn-primary:active:hover,
.btn-pure.btn-primary:focus.focus,
.btn-pure.btn-primary:focus:focus,
.btn-pure.btn-primary:focus:hover,
.btn-pure.btn-primary:hover.focus,
.btn-pure.btn-primary:hover:focus,
.btn-pure.btn-primary:hover:hover,
.open>.dropdown-toggle.btn-pure.btn-primary.focus,
.open>.dropdown-toggle.btn-pure.btn-primary:focus,
.open>.dropdown-toggle.btn-pure.btn-primary:hover {
    color: #42a5f5
}

.btn-pure.btn-primary.active .badge,
.btn-pure.btn-primary:active .badge,
.btn-pure.btn-primary:focus .badge,
.btn-pure.btn-primary:hover .badge,
.open>.dropdown-toggle.btn-pure.btn-primary .badge {
    color: #42a5f5
}

.btn-pure.btn-success {
    color: #4caf50
}

.btn-pure.btn-success.active,
.btn-pure.btn-success:active,
.btn-pure.btn-success:focus,
.btn-pure.btn-success:hover,
.open>.dropdown-toggle.btn-pure.btn-success {
    color: #66bb6a
}

.btn-pure.btn-success.active.focus,
.btn-pure.btn-success.active:focus,
.btn-pure.btn-success.active:hover,
.btn-pure.btn-success:active.focus,
.btn-pure.btn-success:active:focus,
.btn-pure.btn-success:active:hover,
.btn-pure.btn-success:focus.focus,
.btn-pure.btn-success:focus:focus,
.btn-pure.btn-success:focus:hover,
.btn-pure.btn-success:hover.focus,
.btn-pure.btn-success:hover:focus,
.btn-pure.btn-success:hover:hover,
.open>.dropdown-toggle.btn-pure.btn-success.focus,
.open>.dropdown-toggle.btn-pure.btn-success:focus,
.open>.dropdown-toggle.btn-pure.btn-success:hover {
    color: #66bb6a
}

.btn-pure.btn-success.active .badge,
.btn-pure.btn-success:active .badge,
.btn-pure.btn-success:focus .badge,
.btn-pure.btn-success:hover .badge,
.open>.dropdown-toggle.btn-pure.btn-success .badge {
    color: #66bb6a
}

.btn-pure.btn-info {
    color: #00bcd4
}

.btn-pure.btn-info.active,
.btn-pure.btn-info:active,
.btn-pure.btn-info:focus,
.btn-pure.btn-info:hover,
.open>.dropdown-toggle.btn-pure.btn-info {
    color: #26c6da
}

.btn-pure.btn-info.active.focus,
.btn-pure.btn-info.active:focus,
.btn-pure.btn-info.active:hover,
.btn-pure.btn-info:active.focus,
.btn-pure.btn-info:active:focus,
.btn-pure.btn-info:active:hover,
.btn-pure.btn-info:focus.focus,
.btn-pure.btn-info:focus:focus,
.btn-pure.btn-info:focus:hover,
.btn-pure.btn-info:hover.focus,
.btn-pure.btn-info:hover:focus,
.btn-pure.btn-info:hover:hover,
.open>.dropdown-toggle.btn-pure.btn-info.focus,
.open>.dropdown-toggle.btn-pure.btn-info:focus,
.open>.dropdown-toggle.btn-pure.btn-info:hover {
    color: #26c6da
}

.btn-pure.btn-info.active .badge,
.btn-pure.btn-info:active .badge,
.btn-pure.btn-info:focus .badge,
.btn-pure.btn-info:hover .badge,
.open>.dropdown-toggle.btn-pure.btn-info .badge {
    color: #26c6da
}

.btn-pure.btn-warning {
    color: #ff9800
}

.btn-pure.btn-warning.active,
.btn-pure.btn-warning:active,
.btn-pure.btn-warning:focus,
.btn-pure.btn-warning:hover,
.open>.dropdown-toggle.btn-pure.btn-warning {
    color: #ffa726
}

.btn-pure.btn-warning.active.focus,
.btn-pure.btn-warning.active:focus,
.btn-pure.btn-warning.active:hover,
.btn-pure.btn-warning:active.focus,
.btn-pure.btn-warning:active:focus,
.btn-pure.btn-warning:active:hover,
.btn-pure.btn-warning:focus.focus,
.btn-pure.btn-warning:focus:focus,
.btn-pure.btn-warning:focus:hover,
.btn-pure.btn-warning:hover.focus,
.btn-pure.btn-warning:hover:focus,
.btn-pure.btn-warning:hover:hover,
.open>.dropdown-toggle.btn-pure.btn-warning.focus,
.open>.dropdown-toggle.btn-pure.btn-warning:focus,
.open>.dropdown-toggle.btn-pure.btn-warning:hover {
    color: #ffa726
}

.btn-pure.btn-warning.active .badge,
.btn-pure.btn-warning:active .badge,
.btn-pure.btn-warning:focus .badge,
.btn-pure.btn-warning:hover .badge,
.open>.dropdown-toggle.btn-pure.btn-warning .badge {
    color: #ffa726
}

.btn-pure.btn-danger {
    color: #f44336
}

.btn-pure.btn-danger.active,
.btn-pure.btn-danger:active,
.btn-pure.btn-danger:focus,
.btn-pure.btn-danger:hover,
.open>.dropdown-toggle.btn-pure.btn-danger {
    color: #ef5350
}

.btn-pure.btn-danger.active.focus,
.btn-pure.btn-danger.active:focus,
.btn-pure.btn-danger.active:hover,
.btn-pure.btn-danger:active.focus,
.btn-pure.btn-danger:active:focus,
.btn-pure.btn-danger:active:hover,
.btn-pure.btn-danger:focus.focus,
.btn-pure.btn-danger:focus:focus,
.btn-pure.btn-danger:focus:hover,
.btn-pure.btn-danger:hover.focus,
.btn-pure.btn-danger:hover:focus,
.btn-pure.btn-danger:hover:hover,
.open>.dropdown-toggle.btn-pure.btn-danger.focus,
.open>.dropdown-toggle.btn-pure.btn-danger:focus,
.open>.dropdown-toggle.btn-pure.btn-danger:hover {
    color: #ef5350
}

.btn-pure.btn-danger.active .badge,
.btn-pure.btn-danger:active .badge,
.btn-pure.btn-danger:focus .badge,
.btn-pure.btn-danger:hover .badge,
.open>.dropdown-toggle.btn-pure.btn-danger .badge {
    color: #ef5350
}

.btn-pure.btn-dark {
    color: #616161
}

.btn-pure.btn-dark.active,
.btn-pure.btn-dark:active,
.btn-pure.btn-dark:focus,
.btn-pure.btn-dark:hover,
.open>.dropdown-toggle.btn-pure.btn-dark {
    color: #757575
}

.btn-pure.btn-dark.active.focus,
.btn-pure.btn-dark.active:focus,
.btn-pure.btn-dark.active:hover,
.btn-pure.btn-dark:active.focus,
.btn-pure.btn-dark:active:focus,
.btn-pure.btn-dark:active:hover,
.btn-pure.btn-dark:focus.focus,
.btn-pure.btn-dark:focus:focus,
.btn-pure.btn-dark:focus:hover,
.btn-pure.btn-dark:hover.focus,
.btn-pure.btn-dark:hover:focus,
.btn-pure.btn-dark:hover:hover,
.open>.dropdown-toggle.btn-pure.btn-dark.focus,
.open>.dropdown-toggle.btn-pure.btn-dark:focus,
.open>.dropdown-toggle.btn-pure.btn-dark:hover {
    color: #757575
}

.btn-pure.btn-dark.active .badge,
.btn-pure.btn-dark:active .badge,
.btn-pure.btn-dark:focus .badge,
.btn-pure.btn-dark:hover .badge,
.open>.dropdown-toggle.btn-pure.btn-dark .badge {
    color: #757575
}

.btn-pure.btn-inverse {
    color: #fff
}

.btn-pure.btn-inverse.active,
.btn-pure.btn-inverse:active,
.btn-pure.btn-inverse:focus,
.btn-pure.btn-inverse:hover,
.open>.dropdown-toggle.btn-pure.btn-inverse {
    color: #fff
}

.btn-pure.btn-inverse.active.focus,
.btn-pure.btn-inverse.active:focus,
.btn-pure.btn-inverse.active:hover,
.btn-pure.btn-inverse:active.focus,
.btn-pure.btn-inverse:active:focus,
.btn-pure.btn-inverse:active:hover,
.btn-pure.btn-inverse:focus.focus,
.btn-pure.btn-inverse:focus:focus,
.btn-pure.btn-inverse:focus:hover,
.btn-pure.btn-inverse:hover.focus,
.btn-pure.btn-inverse:hover:focus,
.btn-pure.btn-inverse:hover:hover,
.open>.dropdown-toggle.btn-pure.btn-inverse.focus,
.open>.dropdown-toggle.btn-pure.btn-inverse:focus,
.open>.dropdown-toggle.btn-pure.btn-inverse:hover {
    color: #fff
}

.btn-pure.btn-inverse.active .badge,
.btn-pure.btn-inverse:active .badge,
.btn-pure.btn-inverse:focus .badge,
.btn-pure.btn-inverse:hover .badge,
.open>.dropdown-toggle.btn-pure.btn-inverse .badge {
    color: #fff
}

.caret {
    border-top: 4px solid
}

.dropdown-menu {
    -webkit-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, .1);
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, .1)
}

.dropdown-menu.bullet:after,
.dropdown-menu.bullet:before {
    border: 7px solid transparent;
    border-top-width: 0
}

.dropdown-menu.bullet:before {
    border-bottom-color: #e0e0e0
}

.dropdown-menu.bullet:after {
    border-bottom-color: #fff
}

.dropup .dropdown-menu,
.navbar-fixed-bottom .dropdown .dropdown-menu {
    -webkit-box-shadow: 0 -1px 4px 0 rgba(0, 0, 0, .1);
    box-shadow: 0 -1px 4px 0 rgba(0, 0, 0, .1)
}

.dropup .dropdown-menu.bullet:after,
.dropup .dropdown-menu.bullet:before,
.navbar-fixed-bottom .dropdown .dropdown-menu.bullet:after,
.navbar-fixed-bottom .dropdown .dropdown-menu.bullet:before {
    border-top-width: 7px;
    border-bottom-width: 0
}

.dropup .dropdown-menu.bullet:before,
.navbar-fixed-bottom .dropdown .dropdown-menu.bullet:before {
    border-top-color: #e0e0e0
}

.dropup .dropdown-menu.bullet:after,
.navbar-fixed-bottom .dropdown .dropdown-menu.bullet:after {
    border-top-color: #fff
}

.dropdown-menu>.dropdown-submenu>a:after {
    border-top: 4px solid transparent;
    border-bottom: 4px solid transparent;
    border-left: 4px dashed
}

.dropdown-menu-media .dropdown-menu-header {
    padding: 20px 20px;
    background-color: #fff;
    border-bottom: 1px solid #e0e0e0
}

.dropdown-menu-media .list-group-item {
    border: none
}

.dropdown-menu-media .list-group-item .media {
    border-top: 1px solid #e0e0e0
}

.dropdown-menu-media .list-group-item:first-child .media {
    border-top: none
}

.dropdown-menu-media>.dropdown-menu-footer {
    background-color: #eee;
    border-top: 1px solid #e0e0e0
}

.dropdown-menu-media>.dropdown-menu-footer>a {
    color: #9e9e9e!important
}

.dropdown-menu-media>.dropdown-menu-footer>a:hover {
    color: #2196f3!important;
    background-color: transparent!important
}

.dropdown-menu-media>.dropdown-menu-footer>.dropdown-menu-footer-btn:hover {
    color: #2196f3!important;
    background-color: transparent!important
}

.dropdown-menu-primary>li>a:focus,
.dropdown-menu-primary>li>a:hover {
    color: #fff;
    background-color: #2196f3
}

.dropdown-menu-primary>.active>a,
.dropdown-menu-primary>.active>a:focus,
.dropdown-menu-primary>.active>a:hover {
    color: #fff;
    background-color: #2196f3
}

.dropdown-menu-success>li>a:focus,
.dropdown-menu-success>li>a:hover {
    color: #fff;
    background-color: #4caf50
}

.dropdown-menu-success>.active>a,
.dropdown-menu-success>.active>a:focus,
.dropdown-menu-success>.active>a:hover {
    color: #fff;
    background-color: #4caf50
}

.dropdown-menu-info>li>a:focus,
.dropdown-menu-info>li>a:hover {
    color: #fff;
    background-color: #00bcd4
}

.dropdown-menu-info>.active>a,
.dropdown-menu-info>.active>a:focus,
.dropdown-menu-info>.active>a:hover {
    color: #fff;
    background-color: #00bcd4
}

.dropdown-menu-warning>li>a:focus,
.dropdown-menu-warning>li>a:hover {
    color: #fff;
    background-color: #ff9800
}

.dropdown-menu-warning>.active>a,
.dropdown-menu-warning>.active>a:focus,
.dropdown-menu-warning>.active>a:hover {
    color: #fff;
    background-color: #ff9800
}

.dropdown-menu-danger>li>a:focus,
.dropdown-menu-danger>li>a:hover {
    color: #fff;
    background-color: #f44336
}

.dropdown-menu-danger>.active>a,
.dropdown-menu-danger>.active>a:focus,
.dropdown-menu-danger>.active>a:hover {
    color: #fff;
    background-color: #f44336
}

.dropdown-menu-dark>li>a:focus,
.dropdown-menu-dark>li>a:hover {
    color: #fff;
    background-color: #616161
}

.dropdown-menu-dark>.active>a,
.dropdown-menu-dark>.active>a:focus,
.dropdown-menu-dark>.active>a:hover {
    color: #fff;
    background-color: #616161
}

.btn-group.open .dropdown-toggle {
    -webkit-box-shadow: inset 0 1px 3px rgba(0, 0, 0, .05);
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, .05)
}

.nav .open>a,
.nav .open>a:focus,
.nav .open>a:hover {
    border-color: transparent
}

.nav-quick {
    background-color: #fff;
    -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, .05);
    box-shadow: 0 1px 1px rgba(0, 0, 0, .05)
}

.nav-quick a {
    color: #757575
}

.nav-quick a:hover {
    background-color: #eee
}

.nav-quick-bordered {
    border-top: 1px solid #e0e0e0;
    border-left: 1px solid #e0e0e0
}

.nav-quick-bordered li {
    border-right: 1px solid #e0e0e0;
    border-bottom: 1px solid #e0e0e0
}

.nav-tabs>li>a {
    color: #757575
}

.nav-tabs>li.active>a,
.nav-tabs>li.active>a:focus,
.nav-tabs>li.active>a:hover {
    color: #fff;
    background-color: #2196f3;
    border-color: transparent;
    border-bottom-color: #2196f3
}

.nav-tabs.nav-justified>li.active>a,
.nav-tabs.nav-justified>li.active>a:focus,
.nav-tabs.nav-justified>li.active>a:hover {
    border-color: transparent;
    border-bottom-color: #2196f3
}

.nav-tabs.nav-tabs-bottom {
    border-top: 1px solid #e0e0e0
}

.nav-tabs.nav-tabs-bottom>li>a:focus,
.nav-tabs.nav-tabs-bottom>li>a:hover {
    border-top-color: #e0e0e0;
    border-bottom-color: transparent
}

.nav-tabs.nav-tabs-bottom.nav-justified {
    border-top: none
}

.nav-tabs.nav-tabs-bottom.nav-justified>li>a {
    border-top-color: #e0e0e0;
    border-bottom-color: transparent
}

.nav-tabs.nav-tabs-bottom.nav-justified>li.active>a,
.nav-tabs.nav-tabs-bottom.nav-justified>li.active>a:focus,
.nav-tabs.nav-tabs-bottom.nav-justified>li.active>a:hover {
    border-top: 1px solid #2196f3
}

.nav-tabs-solid {
    border-bottom-color: #eee
}

.nav-tabs-solid>li>a:hover {
    border-color: transparent
}

.nav-tabs-solid>li.active>a,
.nav-tabs-solid>li.active>a:focus,
.nav-tabs-solid>li.active>a:hover {
    color: #757575;
    background-color: #eee;
    border-color: transparent
}

.nav-tabs-solid~.tab-content {
    background-color: #eee
}

.nav-tabs-solid.nav-justified>li>a {
    border: none
}

.nav-tabs-solid.nav-justified>li.active>a,
.nav-tabs-solid.nav-justified>li.active>a:focus,
.nav-tabs-solid.nav-justified>li.active>a:hover {
    border: none
}

.nav-tabs-solid.nav-tabs-bottom>li.active>a,
.nav-tabs-solid.nav-tabs-bottom>li.active>a:focus,
.nav-tabs-solid.nav-tabs-bottom>li.active>a:hover {
    border: none
}

.nav-tabs-line>li>a {
    border-bottom: 2px solid transparent
}

.nav-tabs-line>li>a:focus,
.nav-tabs-line>li>a:hover {
    background-color: transparent
}

.nav-tabs-line>li>a:hover {
    border-bottom-color: #bdbdbd
}

.nav-tabs-line>li.active>a,
.nav-tabs-line>li.active>a:focus,
.nav-tabs-line>li.active>a:hover {
    color: #2196f3;
    background-color: transparent;
    border-bottom: 2px solid #2196f3
}

.nav-tabs-line .open>a,
.nav-tabs-line .open>a:focus,
.nav-tabs-line .open>a:hover {
    border-color: transparent;
    border-bottom-color: #bdbdbd
}

.nav-tabs-line>.nav-tabs-autoline {
    background-color: #2196f3
}

.nav-tabs-line.nav-tabs-bottom>li>a {
    border-top: 2px solid transparent;
    border-bottom: none
}

.nav-tabs-line.nav-tabs-bottom>li>a:hover {
    border-top-color: #bdbdbd;
    border-bottom-color: transparent
}

.nav-tabs-line.nav-tabs-bottom>li.active>a,
.nav-tabs-line.nav-tabs-bottom>li.active>a:focus,
.nav-tabs-line.nav-tabs-bottom>li.active>a:hover {
    border-top: 2px solid #2196f3;
    border-bottom: none
}

.nav-tabs-line.nav-justified>li>a {
    border-bottom: 2px solid #e0e0e0
}

.nav-tabs-line.nav-justified>li>a:hover {
    border-bottom-color: #bdbdbd
}

.nav-tabs-line.nav-justified>li.active>a,
.nav-tabs-line.nav-justified>li.active>a:focus,
.nav-tabs-line.nav-justified>li.active>a:hover {
    border-color: transparent;
    border-bottom: 2px solid #2196f3
}

.nav-tabs-line.nav-justified.nav-tabs-bottom {
    border-top: none
}

.nav-tabs-line.nav-justified.nav-tabs-bottom>li>a {
    border-top: 2px solid #e0e0e0;
    border-bottom: none
}

.nav-tabs-line.nav-justified.nav-tabs-bottom>li>a:hover {
    border-top-color: #bdbdbd
}

.nav-tabs-line.nav-justified.nav-tabs-bottom>li.active>a,
.nav-tabs-line.nav-justified.nav-tabs-bottom>li.active>a:focus,
.nav-tabs-line.nav-justified.nav-tabs-bottom>li.active>a:hover {
    border-top-color: #2196f3;
    border-bottom: none
}

.nav-tabs-vertical:after,
.nav-tabs-vertical:before {
    display: table;
    content: " "
}

.nav-tabs-vertical:after {
    clear: both
}

.nav-tabs-vertical .nav-tabs {
    border-right: 1px solid #e0e0e0;
    border-bottom: none
}

.nav-tabs-vertical .nav-tabs>li>a:hover {
    border-right-color: #e0e0e0;
    border-bottom-color: transparent
}

.nav-tabs-vertical .nav-tabs>li.active>a,
.nav-tabs-vertical .nav-tabs>li.active>a:focus,
.nav-tabs-vertical .nav-tabs>li.active>a:hover {
    border-right-color: #2196f3
}

.nav-tabs-vertical .nav-tabs-reverse {
    border-right: none;
    border-left: 1px solid #e0e0e0
}

.nav-tabs-vertical .nav-tabs-reverse>li>a:hover {
    border-right-color: transparent;
    border-left-color: #e0e0e0
}

.nav-tabs-vertical .nav-tabs-reverse>li.active>a,
.nav-tabs-vertical .nav-tabs-reverse>li.active>a:focus,
.nav-tabs-vertical .nav-tabs-reverse>li.active>a:hover {
    border-left-color: #2196f3
}

.nav-tabs-vertical .nav-tabs-solid {
    border-right-color: #eee
}

.nav-tabs-vertical .nav-tabs-solid>li>a:hover {
    border-color: transparent
}

.nav-tabs-vertical .nav-tabs-solid>li.active>a,
.nav-tabs-vertical .nav-tabs-solid>li.active>a:focus,
.nav-tabs-vertical .nav-tabs-solid>li.active>a:hover {
    border-color: transparent
}

.nav-tabs-vertical .nav-tabs-solid.nav-tabs-reverse {
    border-left-color: #eee
}

.nav-tabs-vertical .nav-tabs-line>li>a {
    border-right: 2px solid transparent;
    border-bottom: none
}

.nav-tabs-vertical .nav-tabs-line>li>a:hover {
    border-right-color: #bdbdbd
}

.nav-tabs-vertical .nav-tabs-line>li.active>a,
.nav-tabs-vertical .nav-tabs-line>li.active>a:focus,
.nav-tabs-vertical .nav-tabs-line>li.active>a:hover {
    border-right: 2px solid #2196f3;
    border-bottom: none
}

.nav-tabs-vertical .nav-tabs-line.nav-tabs-reverse>li>a {
    border-right-width: 1px;
    border-left: 2px solid transparent
}

.nav-tabs-vertical .nav-tabs-line.nav-tabs-reverse>li>a:hover {
    border-color: transparent;
    border-left-color: #bdbdbd
}

.nav-tabs-vertical .nav-tabs-line.nav-tabs-reverse>li.active>a,
.nav-tabs-vertical .nav-tabs-line.nav-tabs-reverse>li.active>a:focus,
.nav-tabs-vertical .nav-tabs-line.nav-tabs-reverse>li.active>a:hover {
    border-right: 1px solid transparent;
    border-left: 2px solid #2196f3
}

.nav-tabs-inverse .nav-tabs-solid {
    border-bottom-color: #fff
}

.nav-tabs-inverse .nav-tabs-solid>li.active>a,
.nav-tabs-inverse .nav-tabs-solid>li.active>a:focus,
.nav-tabs-inverse .nav-tabs-solid>li.active>a:hover {
    color: #757575;
    background-color: #fff
}

.nav-tabs-inverse.nav-tabs-vertical .nav-tabs-solid {
    border-right-color: #fff
}

.nav-tabs-inverse.nav-tabs-vertical .nav-tabs-solid.nav-tabs-reverse {
    border-left-color: #fff
}

.nav-tabs-inverse .tab-content {
    background: #fff
}

.navbar-toggle {
    background: 0 0!important
}

.navbar-toggle:hover {
    background: 0 0!important
}

.navbar {
    border: none;
    -webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, .08);
    box-shadow: 0 2px 4px rgba(0, 0, 0, .08)
}

.navbar-form .icon {
    color: rgba(66, 66, 66, .4)
}

.navbar-form .form-control {
    background-color: #eee;
    border: none
}

@media (max-width:767px) {
    .navbar-search .navbar-form {
        border-bottom: none
    }
}

.navbar-search-overlap {
    background-color: #fff
}

.navbar-search-overlap .form-control {
    background-color: transparent!important
}

.navbar-search-overlap .form-control:focus {
    border-color: transparent
}

.navbar-default .navbar-toolbar>li>a {
    color: #757575
}

.navbar-default .navbar-toolbar>li>a:focus,
.navbar-default .navbar-toolbar>li>a:hover {
    color: #616161;
    background-color: rgba(238, 238, 238, .3)
}

.navbar-default .navbar-toolbar>.active>a,
.navbar-default .navbar-toolbar>.active>a:focus,
.navbar-default .navbar-toolbar>.active>a:hover {
    color: #616161;
    background-color: rgba(238, 238, 238, .6)
}

.navbar-default .navbar-toolbar>.disabled>a,
.navbar-default .navbar-toolbar>.disabled>a:focus,
.navbar-default .navbar-toolbar>.disabled>a:hover {
    color: #bdbdbd;
    background-color: transparent
}

.navbar-default .navbar-toggle {
    color: #757575
}

.navbar-default .navbar-toolbar>.open>a,
.navbar-default .navbar-toolbar>.open>a:focus,
.navbar-default .navbar-toolbar>.open>a:hover {
    color: #616161;
    background-color: rgba(238, 238, 238, .6)
}

.navbar-inverse .navbar-toolbar>li>a {
    color: #fff
}

.navbar-inverse .navbar-toolbar>li>a:focus,
.navbar-inverse .navbar-toolbar>li>a:hover {
    color: #fff;
    background-color: rgba(0, 0, 0, .1)
}

.navbar-inverse .navbar-toolbar>.active>a,
.navbar-inverse .navbar-toolbar>.active>a:focus,
.navbar-inverse .navbar-toolbar>.active>a:hover {
    color: #fff;
    background-color: rgba(0, 0, 0, .1)
}

.navbar-inverse .navbar-toolbar>.disabled>a,
.navbar-inverse .navbar-toolbar>.disabled>a:focus,
.navbar-inverse .navbar-toolbar>.disabled>a:hover {
    color: #fff;
    background-color: transparent
}

.navbar-inverse .navbar-toggle {
    color: #fff
}

.navbar-inverse .navbar-toolbar>.open>a,
.navbar-inverse .navbar-toolbar>.open>a:focus,
.navbar-inverse .navbar-toolbar>.open>a:hover {
    color: #fff;
    background-color: rgba(0, 0, 0, .1)
}

.breadcrumb li .icon {
    text-decoration: none
}

.pagination>.disabled>a,
.pagination>.disabled>a:focus,
.pagination>.disabled>a:hover,
.pagination>.disabled>span,
.pagination>.disabled>span:focus,
.pagination>.disabled>span:hover {
    color: #bdbdbd;
    background-color: transparent;
    border-color: #e0e0e0
}

.pagination-gap>li>a:hover {
    background-color: transparent;
    border-color: #2196f3
}

.pagination-no-border>li>a {
    border: none
}

.pager li>a,
.pager li>span {
    color: #757575
}

.pager li>a:focus,
.pager li>a:hover {
    color: #2196f3
}

.pager li>a:focus,
.pager li>a:hover {
    border-color: #2196f3
}

.pager .disabled>a,
.pager .disabled>a:focus,
.pager .disabled>a:hover,
.pager .disabled>span {
    border-color: #e0e0e0
}

.label.label-outline {
    color: #eee;
    background-color: transparent;
    border-color: #eee
}

.label-outline {
    border: 1px solid transparent
}

.label-default {
    color: #757575;
    background-color: #e0e0e0
}

.label-default[href]:focus,
.label-default[href]:hover {
    background-color: #eee
}

.label-default.label-outline {
    color: #e0e0e0;
    background-color: transparent;
    border-color: #e0e0e0
}

.label-default[href]:focus,
.label-default[href]:hover {
    color: #9e9e9e
}

.label-default.label-outline {
    color: #757575
}

.label-primary {
    background-color: #2196f3
}

.label-primary[href]:focus,
.label-primary[href]:hover {
    background-color: #42a5f5
}

.label-primary.label-outline {
    color: #2196f3;
    background-color: transparent;
    border-color: #2196f3
}

.label-success {
    background-color: #4caf50
}

.label-success[href]:focus,
.label-success[href]:hover {
    background-color: #66bb6a
}

.label-success.label-outline {
    color: #4caf50;
    background-color: transparent;
    border-color: #4caf50
}

.label-info {
    background-color: #00bcd4
}

.label-info[href]:focus,
.label-info[href]:hover {
    background-color: #26c6da
}

.label-info.label-outline {
    color: #00bcd4;
    background-color: transparent;
    border-color: #00bcd4
}

.label-warning {
    background-color: #ff9800
}

.label-warning[href]:focus,
.label-warning[href]:hover {
    background-color: #ffa726
}

.label-warning.label-outline {
    color: #ff9800;
    background-color: transparent;
    border-color: #ff9800
}

.label-danger {
    background-color: #f44336
}

.label-danger[href]:focus,
.label-danger[href]:hover {
    background-color: #ef5350
}

.label-danger.label-outline {
    color: #f44336;
    background-color: transparent;
    border-color: #f44336
}

.label-dark {
    background-color: #616161
}

.label-dark[href]:focus,
.label-dark[href]:hover {
    background-color: #757575
}

.label-dark.label-outline {
    color: #616161;
    background-color: transparent;
    border-color: #616161
}

.badge-primary {
    color: #fff;
    background-color: #2196f3
}

.badge-primary[href]:focus,
.badge-primary[href]:hover {
    color: #fff;
    background-color: #0c7cd5
}

.list-group-item.active>.badge-primary,
.nav-pills>.active>a>.badge-primary {
    color: #fff;
    background-color: #2196f3
}

.badge-success {
    color: #fff;
    background-color: #4caf50
}

.badge-success[href]:focus,
.badge-success[href]:hover {
    color: #fff;
    background-color: #3d8b40
}

.list-group-item.active>.badge-success,
.nav-pills>.active>a>.badge-success {
    color: #fff;
    background-color: #4caf50
}

.badge-info {
    color: #fff;
    background-color: #00bcd4
}

.badge-info[href]:focus,
.badge-info[href]:hover {
    color: #fff;
    background-color: #008fa1
}

.list-group-item.active>.badge-info,
.nav-pills>.active>a>.badge-info {
    color: #fff;
    background-color: #00bcd4
}

.badge-warning {
    color: #fff;
    background-color: #ff9800
}

.badge-warning[href]:focus,
.badge-warning[href]:hover {
    color: #fff;
    background-color: #cc7a00
}

.list-group-item.active>.badge-warning,
.nav-pills>.active>a>.badge-warning {
    color: #fff;
    background-color: #ff9800
}

.badge-danger {
    color: #fff;
    background-color: #f44336
}

.badge-danger[href]:focus,
.badge-danger[href]:hover {
    color: #fff;
    background-color: #ea1c0d
}

.list-group-item.active>.badge-danger,
.nav-pills>.active>a>.badge-danger {
    color: #fff;
    background-color: #f44336
}

.badge-dark {
    color: #fff;
    background-color: #616161
}

.badge-dark[href]:focus,
.badge-dark[href]:hover {
    color: #fff;
    background-color: #484848
}

.list-group-item.active>.badge-dark,
.nav-pills>.active>a>.badge-dark {
    color: #fff;
    background-color: #616161
}

.thumbnail {
    border: none
}

.alert-alt {
    color: #757575;
    background-color: rgba(238, 238, 238, .8);
    border: none;
    border-left: 3px solid transparent
}

.alert-alt .alert-link,
.alert-alt a {
    text-decoration: none
}

.alert-dismissible .close {
    text-decoration: none
}

.alert-dismissible.alert-alt .close {
    color: #9e9e9e
}

.alert-dismissible.alert-alt .close:focus,
.alert-dismissible.alert-alt .close:hover {
    color: #9e9e9e
}

.alert-primary {
    color: #2196f3;
    background-color: rgba(187, 222, 251, .8);
    border-color: #bbdefb
}

.alert-primary hr {
    border-top-color: #a3d2fa
}

.alert-primary .alert-link {
    color: #0c7cd5
}

.alert-primary .close {
    color: #2196f3
}

.alert-primary .close:focus,
.alert-primary .close:hover {
    color: #2196f3
}

.alert-primary .alert-link {
    color: #1e88e5
}

.alert-alt.alert-primary {
    border-color: #2196f3
}

.alert-alt.alert-primary .alert-link,
.alert-alt.alert-primary a {
    color: #2196f3
}

.alert-success .alert-link {
    color: #43a047
}

.alert-alt.alert-success {
    border-color: #4caf50
}

.alert-alt.alert-success .alert-link,
.alert-alt.alert-success a {
    color: #4caf50
}

.alert-info .alert-link {
    color: #00acc1
}

.alert-alt.alert-info {
    border-color: #00bcd4
}

.alert-alt.alert-info .alert-link,
.alert-alt.alert-info a {
    color: #00bcd4
}

.alert-warning .alert-link {
    color: #fb8c00
}

.alert-alt.alert-warning {
    border-color: #ff9800
}

.alert-alt.alert-warning .alert-link,
.alert-alt.alert-warning a {
    color: #ff9800
}

.alert-danger .alert-link {
    color: #e53935
}

.alert-alt.alert-danger {
    border-color: #f44336
}

.alert-alt.alert-danger .alert-link,
.alert-alt.alert-danger a {
    color: #f44336
}

.alert-facebook {
    color: #fff;
    background-color: #3b5998;
    border-color: #3b5998
}

.alert-facebook hr {
    border-top-color: #344e86
}

.alert-facebook .alert-link {
    color: #e6e6e6
}

.alert-facebook .close {
    color: #fff
}

.alert-facebook .close:focus,
.alert-facebook .close:hover {
    color: #fff
}

.alert-facebook .alert-link {
    color: #fff
}

.alert-twitter {
    color: #fff;
    background-color: #55acee;
    border-color: #55acee
}

.alert-twitter hr {
    border-top-color: #3ea1ec
}

.alert-twitter .alert-link {
    color: #e6e6e6
}

.alert-twitter .close {
    color: #fff
}

.alert-twitter .close:focus,
.alert-twitter .close:hover {
    color: #fff
}

.alert-twitter .alert-link {
    color: #fff
}

.alert-google-plus {
    color: #fff;
    background-color: #dd4b39;
    border-color: #dd4b39
}

.alert-google-plus hr {
    border-top-color: #d73925
}

.alert-google-plus .alert-link {
    color: #e6e6e6
}

.alert-google-plus .close {
    color: #fff
}

.alert-google-plus .close:focus,
.alert-google-plus .close:hover {
    color: #fff
}

.alert-google-plus .alert-link {
    color: #fff
}

.alert-linkedin {
    color: #fff;
    background-color: #0976b4;
    border-color: #0976b4
}

.alert-linkedin hr {
    border-top-color: #08669c
}

.alert-linkedin .alert-link {
    color: #e6e6e6
}

.alert-linkedin .close {
    color: #fff
}

.alert-linkedin .close:focus,
.alert-linkedin .close:hover {
    color: #fff
}

.alert-linkedin .alert-link {
    color: #fff
}

.alert-flickr {
    color: #fff;
    background-color: #ff0084;
    border-color: #ff0084
}

.alert-flickr hr {
    border-top-color: #e60077
}

.alert-flickr .alert-link {
    color: #e6e6e6
}

.alert-flickr .close {
    color: #fff
}

.alert-flickr .close:focus,
.alert-flickr .close:hover {
    color: #fff
}

.alert-flickr .alert-link {
    color: #fff
}

.alert-tumblr {
    color: #fff;
    background-color: #35465c;
    border-color: #35465c
}

.alert-tumblr hr {
    border-top-color: #2c3a4c
}

.alert-tumblr .alert-link {
    color: #e6e6e6
}

.alert-tumblr .close {
    color: #fff
}

.alert-tumblr .close:focus,
.alert-tumblr .close:hover {
    color: #fff
}

.alert-tumblr .alert-link {
    color: #fff
}

.alert-github {
    color: #fff;
    background-color: #4183c4;
    border-color: #4183c4
}

.alert-github hr {
    border-top-color: #3876b4
}

.alert-github .alert-link {
    color: #e6e6e6
}

.alert-github .close {
    color: #fff
}

.alert-github .close:focus,
.alert-github .close:hover {
    color: #fff
}

.alert-github .alert-link {
    color: #fff
}

.alert-dribbble {
    color: #fff;
    background-color: #c32361;
    border-color: #c32361
}

.alert-dribbble hr {
    border-top-color: #ad1f56
}

.alert-dribbble .alert-link {
    color: #e6e6e6
}

.alert-dribbble .close {
    color: #fff
}

.alert-dribbble .close:focus,
.alert-dribbble .close:hover {
    color: #fff
}

.alert-dribbble .alert-link {
    color: #fff
}

.alert-youtube {
    color: #fff;
    background-color: #b31217;
    border-color: #b31217
}

.alert-youtube hr {
    border-top-color: #9c1014
}

.alert-youtube .alert-link {
    color: #e6e6e6
}

.alert-youtube .close {
    color: #fff
}

.alert-youtube .close:focus,
.alert-youtube .close:hover {
    color: #fff
}

.alert-youtube .alert-link {
    color: #fff
}

.alert.dark .alert-link {
    color: #fff!important
}

.alert.dark .alert-left-border {
    border: none;
    border-left: 3px solid transparent
}

.alert.dark.alert-dismissible.alert-alt .close {
    color: #fff
}

.alert.dark.alert-dismissible.alert-alt .close:focus,
.alert.dark.alert-dismissible.alert-alt .close:hover {
    color: #fff
}

.alert.dark.alert-primary {
    color: #fff;
    background-color: #2196f3;
    border-color: #2196f3
}

.alert.dark.alert-primary hr {
    border-top-color: #0d8aee
}

.alert.dark.alert-primary .alert-link {
    color: #e6e6e6
}

.alert.dark.alert-primary .close {
    color: #fff
}

.alert.dark.alert-primary .close:focus,
.alert.dark.alert-primary .close:hover {
    color: #fff
}

.alert-alt.alert.dark.alert-primary {
    border-color: #1360a4
}

.alert-alt.alert.dark.alert-primary .alert-link,
.alert-alt.alert.dark.alert-primary a {
    color: #fff
}

.alert.dark.alert-success {
    color: #fff;
    background-color: #4caf50;
    border-color: #4caf50
}

.alert.dark.alert-success hr {
    border-top-color: #449d48
}

.alert.dark.alert-success .alert-link {
    color: #e6e6e6
}

.alert.dark.alert-success .close {
    color: #fff
}

.alert.dark.alert-success .close:focus,
.alert.dark.alert-success .close:hover {
    color: #fff
}

.alert-alt.alert.dark.alert-success {
    border-color: #2c6a2f
}

.alert-alt.alert.dark.alert-success .alert-link,
.alert-alt.alert.dark.alert-success a {
    color: #fff
}

.alert.dark.alert-info {
    color: #fff;
    background-color: #00bcd4;
    border-color: #00bcd4
}

.alert.dark.alert-info hr {
    border-top-color: #00a5bb
}

.alert.dark.alert-info .alert-link {
    color: #e6e6e6
}

.alert.dark.alert-info .close {
    color: #fff
}

.alert.dark.alert-info .close:focus,
.alert.dark.alert-info .close:hover {
    color: #fff
}

.alert-alt.alert.dark.alert-info {
    border-color: #006875
}

.alert-alt.alert.dark.alert-info .alert-link,
.alert-alt.alert.dark.alert-info a {
    color: #fff
}

.alert.dark.alert-warning {
    color: #fff;
    background-color: #ff9800;
    border-color: #ff9800
}

.alert.dark.alert-warning hr {
    border-top-color: #e68900
}

.alert.dark.alert-warning .alert-link {
    color: #e6e6e6
}

.alert.dark.alert-warning .close {
    color: #fff
}

.alert.dark.alert-warning .close:focus,
.alert.dark.alert-warning .close:hover {
    color: #fff
}

.alert-alt.alert.dark.alert-warning {
    border-color: #af6100
}

.alert-alt.alert.dark.alert-warning .alert-link,
.alert-alt.alert.dark.alert-warning a {
    color: #fff
}

.alert.dark.alert-danger {
    color: #fff;
    background-color: #f44336;
    border-color: #f44336
}

.alert.dark.alert-danger hr {
    border-top-color: #f32c1e
}

.alert.dark.alert-danger .alert-link {
    color: #e6e6e6
}

.alert.dark.alert-danger .close {
    color: #fff
}

.alert.dark.alert-danger .close:focus,
.alert.dark.alert-danger .close:hover {
    color: #fff
}

.alert-alt.alert.dark.alert-danger {
    border-color: #b61b17
}

.alert-alt.alert.dark.alert-danger .alert-link,
.alert-alt.alert.dark.alert-danger a {
    color: #fff
}

.progress {
    -webkit-box-shadow: none;
    box-shadow: none
}

.progress-bar {
    -webkit-box-shadow: none;
    box-shadow: none
}

.progress-bar-indicating.active:before {
    background-color: #fff
}

.vertical .progress-bar-indicating.active:before {
    background-color: #fff
}

.progress-skill .progress-bar>span {
    color: #616161
}

.media .media {
    border-bottom: none
}

.media-meta {
    color: #616161
}

.list-group .media {
    border-bottom: 0
}

a.list-group-item.disabled,
a.list-group-item.disabled:focus,
a.list-group-item.disabled:hover {
    color: #bdbdbd;
    background-color: #eee
}

a.list-group-item.active,
a.list-group-item.active:focus,
a.list-group-item.active:hover {
    color: #fff;
    background-color: #2196f3
}

.list-group.bg-inherit .list-group-item {
    background-color: transparent;
    border-bottom-color: rgba(0, 0, 0, .075)
}

.list-group.bg-inherit .list-group-item:last-child {
    border-bottom-color: transparent
}

.list-group.bg-inherit .list-group-item:hover {
    background-color: rgba(0, 0, 0, .075);
    border-color: transparent
}

.list-group-bordered .list-group-item {
    border-color: #e0e0e0
}

.list-group-bordered .list-group-item.active,
.list-group-bordered .list-group-item.active:focus,
.list-group-bordered .list-group-item.active:hover {
    color: #fff;
    background-color: #1976d2;
    border-color: #1976d2
}

.list-group-dividered .list-group-item {
    border-top-color: #e0e0e0
}

.list-group-dividered .list-group-item.active:hover {
    border-top-color: #e0e0e0
}

.list-group-dividered .list-group-item:last-child {
    border-bottom-color: #e0e0e0
}

.list-group-dividered .list-group-item:first-child {
    border-top-color: transparent
}

.list-group-dividered .list-group-item:first-child.active:hover {
    border-top-color: transparent
}

.list-group-item-dark {
    color: #fff;
    background-color: #616161
}

a.list-group-item-dark,
button.list-group-item-dark {
    color: #fff
}

a.list-group-item-dark .list-group-item-heading,
button.list-group-item-dark .list-group-item-heading {
    color: inherit
}

a.list-group-item-dark:focus,
a.list-group-item-dark:hover,
button.list-group-item-dark:focus,
button.list-group-item-dark:hover {
    color: #fff;
    background-color: #545454
}

a.list-group-item-dark.active,
a.list-group-item-dark.active:focus,
a.list-group-item-dark.active:hover,
button.list-group-item-dark.active,
button.list-group-item-dark.active:focus,
button.list-group-item-dark.active:hover {
    color: #fff;
    background-color: #fff;
    border-color: #fff
}

.panel {
    border-width: 0
}

.panel>.nav-tabs-vertical .nav-tabs>li>a {
    border-left: none
}

.panel>.nav-tabs-vertical .nav-tabs.nav-tabs-reverse>li>a {
    border-right: none
}

.panel-heading {
    border-bottom: 1px solid transparent
}

.panel-heading>.nav-tabs {
    border-bottom: none
}

.panel-body>.list-group-dividered:only-child>.list-group-item:last-child {
    border-bottom-color: transparent
}

.panel-footer {
    border-top: 1px solid transparent
}

.table+.panel-footer {
    border-color: #e0e0e0
}

.panel-title {
    color: #424242
}

.panel-title small {
    color: #757575
}

.panel-desc {
    color: #757575
}

.panel-actions a {
    color: inherit
}

.panel-actions .panel-action {
    color: #9e9e9e;
    text-decoration: none;
    background-color: transparent
}

.panel-actions .panel-action:hover {
    color: #616161
}

.panel-actions .panel-action:active {
    color: #616161
}

.panel-toolbar {
    background-color: transparent;
    border-top: 1px solid #e0e0e0;
    border-bottom: 1px solid #e0e0e0
}

.panel-bordered .panel-toolbar {
    border-top-color: transparent
}

.panel-toolbar .btn {
    color: #9e9e9e
}

.panel-toolbar .btn.active,
.panel-toolbar .btn:active,
.panel-toolbar .btn:hover {
    color: #757575
}

.panel-control {
    border: none;
    -webkit-box-shadow: none;
    box-shadow: none
}

.panel-bordered>.panel-heading {
    border-bottom: 1px solid #e0e0e0
}

.panel-bordered>.panel-footer {
    border-top: 1px solid #e0e0e0
}

.panel.panel-transparent {
    background: 0 0;
    border-color: transparent;
    -webkit-box-shadow: none;
    box-shadow: none
}

.panel.panel-transparent>.panel-footer,
.panel.panel-transparent>.panel-heading {
    border-color: transparent
}

.panel-danger,
.panel-dark,
.panel-info,
.panel-primary,
.panel-success,
.panel-warning {
    border: none
}

.panel-danger .panel-heading,
.panel-dark .panel-heading,
.panel-info .panel-heading,
.panel-primary .panel-heading,
.panel-success .panel-heading,
.panel-warning .panel-heading {
    border: none
}

.panel-danger .panel-title,
.panel-dark .panel-title,
.panel-info .panel-title,
.panel-primary .panel-title,
.panel-success .panel-title,
.panel-warning .panel-title {
    color: #fff
}

.panel-danger .panel-action,
.panel-dark .panel-action,
.panel-info .panel-action,
.panel-primary .panel-action,
.panel-success .panel-action,
.panel-warning .panel-action {
    color: #fff
}

.well {
    -webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, .02);
    box-shadow: inset 0 0 1px rgba(0, 0, 0, .02)
}

.well-primary {
    color: #fff;
    background-color: #2196f3
}

.well-success {
    color: #fff;
    background-color: #4caf50
}

.well-info {
    color: #fff;
    background-color: #00bcd4
}

.well-warning {
    color: #fff;
    background-color: #ff9800
}

.well-danger {
    color: #fff;
    background-color: #f44336
}

.modal-content {
    border: none;
    -webkit-box-shadow: 0 2px 12px rgba(0, 0, 0, .2);
    box-shadow: 0 2px 12px rgba(0, 0, 0, .2)
}

.modal-header {
    border-bottom: none
}

.modal-footer {
    border-top: none
}

.modal-sidebar {
    background-color: #fff
}

.modal-sidebar .modal-content {
    background-color: transparent;
    -webkit-box-shadow: none;
    box-shadow: none
}

.modal-sidebar .modal-header {
    border-bottom: none
}

.modal-sidebar .modal-footer {
    border-top: none
}

.modal-fill-in {
    background-color: transparent
}

.modal-fill-in.in {
    background-color: rgba(255, 255, 255, .95)
}

.modal-fill-in .modal-content {
    background-color: transparent;
    -webkit-box-shadow: none;
    box-shadow: none
}

.modal-fill-in .modal-header {
    border-bottom: none
}

.modal-fill-in .modal-footer {
    border-top: none
}

.modal-primary .modal-header {
    background-color: #2196f3
}

.modal-primary .modal-header * {
    color: #fff
}

.modal-success .modal-header {
    background-color: #4caf50
}

.modal-success .modal-header * {
    color: #fff
}

.modal-info .modal-header {
    background-color: #00bcd4
}

.modal-info .modal-header * {
    color: #fff
}

.modal-warning .modal-header {
    background-color: #ff9800
}

.modal-warning .modal-header * {
    color: #fff
}

.modal-danger .modal-header {
    background-color: #f44336
}

.modal-danger .modal-header * {
    color: #fff
}

.modal.modal-just-me .modal-backdrop {
    background-color: #fff
}

.modal.modal-just-me.in {
    background: #fff
}

.tooltip-primary+.tooltip .tooltip-inner {
    color: #fff;
    background-color: #2196f3
}

.tooltip-primary+.tooltip.top .tooltip-arrow {
    border-top-color: #2196f3
}

.tooltip-primary+.tooltip.right .tooltip-arrow {
    border-right-color: #2196f3
}

.tooltip-primary+.tooltip.bottom .tooltip-arrow {
    border-bottom-color: #2196f3
}

.tooltip-primary+.tooltip.left .tooltip-arrow {
    border-left-color: #2196f3
}

.tooltip-success+.tooltip .tooltip-inner {
    color: #fff;
    background-color: #4caf50
}

.tooltip-success+.tooltip.top .tooltip-arrow {
    border-top-color: #4caf50
}

.tooltip-success+.tooltip.right .tooltip-arrow {
    border-right-color: #4caf50
}

.tooltip-success+.tooltip.bottom .tooltip-arrow {
    border-bottom-color: #4caf50
}

.tooltip-success+.tooltip.left .tooltip-arrow {
    border-left-color: #4caf50
}

.tooltip-info+.tooltip .tooltip-inner {
    color: #fff;
    background-color: #00bcd4
}

.tooltip-info+.tooltip.top .tooltip-arrow {
    border-top-color: #00bcd4
}

.tooltip-info+.tooltip.right .tooltip-arrow {
    border-right-color: #00bcd4
}

.tooltip-info+.tooltip.bottom .tooltip-arrow {
    border-bottom-color: #00bcd4
}

.tooltip-info+.tooltip.left .tooltip-arrow {
    border-left-color: #00bcd4
}

.tooltip-warning+.tooltip .tooltip-inner {
    color: #fff;
    background-color: #ff9800
}

.tooltip-warning+.tooltip.top .tooltip-arrow {
    border-top-color: #ff9800
}

.tooltip-warning+.tooltip.right .tooltip-arrow {
    border-right-color: #ff9800
}

.tooltip-warning+.tooltip.bottom .tooltip-arrow {
    border-bottom-color: #ff9800
}

.tooltip-warning+.tooltip.left .tooltip-arrow {
    border-left-color: #ff9800
}

.tooltip-danger+.tooltip .tooltip-inner {
    color: #fff;
    background-color: #f44336
}

.tooltip-danger+.tooltip.top .tooltip-arrow {
    border-top-color: #f44336
}

.tooltip-danger+.tooltip.right .tooltip-arrow {
    border-right-color: #f44336
}

.tooltip-danger+.tooltip.bottom .tooltip-arrow {
    border-bottom-color: #f44336
}

.tooltip-danger+.tooltip.left .tooltip-arrow {
    border-left-color: #f44336
}

.popover {
    -webkit-box-shadow: 0 2px 6px rgba(0, 0, 0, .05);
    box-shadow: 0 2px 6px rgba(0, 0, 0, .05)
}

.popover.bottom>.arrow:after {
    border-bottom-color: #eee
}

.popover-primary+.popover .popover-title {
    color: #fff;
    background-color: #2196f3;
    border-color: #2196f3
}

.popover-primary+.popover.bottom .arrow {
    border-bottom-color: #2196f3
}

.popover-primary+.popover.bottom .arrow:after {
    border-bottom-color: #2196f3
}

.popover-success+.popover .popover-title {
    color: #fff;
    background-color: #4caf50;
    border-color: #4caf50
}

.popover-success+.popover.bottom .arrow {
    border-bottom-color: #4caf50
}

.popover-success+.popover.bottom .arrow:after {
    border-bottom-color: #4caf50
}

.popover-info+.popover .popover-title {
    color: #fff;
    background-color: #00bcd4;
    border-color: #00bcd4
}

.popover-info+.popover.bottom .arrow {
    border-bottom-color: #00bcd4
}

.popover-info+.popover.bottom .arrow:after {
    border-bottom-color: #00bcd4
}

.popover-warning+.popover .popover-title {
    color: #fff;
    background-color: #ff9800;
    border-color: #ff9800
}

.popover-warning+.popover.bottom .arrow {
    border-bottom-color: #ff9800
}

.popover-warning+.popover.bottom .arrow:after {
    border-bottom-color: #ff9800
}

.popover-danger+.popover .popover-title {
    color: #fff;
    background-color: #f44336;
    border-color: #f44336
}

.popover-danger+.popover.bottom .arrow {
    border-bottom-color: #f44336
}

.popover-danger+.popover.bottom .arrow:after {
    border-bottom-color: #f44336
}

.carousel-caption h1,
.carousel-caption h2,
.carousel-caption h3,
.carousel-caption h4,
.carousel-caption h5,
.carousel-caption h6 {
    color: inherit
}

.carousel-indicators li {
    background-color: rgba(255, 255, 255, .3);
    border: none
}

.carousel-indicators-scaleup li {
    border: none
}

.carousel-indicators-fillin li {
    background-color: transparent;
    -webkit-box-shadow: 0 0 0 2px #fff inset;
    box-shadow: 0 0 0 2px #fff inset
}

.carousel-indicators-fillin .active {
    -webkit-box-shadow: 0 0 0 8px #fff inset;
    box-shadow: 0 0 0 8px #fff inset
}

.carousel-indicators-fall li:after {
    background-color: rgba(0, 0, 0, .3)
}

.carousel-indicators-fall .active {
    background-color: transparent
}

.site-navbar {
    background-color: #2196f3
}

.site-navbar .navbar-container {
    background-color: #fff
}

.site-navbar .navbar-container .navbar-toolbar [data-toggle=menubar]:focus,
.site-navbar .navbar-container .navbar-toolbar [data-toggle=menubar]:hover {
    color: #fff;
    background-color: transparent
}

.site-navbar.navbar-inverse .navbar-container {
    background-color: transparent
}

.site-menubar {
    color: rgba(117, 117, 117, .9);
    background: #fff
}

.site-menubar.site-menubar-dark {
    background: #212121
}

.site-menubar.site-menubar-dark .scrollable-inverse.scrollable .scrollable-bar-handle {
    background: rgba(158, 158, 158, .6)
}

.site-menu-item a {
    color: rgba(117, 117, 117, .9)
}

.site-menu-item.has-sub {
    border-top-color: transparent;
    border-bottom-color: transparent
}

.site-menu-item.has-sub.open {
    border-top-color: rgba(0, 0, 0, .06);
    border-bottom-color: rgba(0, 0, 0, .06)
}

.site-menu>.site-menu-item {
    padding: 0
}

.site-menu>.site-menu-item.open {
    background: rgba(33, 33, 33, .05)
}

.site-menu>.site-menu-item.open>a {
    color: #424242;
    background: 0 0
}

.site-menu>.site-menu-item.open.hover>a {
    background: 0 0
}

.site-menu>.site-menu-item.hover,
.site-menu>.site-menu-item:hover {
    color: #424242
}

.site-menu>.site-menu-item.hover>a,
.site-menu>.site-menu-item:hover>a {
    color: #424242;
    background-color: rgba(33, 33, 33, .05)
}

.site-menu>.site-menu-item.active {
    background: rgba(33, 33, 33, .05)
}

.site-menu>.site-menu-item.active>a {
    color: #424242;
    background: 0 0
}

.site-menu>.site-menu-item.active.hover>a {
    background: 0 0
}

.site-menu .site-menu-sub {
    background: 0 0
}

.site-menu .site-menu-sub .site-menu-item {
    color: rgba(117, 117, 117, .9);
    background: 0 0
}

.site-menu .site-menu-sub .site-menu-item.open {
    background: rgba(33, 33, 33, .05)
}

.site-menu .site-menu-sub .site-menu-item.open>a {
    color: #424242
}

.site-menu .site-menu-sub .site-menu-item.open.hover>a {
    background-color: transparent
}

.site-menu .site-menu-sub .site-menu-item.hover>a,
.site-menu .site-menu-sub .site-menu-item:hover>a {
    color: #424242;
    background-color: rgba(255, 255, 255, .02)
}

.site-menu .site-menu-sub .site-menu-item.active {
    background: rgba(33, 33, 33, .05)
}

.site-menu .site-menu-sub .site-menu-item.active>a {
    color: #424242
}

.site-menu .site-menu-sub .site-menu-item.active.hover>a {
    background-color: transparent
}

.site-menubar-dark .site-menu-item a {
    color: rgba(158, 158, 158, .9)
}

.site-menubar-dark .site-menu-item.hover>a,
.site-menubar-dark .site-menu-item:hover>a {
    background: 0 0
}

.site-menubar-dark .site-menu>.site-menu-item.open {
    background: #1e1e1e
}

.site-menubar-dark .site-menu>.site-menu-item.open>a {
    color: #fff
}

.site-menubar-dark .site-menu>.site-menu-item.hover,
.site-menubar-dark .site-menu>.site-menu-item:hover {
    background-color: rgba(255, 255, 255, .02)
}

.site-menubar-dark .site-menu>.site-menu-item.hover>a,
.site-menubar-dark .site-menu>.site-menu-item:hover>a {
    color: rgba(255, 255, 255, .8)
}

.site-menubar-dark .site-menu>.site-menu-item.active {
    background: #1e1e1e
}

.site-menubar-dark .site-menu>.site-menu-item.active>a {
    color: #fff
}

.site-menubar-dark .site-menu .site-menu-sub .site-menu-item.open {
    background: rgba(0, 0, 0, .06)
}

.site-menubar-dark .site-menu .site-menu-sub .site-menu-item.open>a {
    color: #fff
}

.site-menubar-dark .site-menu .site-menu-sub .site-menu-item.hover,
.site-menubar-dark .site-menu .site-menu-sub .site-menu-item:hover {
    background-color: rgba(0, 0, 0, .06)
}

.site-menubar-dark .site-menu .site-menu-sub .site-menu-item.hover>a,
.site-menubar-dark .site-menu .site-menu-sub .site-menu-item:hover>a {
    color: rgba(255, 255, 255, .8)
}

.site-menubar-dark .site-menu .site-menu-sub .site-menu-item.hover.open,
.site-menubar-dark .site-menu .site-menu-sub .site-menu-item:hover.open {
    background-color: rgba(0, 0, 0, .06)
}

.site-menubar-dark .site-menu .site-menu-sub .site-menu-item.active {
    background: rgba(0, 0, 0, .06)
}

.site-menubar-dark .site-menu .site-menu-sub .site-menu-item.active>a {
    color: #fff
}

.site-gridmenu {
    background-color: #212121
}

.site-gridmenu li>a {
    color: #9e9e9e
}

.site-gridmenu li:hover>a {
    color: #fff;
    background-color: rgba(255, 255, 255, .02)
}

body {
    background-color: #f3f4f5
}

.page {
    background: #f3f4f5
}

.page-description {
    color: #9e9e9e
}

.page-header {
    background: 0 0;
    border-bottom: none
}

.page-header-bordered {
    background-color: #fff;
    border-bottom-color: transparent
}

.page-header-tabs .nav-tabs-line {
    border-bottom-color: transparent
}

.page-aside {
    background: #fff;
    border-right-color: #e0e0e0
}

.page-aside-section:after {
    border-bottom-color: #e0e0e0
}

.page-aside-title {
    color: #616161
}

.page-aside .list-group-item {
    border: none
}

.page-aside .list-group-item .icon {
    color: #9e9e9e
}

.page-aside .list-group-item:focus,
.page-aside .list-group-item:hover {
    color: #2196f3;
    background-color: #eee;
    border: none
}

.page-aside .list-group-item:focus>.icon,
.page-aside .list-group-item:hover>.icon {
    color: #2196f3
}

.page-aside .list-group-item.active {
    color: #2196f3;
    background-color: transparent
}

.page-aside .list-group-item.active>.icon {
    color: #2196f3
}

.page-aside .list-group-item.active:focus,
.page-aside .list-group-item.active:hover {
    color: #2196f3;
    background-color: #eee;
    border: none
}

.page-aside .list-group-item.active:focus>.icon,
.page-aside .list-group-item.active:hover>.icon {
    color: #2196f3
}

.page-aside .list-group.has-actions .list-group-item .item-actions .btn-icon {
    background-color: transparent
}

.page-aside .list-group.has-actions .list-group-item .item-actions .btn-icon:hover .icon {
    color: #2196f3
}

.page-aside .list-group.has-actions .list-group-item:hover .item-actions .icon {
    color: #757575
}

@media (max-width:767px) {
    .page-aside {
        border-color: transparent
    }
    .page-aside .page-aside-inner {
        background-color: #fff;
        border-right-color: #e0e0e0
    }
}

.site-footer {
    background-color: rgba(0, 0, 0, .02);
    border-top-color: #e0e0e0
}

.site-footer .scroll-to-top {
    color: #757575
}

@media (min-width:1200px) {
    .layout-boxed {
        background: #e0e0e0
    }
}

.site-print {
    padding-top: 0
}

.site-print .site-footer,
.site-print .site-gridmenu,
.site-print .site-menubar,
.site-print .site-navbar {
    display: none
}

.site-print .page {
    margin: 0!important
}

.site-menubar-fold.site-menubar-fold-alt .site-menu>.site-menu-item.active,
.site-menubar-fold.site-menubar-fold-alt .site-menu>.site-menu-item.hover,
.site-menubar-fold.site-menubar-fold-alt .site-menu>.site-menu-item.open {
    background: #424242
}

.site-menubar-fold.site-menubar-fold-alt .site-menu>.site-menu-item>a .site-menu-title {
    background: #424242
}

.site-menubar-fold.site-menubar-fold-alt .site-menubar-dark .site-menu>.site-menu-item.active,
.site-menubar-fold.site-menubar-fold-alt .site-menubar-dark .site-menu>.site-menu-item.hover,
.site-menubar-fold.site-menubar-fold-alt .site-menubar-dark .site-menu>.site-menu-item.open {
    background: rgba(66, 66, 66, .9)
}

.site-menubar-fold.site-menubar-fold-alt .site-menubar-dark .site-menu>.site-menu-item>a .site-menu-title {
    background: rgba(66, 66, 66, .9)
}

@media screen and (max-width:767px),
screen and (min-width:1200px) {
    .css-menubar .site-menu-category {
        color: #757575
    }
}

.site-menubar-unfold .site-menu-category {
    color: #757575
}

@media (max-width:767px) {
    .site-gridmenu {
        background: rgba(33, 33, 33, .9)
    }
}

body.site-navbar-small {
    padding-top: 60px
}

.site-navbar-small .site-navbar {
    height: 60px;
    min-height: 60px
}

.site-navbar-small .site-navbar .navbar-brand {
    height: 60px;
    padding: 19px 20px
}

.site-navbar-small .site-navbar .navbar-nav {
    margin: 9.5px -15px
}

@media (min-width:768px) {
    .site-navbar-small .site-navbar .navbar-nav>li>a {
        padding-top: 19px;
        padding-bottom: 19px
    }
}

.site-navbar-small .site-navbar .navbar-toggle {
    height: 60px;
    padding: 19px 15px
}

.site-navbar-small .site-navbar .navbar-toolbar>li>a {
    padding-top: 19px;
    padding-bottom: 19px
}

.site-navbar-small .site-navbar .navbar-nav>li>a.navbar-avatar,
.site-navbar-small .site-navbar .navbar-toolbar>li>a.navbar-avatar {
    padding-top: 15px;
    padding-bottom: 15px
}

.site-navbar-small .site-navbar .navbar-search-overlap .form-control {
    height: 60px!important
}

.site-navbar-small .site-menubar {
    top: 60px;
    height: -webkit-calc(100% - 60px);
    height: calc(100% - 60px)
}

.site-navbar-small .site-gridmenu {
    top: 60px
}

@media (max-width:767px) {
    body.site-navbar-collapse-show.site-navbar-small {
        padding-top: 120px
    }
    .site-navbar-small .site-menubar {
        top: 60px
    }
    .site-navbar-collapse-show .site-navbar-small .site-menubar {
        top: 120px;
        height: -webkit-calc(100% - 120px);
        height: calc(100% - 120px)
    }
    .site-navbar-small .page-aside {
        top: 60px
    }
    .site-navbar-collapse-show .site-navbar-small .page-aside {
        top: 120px
    }
}

.primary-50 {
    color: #e3f2fd!important
}

.primary-100 {
    color: #bbdefb!important
}

.primary-200 {
    color: #90caf9!important
}

.primary-300 {
    color: #64b5f6!important
}

.primary-400 {
    color: #42a5f5!important
}

.primary-500 {
    color: #2196f3!important
}

.primary-600 {
    color: #1e88e5!important
}

.primary-700 {
    color: #1976d2!important
}

.primary-800 {
    color: #1565c0!important
}

.primary-900 {
    color: #0d47a1!important
}

.bg-primary-50 {
    background-color: #e3f2fd!important
}

.bg-primary-100 {
    background-color: #bbdefb!important
}

.bg-primary-200 {
    background-color: #90caf9!important
}

.bg-primary-300 {
    background-color: #64b5f6!important
}

.bg-primary-400 {
    background-color: #42a5f5!important
}

.bg-primary-500 {
    background-color: #2196f3!important
}

.bg-primary-600 {
    background-color: #1e88e5!important
}

.bg-primary-700 {
    background-color: #1976d2!important
}

.bg-primary-800 {
    background-color: #1565c0!important
}

.bg-primary-900 {
    background-color: #0d47a1!important
}

.bg-white {
    color: #757575;
    background-color: #fff
}

.bg-primary {
    color: #fff;
    background-color: #2196f3
}

.bg-primary:hover {
    background-color: #51adf6
}

.bg-primary a,
a.bg-primary {
    color: #fff
}

.bg-primary a:hover,
a.bg-primary:hover {
    color: #fff
}

.bg-success {
    color: #fff;
    background-color: #4caf50
}

.bg-success:hover {
    background-color: #6ec071
}

.bg-success a,
a.bg-success {
    color: #fff
}

.bg-success a:hover,
a.bg-success:hover {
    color: #fff
}

.bg-info {
    color: #fff;
    background-color: #00bcd4
}

.bg-info:hover {
    background-color: #08e3ff
}

.bg-info a,
a.bg-info {
    color: #fff
}

.bg-info a:hover,
a.bg-info:hover {
    color: #fff
}

.bg-warning {
    color: #fff;
    background-color: #ff9800
}

.bg-warning:hover {
    background-color: #ffad33
}

.bg-warning a,
a.bg-warning {
    color: #fff
}

.bg-warning a:hover,
a.bg-warning:hover {
    color: #fff
}

.bg-danger {
    color: #fff;
    background-color: #f44336
}

.bg-danger:hover {
    background-color: #f77066
}

.bg-danger a,
a.bg-danger {
    color: #fff
}

.bg-danger a:hover,
a.bg-danger:hover {
    color: #fff
}

.bg-dark {
    color: #fff;
    background-color: #616161
}

.bg-dark:hover {
    background-color: #7a7a7a
}

.bg-dark a,
a.bg-dark {
    color: #fff
}

.bg-dark a:hover,
a.bg-dark:hover {
    color: #fff
}

.avatar i {
    border-color: #fff
}

.avatar-online i {
    background-color: #4caf50
}

.avatar-off i {
    background-color: #616161
}

.avatar-busy i {
    background-color: #ff9800
}

.avatar-away i {
    background-color: #f44336
}

.status-online {
    background-color: #4caf50
}

.status-off {
    background-color: #616161
}

.status-busy {
    background-color: #ff9800
}

.status-away {
    background-color: #f44336
}

.counter .counter-number-group,
.counter>.counter-number {
    color: #424242
}

.counter-inverse {
    color: #fff
}

.counter-inverse .counter-number-group,
.counter-inverse>.counter-number {
    color: #fff
}

.counter-inverse .counter-icon {
    color: #fff
}

.comments .comment {
    border: none;
    border-bottom-color: #e0e0e0
}

.comments .comment .comment:first-child {
    border-top-color: #e0e0e0
}

.comments .comment .comment:last-child {
    border-bottom: none
}

.comment-author,
.comment-author:focus,
.comment-author:hover {
    color: #424242
}

.comment-meta {
    color: #9e9e9e
}

.chat-box {
    background-color: #fff
}

.chat-content {
    color: #fff;
    background-color: #2196f3
}

.chat-content:before {
    border-color: transparent;
    border-top-color: #2196f3
}

.chat-right .chat-content {
    color: #757575;
    background-color: #dfdfdf
}

.chat-right .chat-content:before {
    border-top-color: #dfdfdf
}

.step {
    background-color: #eee
}

.step-number {
    color: #fff;
    background: #e0e0e0
}

.step-title {
    color: #616161
}

.step.current {
    color: #fff;
    background-color: #2196f3
}

.step.current .step-title {
    color: #fff
}

.step.current .step-number {
    color: #2196f3;
    background-color: #fff
}

.step.disabled {
    color: #bdbdbd
}

.step.disabled .step-title {
    color: #bdbdbd
}

.step.disabled .step-number {
    background-color: #bdbdbd
}

.step.error {
    color: #fff;
    background-color: #f44336
}

.step.error .step-title {
    color: #fff
}

.step.error .step-number {
    color: #f44336;
    background-color: #fff
}

.step.done {
    color: #fff;
    background-color: #4caf50
}

.step.done .step-title {
    color: #fff
}

.step.done .step-number {
    color: #4caf50;
    background-color: #fff
}

.pearl:after,
.pearl:before {
    background-color: #eee
}

.pearl-icon,
.pearl-number {
    color: #fff;
    background: #bdbdbd;
    border-color: #bdbdbd
}

.pearl-title {
    color: #616161
}

.pearl.current:after,
.pearl.current:before {
    background-color: #2196f3
}

.pearl.current .pearl-icon,
.pearl.current .pearl-number {
    color: #2196f3;
    background-color: #fff;
    border-color: #2196f3
}

.pearl.disabled:after,
.pearl.disabled:before {
    background-color: #eee
}

.pearl.disabled .pearl-icon,
.pearl.disabled .pearl-number {
    color: #fff;
    background-color: #bdbdbd;
    border-color: #bdbdbd
}

.pearl.error:before {
    background-color: #2196f3
}

.pearl.error:after {
    background-color: #eee
}

.pearl.error .pearl-icon,
.pearl.error .pearl-number {
    color: #f44336;
    background-color: #fff;
    border-color: #f44336
}

.pearl.done:after,
.pearl.done:before {
    background-color: #2196f3
}

.pearl.done .pearl-icon,
.pearl.done .pearl-number {
    color: #fff;
    background-color: #2196f3;
    border-color: #2196f3
}

.ribbon-primary .ribbon-inner {
    background-color: #2196f3
}

.ribbon-primary.ribbon-bookmark .ribbon-inner:before {
    border-color: #2196f3;
    border-right-color: transparent
}

.ribbon-primary.ribbon-bookmark.ribbon-reverse .ribbon-inner:before {
    border-right-color: #2196f3;
    border-left-color: transparent
}

.ribbon-primary.ribbon-bookmark.ribbon-vertical .ribbon-inner:before {
    border-right-color: #2196f3;
    border-bottom-color: transparent
}

.ribbon-primary.ribbon-bookmark.ribbon-vertical.ribbon-reverse .ribbon-inner:before {
    border-right-color: #2196f3;
    border-bottom-color: transparent;
    border-left-color: #2196f3
}

.ribbon-primary.ribbon-corner .ribbon-inner {
    background-color: transparent
}

.ribbon-primary.ribbon-corner .ribbon-inner:before {
    border-top-color: #2196f3;
    border-left-color: #2196f3
}

.ribbon-primary.ribbon-corner.ribbon-reverse .ribbon-inner:before {
    border-right-color: #2196f3;
    border-left-color: transparent
}

.ribbon-primary.ribbon-corner.ribbon-bottom .ribbon-inner:before {
    border-top-color: transparent;
    border-bottom-color: #2196f3
}

.ribbon-primary .ribbon-inner:after {
    border-top-color: #1e88e5;
    border-right-color: #1e88e5
}

.ribbon-primary.ribbon-reverse .ribbon-inner:after {
    border-right-color: transparent;
    border-left-color: #1e88e5
}

.ribbon-primary.ribbon-bottom .ribbon-inner:after {
    border-top-color: transparent;
    border-bottom-color: #1e88e5
}

.ribbon-success .ribbon-inner {
    background-color: #4caf50
}

.ribbon-success.ribbon-bookmark .ribbon-inner:before {
    border-color: #4caf50;
    border-right-color: transparent
}

.ribbon-success.ribbon-bookmark.ribbon-reverse .ribbon-inner:before {
    border-right-color: #4caf50;
    border-left-color: transparent
}

.ribbon-success.ribbon-bookmark.ribbon-vertical .ribbon-inner:before {
    border-right-color: #4caf50;
    border-bottom-color: transparent
}

.ribbon-success.ribbon-bookmark.ribbon-vertical.ribbon-reverse .ribbon-inner:before {
    border-right-color: #4caf50;
    border-bottom-color: transparent;
    border-left-color: #4caf50
}

.ribbon-success.ribbon-corner .ribbon-inner {
    background-color: transparent
}

.ribbon-success.ribbon-corner .ribbon-inner:before {
    border-top-color: #4caf50;
    border-left-color: #4caf50
}

.ribbon-success.ribbon-corner.ribbon-reverse .ribbon-inner:before {
    border-right-color: #4caf50;
    border-left-color: transparent
}

.ribbon-success.ribbon-corner.ribbon-bottom .ribbon-inner:before {
    border-top-color: transparent;
    border-bottom-color: #4caf50
}

.ribbon-success .ribbon-inner:after {
    border-top-color: #43a047;
    border-right-color: #43a047
}

.ribbon-success.ribbon-reverse .ribbon-inner:after {
    border-right-color: transparent;
    border-left-color: #43a047
}

.ribbon-success.ribbon-bottom .ribbon-inner:after {
    border-top-color: transparent;
    border-bottom-color: #43a047
}

.ribbon-info .ribbon-inner {
    background-color: #00bcd4
}

.ribbon-info.ribbon-bookmark .ribbon-inner:before {
    border-color: #00bcd4;
    border-right-color: transparent
}

.ribbon-info.ribbon-bookmark.ribbon-reverse .ribbon-inner:before {
    border-right-color: #00bcd4;
    border-left-color: transparent
}

.ribbon-info.ribbon-bookmark.ribbon-vertical .ribbon-inner:before {
    border-right-color: #00bcd4;
    border-bottom-color: transparent
}

.ribbon-info.ribbon-bookmark.ribbon-vertical.ribbon-reverse .ribbon-inner:before {
    border-right-color: #00bcd4;
    border-bottom-color: transparent;
    border-left-color: #00bcd4
}

.ribbon-info.ribbon-corner .ribbon-inner {
    background-color: transparent
}

.ribbon-info.ribbon-corner .ribbon-inner:before {
    border-top-color: #00bcd4;
    border-left-color: #00bcd4
}

.ribbon-info.ribbon-corner.ribbon-reverse .ribbon-inner:before {
    border-right-color: #00bcd4;
    border-left-color: transparent
}

.ribbon-info.ribbon-corner.ribbon-bottom .ribbon-inner:before {
    border-top-color: transparent;
    border-bottom-color: #00bcd4
}

.ribbon-info .ribbon-inner:after {
    border-top-color: #00acc1;
    border-right-color: #00acc1
}

.ribbon-info.ribbon-reverse .ribbon-inner:after {
    border-right-color: transparent;
    border-left-color: #00acc1
}

.ribbon-info.ribbon-bottom .ribbon-inner:after {
    border-top-color: transparent;
    border-bottom-color: #00acc1
}

.ribbon-warning .ribbon-inner {
    background-color: #ff9800
}

.ribbon-warning.ribbon-bookmark .ribbon-inner:before {
    border-color: #ff9800;
    border-right-color: transparent
}

.ribbon-warning.ribbon-bookmark.ribbon-reverse .ribbon-inner:before {
    border-right-color: #ff9800;
    border-left-color: transparent
}

.ribbon-warning.ribbon-bookmark.ribbon-vertical .ribbon-inner:before {
    border-right-color: #ff9800;
    border-bottom-color: transparent
}

.ribbon-warning.ribbon-bookmark.ribbon-vertical.ribbon-reverse .ribbon-inner:before {
    border-right-color: #ff9800;
    border-bottom-color: transparent;
    border-left-color: #ff9800
}

.ribbon-warning.ribbon-corner .ribbon-inner {
    background-color: transparent
}

.ribbon-warning.ribbon-corner .ribbon-inner:before {
    border-top-color: #ff9800;
    border-left-color: #ff9800
}

.ribbon-warning.ribbon-corner.ribbon-reverse .ribbon-inner:before {
    border-right-color: #ff9800;
    border-left-color: transparent
}

.ribbon-warning.ribbon-corner.ribbon-bottom .ribbon-inner:before {
    border-top-color: transparent;
    border-bottom-color: #ff9800
}

.ribbon-warning .ribbon-inner:after {
    border-top-color: #fb8c00;
    border-right-color: #fb8c00
}

.ribbon-warning.ribbon-reverse .ribbon-inner:after {
    border-right-color: transparent;
    border-left-color: #fb8c00
}

.ribbon-warning.ribbon-bottom .ribbon-inner:after {
    border-top-color: transparent;
    border-bottom-color: #fb8c00
}

.ribbon-danger .ribbon-inner {
    background-color: #f44336
}

.ribbon-danger.ribbon-bookmark .ribbon-inner:before {
    border-color: #f44336;
    border-right-color: transparent
}

.ribbon-danger.ribbon-bookmark.ribbon-reverse .ribbon-inner:before {
    border-right-color: #f44336;
    border-left-color: transparent
}

.ribbon-danger.ribbon-bookmark.ribbon-vertical .ribbon-inner:before {
    border-right-color: #f44336;
    border-bottom-color: transparent
}

.ribbon-danger.ribbon-bookmark.ribbon-vertical.ribbon-reverse .ribbon-inner:before {
    border-right-color: #f44336;
    border-bottom-color: transparent;
    border-left-color: #f44336
}

.ribbon-danger.ribbon-corner .ribbon-inner {
    background-color: transparent
}

.ribbon-danger.ribbon-corner .ribbon-inner:before {
    border-top-color: #f44336;
    border-left-color: #f44336
}

.ribbon-danger.ribbon-corner.ribbon-reverse .ribbon-inner:before {
    border-right-color: #f44336;
    border-left-color: transparent
}

.ribbon-danger.ribbon-corner.ribbon-bottom .ribbon-inner:before {
    border-top-color: transparent;
    border-bottom-color: #f44336
}

.ribbon-danger .ribbon-inner:after {
    border-top-color: #e53935;
    border-right-color: #e53935
}

.ribbon-danger.ribbon-reverse .ribbon-inner:after {
    border-right-color: transparent;
    border-left-color: #e53935
}

.ribbon-danger.ribbon-bottom .ribbon-inner:after {
    border-top-color: transparent;
    border-bottom-color: #e53935
}

.color-selector>li {
    background-color: #2196f3
}

.color-selector>li input[type=radio]:checked+label:after {
    color: #fff
}

.color-selector>li.color-selector-disabled {
    background-color: #bdbdbd!important
}