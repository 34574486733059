/* Contacts */
.page-contact .page-content form,
.page-company .page-content form {
  margin-bottom: 40px;
}
.page-contact .page-content .list-group-item,
.page-company .page-content .list-group-item {
  padding: 25px 0;
  border-top-color: #e0e0e0;
}
.page-contact .page-content .list-group-item:first-child,
.page-company .page-content .list-group-item:first-child {
  border-top-color: transparent;
}
.page-contact .page-content .list-group-item:last-child,
.page-company .page-content .list-group-item:last-child {
  border-bottom-color: #e0e0e0;
}
.page-contact .page-content .list-group-item .media-heading > small,
.page-company .page-content .list-group-item .media-heading > small {
  margin-left: 10px;
}
.page-contact .page-content .list-group-item p,
.page-company .page-content .list-group-item p {
  margin-bottom: 5px;
}
.page-contact .page-content .list-group-item .media-right,
.page-company .page-content .list-group-item .media-right {
  vertical-align: middle;
}
.page-contact .page-content .nav-tabs-horizontal,
.page-company .page-content .nav-tabs-horizontal {
  position: relative;
}
.page-contact .page-content .page-contact-sortlist,
.page-company .page-content .page-company-sortlist {
  position: absolute;
  top: 5px;
  right: 0;
  z-index: 2;
}
@media (max-width: 991px) {
  .page-contact .page-content .page-contact-sortlist,
  .page-company .page-content .page-company-sortlist {
    top: -15px;
  }
}
@media (max-width: 767px) {
  .page-contact .page-content .list-group-item .media-right,
  .page-company .page-content .list-group-item .media-right {
    display: block;
    margin-top: 15px;
    text-align: center;
  }
}
