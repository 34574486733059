.waves-effect {
  position: relative;
  z-index: 1;
  display: inline-block;
  overflow: hidden;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;

  -webkit-tap-highlight-color: transparent;
}
.waves-effect .waves-ripple {
  position: absolute;
  width: 100px;
  height: 100px;
  margin-top: -50px;
  margin-left: -50px;
  pointer-events: none;
  background: rgba(0, 0, 0, .2);
  background: -webkit-radial-gradient(rgba(0, 0, 0, .2) 0, rgba(0, 0, 0, .3) 40%, rgba(0, 0, 0, .4) 50%, rgba(0, 0, 0, .5) 60%, rgba(255, 255, 255, 0) 70%);
  background:      -o-radial-gradient(rgba(0, 0, 0, .2) 0, rgba(0, 0, 0, .3) 40%, rgba(0, 0, 0, .4) 50%, rgba(0, 0, 0, .5) 60%, rgba(255, 255, 255, 0) 70%);
  background:         radial-gradient(rgba(0, 0, 0, .2) 0, rgba(0, 0, 0, .3) 40%, rgba(0, 0, 0, .4) 50%, rgba(0, 0, 0, .5) 60%, rgba(255, 255, 255, 0) 70%);
  border-radius: 50%;
  opacity: 0;
  -webkit-transition: all .5s ease-out;
       -o-transition: all .5s ease-out;
          transition: all .5s ease-out;
  -webkit-transition-property: -webkit-transform, opacity;
       -o-transition-property:      -o-transform, opacity;
          transition-property:         transform, opacity;
  -webkit-transform: scale(0) translate(0, 0);
      -ms-transform: scale(0) translate(0, 0);
       -o-transform: scale(0) translate(0, 0);
          transform: scale(0) translate(0, 0);
}
.waves-effect.waves-light .waves-ripple {
  background: rgba(255, 255, 255, .4);
  background: -webkit-radial-gradient(rgba(255, 255, 255, .2) 0, rgba(255, 255, 255, .3) 40%, rgba(255, 255, 255, .4) 50%, rgba(255, 255, 255, .5) 60%, rgba(255, 255, 255, 0) 70%);
  background:      -o-radial-gradient(rgba(255, 255, 255, .2) 0, rgba(255, 255, 255, .3) 40%, rgba(255, 255, 255, .4) 50%, rgba(255, 255, 255, .5) 60%, rgba(255, 255, 255, 0) 70%);
  background:         radial-gradient(rgba(255, 255, 255, .2) 0, rgba(255, 255, 255, .3) 40%, rgba(255, 255, 255, .4) 50%, rgba(255, 255, 255, .5) 60%, rgba(255, 255, 255, 0) 70%);
}
.waves-effect.waves-classic .waves-ripple {
  background: rgba(0, 0, 0, .1);
}
.waves-effect.waves-classic.waves-light .waves-ripple {
  background: rgba(255, 255, 255, .4);
}
.waves-effect.waves-red .waves-ripple {
  background-color: rgba(255, 205, 210, .7);
}
.waves-effect.waves-purple .waves-ripple {
  background-color: rgba(225, 190, 231, .7);
}
.waves-effect.waves-light-blue .waves-ripple {
  background-color: rgba(179, 229, 252, .7);
}
.waves-effect.waves-teal .waves-ripple {
  background-color: rgba(178, 223, 219, .7);
}
.waves-effect.waves-green .waves-ripple {
  background-color: rgba(200, 230, 201, .7);
}
.waves-effect.waves-yellow .waves-ripple {
  background-color: rgba(255, 249, 196, .7);
}
.waves-effect.waves-orange .waves-ripple {
  background-color: rgba(255, 224, 178, .7);
}
.waves-effect.waves-brown .waves-ripple {
  background-color: rgba(215, 204, 200, .7);
}
.waves-notransition {
  -webkit-transition: none !important;
       -o-transition: none !important;
          transition: none !important;
}
.waves-button,
.waves-circle,
.waves-round,
.waves-float {
  -webkit-transform: translateZ(0);
      -ms-transform: translateZ(0);
          transform: translateZ(0);
}
.waves-circle {
  border-radius: 50%;
}
.waves-float {
  -webkit-transition: all 300ms;
       -o-transition: all 300ms;
          transition: all 300ms;

  -webkit-mask-image: none;
}
.waves-block {
  display: block;
}
/* Firefox Bug: link not triggered */
a.waves-effect .waves-ripple {
  z-index: -1;
}
