.select2-container {
  position: relative;
  display: inline-block;
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
  margin: 0;
  vertical-align: middle;
}
.select2-container .select2-selection--single {
  display: block;
  height: 28px;
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.select2-container .select2-selection--single .select2-selection__rendered {
  display: block;
  padding-right: 20px;
  padding-left: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.select2-container[dir="rtl"] .select2-selection--single .select2-selection__rendered {
  padding-right: 8px;
  padding-left: 20px;
}
.select2-container .select2-selection--multiple {
  display: block;
  min-height: 32px;
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.select2-container .select2-selection--multiple .select2-selection__rendered {
  display: inline-block;
  padding-left: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.select2-container .select2-search--inline {
  float: left;
}
.select2-container .select2-search--inline .select2-search__field {
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
  padding: 0;
  margin-top: 5px;
  font-size: 100%;
  border: none;
}
.select2-container .select2-search--inline .select2-search__field::-webkit-search-cancel-button {
  -webkit-appearance: none;
}
.select2-primary .select2-container--default .select2-selection--multiple .select2-selection__choice,
.select2-results__otption .select2-results__otption--highlighted {
  color: #fff;
  background: #3f51b5;
  border-color: #3f51b5;
}
.select2-primary .select2-container--default .select2-selection--multiple .select2-selection__choice .select2-selection__choice__remove,
.select2-results__otption .select2-results__otption--highlighted .select2-selection__choice__remove {
  color: #fff;
}
.select2-success .select2-container--default .select2-selection--multiple .select2-selection__choice {
  color: #fff;
  background: #4caf50;
  border-color: #4caf50;
}
.select2-success .select2-container--default .select2-selection--multiple .select2-selection__choice .select2-selection__choice__remove {
  color: #fff;
}
.select2-info .select2-container--default .select2-selection--multiple .select2-selection__choice {
  color: #fff;
  background: #00bcd4;
  border-color: #00bcd4;
}
.select2-info .select2-container--default .select2-selection--multiple .select2-selection__choice .select2-selection__choice__remove {
  color: #fff;
}
.select2-warning .select2-container--default .select2-selection--multiple .select2-selection__choice {
  color: #fff;
  background: #ff9800;
  border-color: #ff9800;
}
.select2-warning .select2-container--default .select2-selection--multiple .select2-selection__choice .select2-selection__choice__remove {
  color: #fff;
}
.select2-danger .select2-container--default .select2-selection--multiple .select2-selection__choice {
  color: #fff;
  background: #f44336;
  border-color: #f44336;
}
.select2-danger .select2-container--default .select2-selection--multiple .select2-selection__choice .select2-selection__choice__remove {
  color: #fff;
}
.select2-dropdown {
  position: absolute;
  left: -100000px;
  z-index: 1200;
  display: block;
  width: 100%;
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
  background-color: white;
  border: 1px solid #e0e0e0;
  border-radius: 3px;
}
.select2-results {
  display: block;
}
.select2-results__options {
  padding: 0;
  margin: 0;
  list-style: none;
}
.select2-results__option {
  padding: 6px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.select2-results__option[aria-selected] {
  cursor: pointer;
}
.select2-container--open .select2-dropdown {
  left: 0;
}
.select2-container--open .select2-dropdown--above {
  border-bottom: none;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.select2-container--open .select2-dropdown--below {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.select2-search--dropdown {
  display: block;
  padding: 4px;
}
.select2-search--dropdown .select2-search__field {
  width: 100%;
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
  padding: 4px;
}
.select2-search--dropdown .select2-search__field::-webkit-search-cancel-button {
  -webkit-appearance: none;
}
.select2-search--dropdown.select2-search--hide {
  display: none;
}
.select2-close-mask {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1800;
  display: block;
  width: auto;
  min-width: 100%;
  height: auto;
  min-height: 100%;
  padding: 0;
  margin: 0;
  background-color: #fff;
  filter: alpha(opacity=0);
  border: 0;
  opacity: 0;
}
.select2-hidden-accessible {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0 0 0 0);
  border: 0;
}
.select2-container--default {
  display: block;
}
.select2-container--default .select2-selection {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  color: #757575;
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 3px;
  outline: 0;
}
.select2-container--default .select2-search--dropdown .select2-search__field {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  color: #757575;
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 3px;
}
.select2-container--default .select2-search__field {
  outline: 0;
}
.select2-container--default .select2-search__field::-webkit-input-placeholder {
  color: #9e9e9e;
}
.select2-container--default .select2-search__field:-moz-placeholder {
  color: #9e9e9e;
}
.select2-container--default .select2-search__field::-moz-placeholder {
  color: #9e9e9e;
  opacity: 1;
}
.select2-container--default .select2-search__field:-ms-input-placeholder {
  color: #9e9e9e;
}
.select2-container--default .select2-results__option[role=group] {
  padding: 0;
}
.select2-container--default .select2-results__option[aria-disabled=true] {
  color: #bdbdbd;
  cursor: not-allowed;
}
.select2-container--default .select2-results__option[aria-selected=true] {
  color: #757575;
  background-color: #eee;
}
.select2-container--default .select2-results__option .select2-results__option {
  padding: 6px 15px;
}
.select2-container--default .select2-results__option .select2-results__option .select2-results__group {
  padding-left: 0;
}
.select2-container--default .select2-results__option .select2-results__option .select2-results__option {
  padding-left: 30px;
  margin-left: -15px;
}
.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  padding-left: 45px;
  margin-left: -30px;
}
.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  padding-left: 60px;
  margin-left: -45px;
}
.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  padding-left: 75px;
  margin-left: -60px;
}
.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  padding-left: 90px;
  margin-left: -75px;
}
.select2-container--default .select2-results__option--highlighted[aria-selected] {
  color: #757575;
  background-color: #eee;
}
.select2-container--default .select2-results__group {
  display: block;
  padding: 6px 15px;
  font-size: 12px;
  line-height: 1.57142857;
  color: #424242;
  white-space: nowrap;
  cursor: default;
}
.select2-container--default.select2-container--open .select2-selection {
  border-color: #3f51b5;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(63, 81, 181, .006);
          box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(63, 81, 181, .006);
  -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
       -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
          transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
}
.select2-container--default.select2-container--open .select2-selection .select2-selection__arrow b {
  border-color: transparent transparent #9e9e9e transparent;
  border-width: 0 4px 4px 4px;
}
.select2-container--default.select2-container--open.select2-container--above .select2-selection {
  border-top-color: transparent;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.select2-container--default.select2-container--open.select2-container--below .select2-selection {
  border-bottom-color: transparent;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.select2-container--default.input-sm,
.select2-container--default.input-lg {
  height: auto;
  padding: 0;
  font-size: 12px;
  line-height: 1;
  border-radius: 0;
}
.select2-container--default .select2-dropdown {
  margin-top: -1px;
  overflow-x: hidden;
  border-color: #3f51b5;
  -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
          box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
}
.select2-container--default .select2-dropdown--above {
  margin-top: 1px;
}
.select2-container--default .select2-results > .select2-results__options {
  max-height: 200px;
  overflow-y: auto;
}
.select2-container--default .select2-selection--single {
  height: 36px;
  padding: 6px 27px 6px 15px;
  line-height: 1.57142857;
}
.select2-container--default .select2-selection--single .select2-selection__clear {
  float: right;
  margin-right: 10px;
  font-weight: 500;
  color: #9e9e9e;
  cursor: pointer;
}
.select2-container--default .select2-selection--single .select2-selection__clear:hover {
  color: #333;
}
.select2-container--default .select2-selection--single .select2-selection__arrow {
  position: absolute;
  top: 0;
  right: 15px;
  bottom: 0;
  width: 4px;
}
.select2-container--default .select2-selection--single .select2-selection__arrow b {
  position: absolute;
  top: 50%;
  left: 0;
  width: 0;
  height: 0;
  margin-top: -2px;
  margin-left: -4px;
  border-color: #9e9e9e transparent transparent transparent;
  border-style: solid;
  border-width: 4px 4px 0 4px;
}
.select2-container--default .select2-selection--single .select2-selection__rendered {
  padding: 0;
  color: #757575;
}
.select2-container--default .select2-selection--single .select2-selection__placeholder {
  color: #9e9e9e;
}
.select2-container--default[dir="rtl"] .select2-selection--single {
  padding-right: 15px;
  padding-left: 27px;
}
.select2-container--default[dir="rtl"] .select2-selection--single .select2-selection__rendered {
  padding-right: 0;
  padding-left: 0;
  text-align: right;
}
.select2-container--default[dir="rtl"] .select2-selection--single .select2-selection__clear {
  float: left;
}
.select2-container--default[dir="rtl"] .select2-selection--single .select2-selection__arrow {
  right: auto;
  left: 15px;
}
.select2-container--default[dir="rtl"] .select2-selection--single .select2-selection__arrow b {
  margin-left: 0;
}
.select2-container--default.select2-container--disabled .select2-selection--single {
  cursor: not-allowed;
  background-color: #eee;
}
.select2-container--default.select2-container--disabled .select2-selection--single .select2-selection__clear {
  display: none;
}
.select2-container--default.input-sm .select2-selection--single,
.input-group-sm .select2-container--default .select2-selection--single,
.form-group-sm .select2-container--default .select2-selection--single {
  height: 32px;
  padding: 6px 25px 6px 13px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 2px;
}
.select2-container--default.input-sm .select2-selection--single .select2-selection__arrow b,
.input-group-sm .select2-container--default .select2-selection--single .select2-selection__arrow b,
.form-group-sm .select2-container--default .select2-selection--single .select2-selection__arrow b {
  margin-left: -6px;
}
.select2-container--default.input-lg .select2-selection--single,
.input-group-lg .select2-container--default .select2-selection--single,
.form-group-lg .select2-container--default .select2-selection--single {
  height: 46px;
  padding: 10px 33px 10px 18px;
  font-size: 18px;
  line-height: 1.3333333;
  border-radius: 4px;
}
.select2-container--default.input-lg .select2-selection--single .select2-selection__arrow,
.input-group-lg .select2-container--default .select2-selection--single .select2-selection__arrow,
.form-group-lg .select2-container--default .select2-selection--single .select2-selection__arrow {
  width: 5px;
}
.select2-container--default.input-lg .select2-selection--single .select2-selection__arrow b,
.input-group-lg .select2-container--default .select2-selection--single .select2-selection__arrow b,
.form-group-lg .select2-container--default .select2-selection--single .select2-selection__arrow b {
  margin-top: -2.5px;
  margin-left: -5px;
  margin-left: -10px;
  border-width: 5px 5px 0 5px;
}
.select2-container--default .select2-selection--multiple {
  min-height: 36px;
}
.select2-container--default .select2-selection--multiple .select2-selection__rendered {
  display: block;
  width: 100%;
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
  padding: 0;
  margin: 0;
  overflow: hidden;
  line-height: 1.57142857;
  text-overflow: ellipsis;
  white-space: nowrap;
  list-style: none;
}
.select2-container--default .select2-selection--multiple .select2-selection__placeholder {
  float: left;
  margin-top: 5px;
  color: #9e9e9e;
}
.select2-container--default .select2-selection--multiple .select2-selection__choice {
  float: left;
  padding: 0 6px;
  margin: 5px 0 0 7.5px;
  color: #757575;
  cursor: default;
  background: #eee;
  border: 1px solid #eee;
  border-radius: 3px;
}
.select2-container--default .select2-selection--multiple .select2-search--inline .select2-search__field {
  min-width: 5em;
  height: 34px;
  padding: 0 15px;
  margin-top: 0;
  line-height: 1.57142857;
  background: transparent;
}
.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
  display: inline-block;
  margin-right: 3px;
  font-weight: 500;
  color: #999;
  cursor: pointer;
}
.select2-container--default .select2-selection--multiple .select2-selection__choice__remove:hover {
  color: #333;
}
.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice,
.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__placeholder {
  float: right;
}
.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice {
  margin-right: 7.5px;
  margin-left: 0;
}
.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice__remove {
  margin-right: auto;
  margin-left: 2px;
}
.select2-container--default.select2-container--disabled .select2-selection--multiple {
  cursor: not-allowed;
  background-color: #eee;
}
.select2-container--default.select2-container--disabled .select2-selection__choice__remove {
  display: none;
}
.select2-container--default.input-sm .select2-selection--multiple,
.input-group-sm .select2-container--default .select2-selection--multiple,
.form-group-sm .select2-container--default .select2-selection--multiple {
  min-height: 32px;
}
.select2-container--default.input-sm .select2-selection--multiple .select2-selection__choice,
.input-group-sm .select2-container--default .select2-selection--multiple .select2-selection__choice,
.form-group-sm .select2-container--default .select2-selection--multiple .select2-selection__choice {
  padding: 0 6px;
  margin: 5px 0 0 6.5px;
  font-size: 12px;
  line-height: 1.5;
}
.select2-container--default.input-sm .select2-selection--multiple .select2-search--inline .select2-search__field,
.input-group-sm .select2-container--default .select2-selection--multiple .select2-search--inline .select2-search__field,
.form-group-sm .select2-container--default .select2-selection--multiple .select2-search--inline .select2-search__field {
  height: 30px;
  padding: 0 13px;
  font-size: 12px;
  line-height: 1.5;
}
.select2-container--default.input-lg .select2-selection--multiple,
.input-group-lg .select2-container--default .select2-selection--multiple,
.form-group-lg .select2-container--default .select2-selection--multiple {
  min-height: 46px;
}
.select2-container--default.input-lg .select2-selection--multiple .select2-selection__choice,
.input-group-lg .select2-container--default .select2-selection--multiple .select2-selection__choice,
.form-group-lg .select2-container--default .select2-selection--multiple .select2-selection__choice {
  padding: 0 10px;
  margin: 9px 0 0 9px;
  font-size: 18px;
  line-height: 1.3333333;
  border-radius: 3px;
}
.select2-container--default.input-lg .select2-selection--multiple .select2-search--inline .select2-search__field,
.input-group-lg .select2-container--default .select2-selection--multiple .select2-search--inline .select2-search__field,
.form-group-lg .select2-container--default .select2-selection--multiple .select2-search--inline .select2-search__field {
  height: 44px;
  padding: 0 18px;
  font-size: 18px;
  line-height: 1.3333333;
}
.select2-container--default.input-lg.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #9e9e9e transparent;
  border-width: 0 5px 5px 5px;
}
.select2-container--default.input-lg.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #9e9e9e transparent;
  border-width: 0 5px 5px 5px;
}
.input-group-lg .select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #9e9e9e transparent;
  border-width: 0 5px 5px 5px;
}
.input-group-lg .select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #9e9e9e transparent;
  border-width: 0 5px 5px 5px;
}
.input-group .select2-container--default {
  position: relative;
  z-index: 2;
  display: table;
  float: left;
  width: 100%;
  margin-bottom: 0;
  table-layout: fixed;
}
.has-warning .select2-dropdown,
.has-warning .select2-selection {
  border-color: #ff9800;
}
.has-warning .select2-container--open .select2-selection {
  border-color: #ff9800;
}
.has-warning.select2-drop-active {
  border-color: #ff9800;
}
.has-warning.select2-drop-active.select2-drop.select2-drop-above {
  border-top-color: #ff9800;
}
.has-warning .select2-dropdown,
.has-warning .select2-selection {
  border-color: #ff9800;
}
.has-warning .select2-container--open .select2-selection {
  border-color: #ff9800;
}
.has-warning.select2-drop-active {
  border-color: #ff9800;
}
.has-warning.select2-drop-active.select2-drop.select2-drop-above {
  border-top-color: #ff9800;
}
.has-error .select2-dropdown,
.has-error .select2-selection {
  border-color: #f44336;
}
.has-error .select2-container--open .select2-selection {
  border-color: #f44336;
}
.has-error.select2-drop-active {
  border-color: #f44336;
}
.has-error.select2-drop-active.select2-drop.select2-drop-above {
  border-top-color: #f44336;
}
.has-error .select2-dropdown,
.has-error .select2-selection {
  border-color: #f44336;
}
.has-error .select2-container--open .select2-selection {
  border-color: #f44336;
}
.has-error.select2-drop-active {
  border-color: #f44336;
}
.has-error.select2-drop-active.select2-drop.select2-drop-above {
  border-top-color: #f44336;
}
.has-success .select2-dropdown,
.has-success .select2-selection {
  border-color: #4caf50;
}
.has-success .select2-container--open .select2-selection {
  border-color: #4caf50;
}
.has-success.select2-drop-active {
  border-color: #4caf50;
}
.has-success.select2-drop-active.select2-drop.select2-drop-above {
  border-top-color: #4caf50;
}
.has-success .select2-dropdown,
.has-success .select2-selection {
  border-color: #4caf50;
}
.has-success .select2-container--open .select2-selection {
  border-color: #4caf50;
}
.has-success.select2-drop-active {
  border-color: #4caf50;
}
.has-success.select2-drop-active.select2-drop.select2-drop-above {
  border-top-color: #4caf50;
}
.select2-container--material {
  display: block;
}
.select2-container--material .select2-selection {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  color: #757575;
  background-color: #fff;
  background-color: transparent;
  background-color: rgba(0, 0, 0, 0);
  background-image: -webkit-gradient(linear, left top, left bottom, from(#3f51b5), to(#3f51b5)), -webkit-gradient(linear, left top, left bottom, from(#e0e0e0), to(#e0e0e0));
  background-image: -webkit-linear-gradient(#3f51b5, #3f51b5), -webkit-linear-gradient(#e0e0e0, #e0e0e0);
  background-image:      -o-linear-gradient(#3f51b5, #3f51b5), -o-linear-gradient(#e0e0e0, #e0e0e0);
  background-image:         linear-gradient(#3f51b5, #3f51b5), linear-gradient(#e0e0e0, #e0e0e0);
  background-repeat: no-repeat;
  background-position: center bottom, center -webkit-calc(100% - 1px);
  background-position: center bottom, center calc(100% - 1px);
  -webkit-background-size: 0 2px, 100% 1px;
          background-size: 0 2px, 100% 1px;
  border: 1px solid #e0e0e0;
  border: 0;
  border-radius: 3px;
  border-radius: 0;
  outline: 0;
  -webkit-box-shadow: none;
          box-shadow: none;
  -webkit-transition: background 0s ease-out;
       -o-transition: background 0s ease-out;
          transition: background 0s ease-out;
}
.select2-container--material .select2-search--dropdown .select2-search__field {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  color: #757575;
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 3px;
}
.select2-container--material .select2-search__field {
  outline: 0;
}
.select2-container--material .select2-search__field::-webkit-input-placeholder {
  color: #9e9e9e;
}
.select2-container--material .select2-search__field:-moz-placeholder {
  color: #9e9e9e;
}
.select2-container--material .select2-search__field::-moz-placeholder {
  color: #9e9e9e;
  opacity: 1;
}
.select2-container--material .select2-search__field:-ms-input-placeholder {
  color: #9e9e9e;
}
.select2-container--material .select2-results__option[role=group] {
  padding: 0;
}
.select2-container--material .select2-results__option[aria-disabled=true] {
  color: #bdbdbd;
  cursor: not-allowed;
}
.select2-container--material .select2-results__option[aria-selected=true] {
  color: #757575;
  background-color: #eee;
}
.select2-container--material .select2-results__option .select2-results__option {
  padding: 6px 15px;
}
.select2-container--material .select2-results__option .select2-results__option .select2-results__group {
  padding-left: 0;
}
.select2-container--material .select2-results__option .select2-results__option .select2-results__option {
  padding-left: 30px;
  margin-left: -15px;
}
.select2-container--material .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  padding-left: 45px;
  margin-left: -30px;
}
.select2-container--material .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  padding-left: 60px;
  margin-left: -45px;
}
.select2-container--material .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  padding-left: 75px;
  margin-left: -60px;
}
.select2-container--material .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  padding-left: 90px;
  margin-left: -75px;
}
.select2-container--material .select2-results__option--highlighted[aria-selected] {
  color: #757575;
  background-color: #eee;
}
.select2-container--material .select2-results__group {
  display: block;
  padding: 6px 15px;
  font-size: 12px;
  line-height: 1.57142857;
  color: #424242;
  white-space: nowrap;
  cursor: default;
}
.select2-container--material.select2-container--open .select2-selection .select2-selection__arrow b {
  border-color: transparent transparent #9e9e9e transparent;
  border-width: 0 4px 4px 4px;
}
.select2-container--material.select2-container--open.select2-container--above .select2-selection {
  border-top-color: transparent;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.select2-container--material.select2-container--open.select2-container--below .select2-selection {
  border-bottom-color: transparent;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.select2-container--material.select2-container--open .select2-selection,
.select2-container--material.select2-container--focus .select2-selection {
  -webkit-background-size: 100% 2px, 100% 1px;
          background-size: 100% 2px, 100% 1px;
  outline: none;
  -webkit-transition-duration: .3s;
       -o-transition-duration: .3s;
          transition-duration: .3s;
}
.select2-container--material.select2-container--disabled .select2-selection {
  background: transparent;
  background: rgba(0, 0, 0, 0);
  border-bottom: 1px dashed #bdbdbd;
}
.select2-container--material.input-sm,
.select2-container--material.input-lg {
  height: auto;
  padding: 0;
  font-size: 12px;
  line-height: 1;
  border-radius: 0;
}
.select2-container--material .select2-dropdown {
  margin-top: -1px;
  overflow-x: hidden;
  border-color: #3f51b5;
  -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
          box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
}
.select2-container--material .select2-dropdown--above {
  margin-top: 1px;
}
.select2-container--material .select2-results > .select2-results__options {
  max-height: 200px;
  overflow-y: auto;
}
.select2-container--material .select2-selection--single {
  height: 36px;
  padding: 6px 27px 6px 15px;
  line-height: 1.57142857;
}
.select2-container--material .select2-selection--single .select2-selection__clear {
  float: right;
  margin-right: 10px;
  font-weight: 500;
  color: #9e9e9e;
  cursor: pointer;
}
.select2-container--material .select2-selection--single .select2-selection__clear:hover {
  color: #333;
}
.select2-container--material .select2-selection--single .select2-selection__arrow {
  position: absolute;
  top: 0;
  right: 15px;
  bottom: 0;
  width: 4px;
}
.select2-container--material .select2-selection--single .select2-selection__arrow b {
  position: absolute;
  top: 50%;
  left: 0;
  width: 0;
  height: 0;
  margin-top: -2px;
  margin-left: -4px;
  border-color: #9e9e9e transparent transparent transparent;
  border-style: solid;
  border-width: 4px 4px 0 4px;
}
.select2-container--material .select2-selection--single .select2-selection__rendered {
  padding: 0;
  color: #757575;
}
.select2-container--material .select2-selection--single .select2-selection__placeholder {
  color: #9e9e9e;
}
.select2-container--material[dir="rtl"] .select2-selection--single {
  padding-right: 15px;
  padding-left: 27px;
}
.select2-container--material[dir="rtl"] .select2-selection--single .select2-selection__rendered {
  padding-right: 0;
  padding-left: 0;
  text-align: right;
}
.select2-container--material[dir="rtl"] .select2-selection--single .select2-selection__clear {
  float: left;
}
.select2-container--material[dir="rtl"] .select2-selection--single .select2-selection__arrow {
  right: auto;
  left: 15px;
}
.select2-container--material[dir="rtl"] .select2-selection--single .select2-selection__arrow b {
  margin-left: 0;
}
.select2-container--material.select2-container--disabled .select2-selection--single {
  cursor: not-allowed;
}
.select2-container--material.select2-container--disabled .select2-selection--single .select2-selection__clear {
  display: none;
}
.select2-container--material.select2-container--disabled .select2-selection--single .select2-selection__rendered {
  color: #bdbdbd;
}
.select2-container--material.input-sm .select2-selection--single,
.input-group-sm .select2-container--material .select2-selection--single,
.form-group-sm .select2-container--material .select2-selection--single {
  height: 32px;
  padding: 6px 25px 6px 13px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 2px;
}
.select2-container--material.input-sm .select2-selection--single .select2-selection__arrow b,
.input-group-sm .select2-container--material .select2-selection--single .select2-selection__arrow b,
.form-group-sm .select2-container--material .select2-selection--single .select2-selection__arrow b {
  margin-left: -6px;
}
.select2-container--material.input-lg .select2-selection--single,
.input-group-lg .select2-container--material .select2-selection--single,
.form-group-lg .select2-container--material .select2-selection--single {
  height: 46px;
  padding: 10px 33px 10px 18px;
  font-size: 18px;
  line-height: 1.3333333;
  border-radius: 4px;
}
.select2-container--material.input-lg .select2-selection--single .select2-selection__arrow,
.input-group-lg .select2-container--material .select2-selection--single .select2-selection__arrow,
.form-group-lg .select2-container--material .select2-selection--single .select2-selection__arrow {
  width: 5px;
}
.select2-container--material.input-lg .select2-selection--single .select2-selection__arrow b,
.input-group-lg .select2-container--material .select2-selection--single .select2-selection__arrow b,
.form-group-lg .select2-container--material .select2-selection--single .select2-selection__arrow b {
  margin-top: -2.5px;
  margin-left: -5px;
  margin-left: -10px;
  border-width: 5px 5px 0 5px;
}
.select2-container--material .select2-selection--multiple {
  min-height: 36px;
}
.select2-container--material .select2-selection--multiple .select2-selection__rendered {
  display: block;
  width: 100%;
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
  padding: 0;
  margin: 0;
  overflow: hidden;
  line-height: 1.57142857;
  text-overflow: ellipsis;
  white-space: nowrap;
  list-style: none;
}
.select2-container--material .select2-selection--multiple .select2-selection__placeholder {
  float: left;
  margin-top: 5px;
  color: #9e9e9e;
}
.select2-container--material .select2-selection--multiple .select2-selection__choice {
  float: left;
  padding: 0 6px;
  margin: 5px 0 0 7.5px;
  color: #757575;
  cursor: default;
  background: #eee;
  border: 1px solid #eee;
  border-radius: 3px;
}
.select2-container--material .select2-selection--multiple .select2-search--inline .select2-search__field {
  min-width: 5em;
  height: 34px;
  padding: 0 15px;
  margin-top: 0;
  line-height: 1.57142857;
  background: transparent;
}
.select2-container--material .select2-selection--multiple .select2-selection__choice__remove {
  display: inline-block;
  margin-right: 3px;
  font-weight: 500;
  color: #999;
  cursor: pointer;
}
.select2-container--material .select2-selection--multiple .select2-selection__choice__remove:hover {
  color: #333;
}
.select2-container--material[dir="rtl"] .select2-selection--multiple .select2-selection__choice,
.select2-container--material[dir="rtl"] .select2-selection--multiple .select2-selection__placeholder {
  float: right;
}
.select2-container--material[dir="rtl"] .select2-selection--multiple .select2-selection__choice {
  margin-right: 7.5px;
  margin-left: 0;
}
.select2-container--material[dir="rtl"] .select2-selection--multiple .select2-selection__choice__remove {
  margin-right: auto;
  margin-left: 2px;
}
.select2-container--material.select2-container--disabled .select2-selection--multiple {
  cursor: not-allowed;
}
.select2-container--material.select2-container--disabled .select2-selection--multiple .select2-selection__rendered {
  color: #bdbdbd;
}
.select2-container--material.select2-container--disabled .select2-selection__choice__remove {
  display: none;
}
.select2-container--material.input-sm .select2-selection--multiple,
.input-group-sm .select2-container--material .select2-selection--multiple,
.form-group-sm .select2-container--material .select2-selection--multiple {
  min-height: 32px;
}
.select2-container--material.input-sm .select2-selection--multiple .select2-selection__choice,
.input-group-sm .select2-container--material .select2-selection--multiple .select2-selection__choice,
.form-group-sm .select2-container--material .select2-selection--multiple .select2-selection__choice {
  padding: 0 6px;
  margin: 5px 0 0 6.5px;
  font-size: 12px;
  line-height: 1.5;
}
.select2-container--material.input-sm .select2-selection--multiple .select2-search--inline .select2-search__field,
.input-group-sm .select2-container--material .select2-selection--multiple .select2-search--inline .select2-search__field,
.form-group-sm .select2-container--material .select2-selection--multiple .select2-search--inline .select2-search__field {
  height: 30px;
  padding: 0 13px;
  font-size: 12px;
  line-height: 1.5;
}
.select2-container--material.input-lg .select2-selection--multiple,
.input-group-lg .select2-container--material .select2-selection--multiple,
.form-group-lg .select2-container--material .select2-selection--multiple {
  min-height: 46px;
}
.select2-container--material.input-lg .select2-selection--multiple .select2-selection__choice,
.input-group-lg .select2-container--material .select2-selection--multiple .select2-selection__choice,
.form-group-lg .select2-container--material .select2-selection--multiple .select2-selection__choice {
  padding: 0 10px;
  margin: 9px 0 0 9px;
  font-size: 18px;
  line-height: 1.3333333;
  border-radius: 3px;
}
.select2-container--material.input-lg .select2-selection--multiple .select2-search--inline .select2-search__field,
.input-group-lg .select2-container--material .select2-selection--multiple .select2-search--inline .select2-search__field,
.form-group-lg .select2-container--material .select2-selection--multiple .select2-search--inline .select2-search__field {
  height: 44px;
  padding: 0 18px;
  font-size: 18px;
  line-height: 1.3333333;
}
.select2-container--material.input-lg.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #9e9e9e transparent;
  border-width: 0 5px 5px 5px;
}
.select2-container--material.input-lg.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #9e9e9e transparent;
  border-width: 0 5px 5px 5px;
}
.input-group-lg .select2-container--material.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #9e9e9e transparent;
  border-width: 0 5px 5px 5px;
}
.input-group-lg .select2-container--material.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #9e9e9e transparent;
  border-width: 0 5px 5px 5px;
}
.input-group .select2-container--material {
  position: relative;
  z-index: 2;
  display: table;
  float: left;
  width: 100%;
  margin-bottom: 0;
  table-layout: fixed;
}
.has-warning .select2-dropdown,
.has-warning .select2-selection {
  border-color: #ff9800;
}
.has-warning .select2-container--open .select2-selection {
  border-color: #ff9800;
}
.has-warning.select2-drop-active {
  border-color: #ff9800;
}
.has-warning.select2-drop-active.select2-drop.select2-drop-above {
  border-top-color: #ff9800;
}
.has-warning .select2-dropdown,
.has-warning .select2-selection {
  border-color: #ff9800;
}
.has-warning .select2-container--open .select2-selection {
  border-color: #ff9800;
}
.has-warning.select2-drop-active {
  border-color: #ff9800;
}
.has-warning.select2-drop-active.select2-drop.select2-drop-above {
  border-top-color: #ff9800;
}
.has-error .select2-dropdown,
.has-error .select2-selection {
  border-color: #f44336;
}
.has-error .select2-container--open .select2-selection {
  border-color: #f44336;
}
.has-error.select2-drop-active {
  border-color: #f44336;
}
.has-error.select2-drop-active.select2-drop.select2-drop-above {
  border-top-color: #f44336;
}
.has-error .select2-dropdown,
.has-error .select2-selection {
  border-color: #f44336;
}
.has-error .select2-container--open .select2-selection {
  border-color: #f44336;
}
.has-error.select2-drop-active {
  border-color: #f44336;
}
.has-error.select2-drop-active.select2-drop.select2-drop-above {
  border-top-color: #f44336;
}
.has-success .select2-dropdown,
.has-success .select2-selection {
  border-color: #4caf50;
}
.has-success .select2-container--open .select2-selection {
  border-color: #4caf50;
}
.has-success.select2-drop-active {
  border-color: #4caf50;
}
.has-success.select2-drop-active.select2-drop.select2-drop-above {
  border-top-color: #4caf50;
}
.has-success .select2-dropdown,
.has-success .select2-selection {
  border-color: #4caf50;
}
.has-success .select2-container--open .select2-selection {
  border-color: #4caf50;
}
.has-success.select2-drop-active {
  border-color: #4caf50;
}
.has-success.select2-drop-active.select2-drop.select2-drop-above {
  border-top-color: #4caf50;
}
